<template>
    <div>

        <article v-if="posts  == '' && user_ativo._id !== this.user_logado" class="container_semrepu">
            <h1>Opss...! Nenhuma postagem encontrada</h1>
            <p>Esse usuário ainda não fez nenhuma postagem.</p>
        </article>

        <section v-if="user_ativo._id === this.user_logado" style="margin-bottom: 22px;">
            <input type="hidden" value="" id="nome-usuario">
            <textarea id="message-box" v-model="postagem" class="form_text_area postagem" ref="postagem"
                @input="checksEmptyPost" placeholder="Digite algum hobbie ou gosto" name="postagem" required></textarea>
            <div id="div_btn_envia">
                <button type="button" class="btn btn_envia" ref="button_envia_post" id="botao_timeline_enviar"
                    name="submit" @click="createPost" disabled>Enviar</button>
            </div>
        </section>

        <article class="post" v-for="post in posts">
            <header class="cabecalho">
                <div class="div_perfil">
                    <Router-Link to="/timeline">
                        <div class="foto-perfil" :style="{ 'background-image': `url(${ user_ativo.picture })` }"></div>
                    </Router-Link>
                    <h2 class="nome-usuario">{{ user_ativo.nome }}</h2>
                    <span class="tempo-postagem"><time datetime="{{ formattedDate(post.data) }}">{{
                            formattedDate(post.data) }}</time></span>

                </div>
                <div class="dropdown-post" ref="dropdown_post">
                    <button class="dropdown-button" @click.stop="toggle(post.uuid)"
                        v-if="post.user_id  === user_logado">
                        <fa :icon="['fas', 'ellipsis-vertical']" style="pointer-events: none;" />
                    </button>
                    <ul class="opcoes-dropdown" v-if="isDropdownOpen(post.uuid)">
                        <li><button type="button" class="button-drop edit-post" data-toggle="modal"
                                @click="showModal(post.uuid)" data-target="#EditPostModal">Editar</button></li>
                        <li><button type="button" class="button-drop" data-toggle="modal" data-target="#DeletePost"
                                @click="showModalPost(post.uuid)">Excluir</button></li>
                    </ul>
                </div>
            </header>
            <section class="conteudo">
                <p class="texto-postagem">{{ post.content }}</p>
                <div class="corpo flex">
                    <div class="interacao">
                        <div class="flex">
                            <fa @click="recuperaLikes(post.uuid)" data-toggle="modal" data-target="#LikesUsuarios"
                                :icon="['fa', 'heart']" class="icone-interacao" />
                            <span class="botao-interacao">{{ post.likes.length }}</span>
                            <fa data-toggle="modal" data-target="#ComentsUsuarios" @click="recuperaComments(post.uuid)"
                                :icon="['fa', 'comment']" class="icone-coment" />
                            <span class="botao-interacao">{{ post.comments.length }}</span>
                        </div>
                    </div>
                </div>
            </section>
            <footer class="footer_post">
                <button type="button" class="botao_like_coments" id="botao_id" @click="likePost(post.uuid)">
                    <fa :icon="[userLikedPost(post) ? 'fas' : 'far', 'heart']" class="icon_post"
                        style="color: #ff0000;" />
                </button>
                <button type="button" @click=showModalPost(post.uuid) data-toggle="modal"
                    data-target="#modal-comentario" class="botao_coments">
                    <fa :icon="['far', 'comment']" class="icon_post" />
                </button>

            </footer>

        </article>

        <div class="modal fade" id="EditPostModal" tabindex="-1" aria-labelledby="EditPostModalLabel"
            ref="modal_edit_post">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="EditPostModalLabel">Editar post</h5>
                        <button type="button" class="close" id="close_edit_post" data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="content">Conteúdo</label>
                            <textarea class="form-control" v-model="post_edit" id="content" name="content"></textarea>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="updatePost(post_edit_uuid)"
                            class="btn btn_envia update-post">Salvar alterações</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="DeletePost" tabindex="-1" aria-labelledby="DeletePostLabel" ref="modal_delete_post">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="DeletePostLabel">Excluir post</h5>
                        <button type="button" class="close" id="dropModalDelete" data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <h4>Você tem certeza que quer deletar o post?</h4>
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="deletePost(post_edit_uuid)"
                            class="btn btn_envia delete-post">Delete</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="modal-comentario" tabindex="-1" role="dialog"
            aria-labelledby="modal-comentario-label" ref="modal_comment_post">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="commentModalLabel">Comentar no post</h5>
                        <button type="button" class="close" data-dismiss="modal"
                            id="dropModalComentario"><span>×</span></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="coment">Comentário:</label>
                            <textarea class="form-control" v-model="comment" rows="3" name="comment"
                                required></textarea>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="commentPost"
                            class="btn btn_envia btn-submit-comentario">Enviar</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="ComentsUsuarios" tabindex="-1" aria-labelledby="ComentsUsuariosLabel"
            ref="modal_comments">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="ComentsUsuariosLabel">Comentários</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div v-for="all_user_comment in all_users_comments">
                            <div class="cabecalho">
                                <div class="div_perfil">
                                    <Router-Link to="/timeline">
                                        <div class="foto-perfil"
                                            :style="{ 'background-image': `url(${ all_user_comment.picture })` }"></div>
                                    </Router-Link>
                                    <h2 class="nome-usuario">{{ all_user_comment.nome }}</h2>
                                    <span class="tempo-postagem">x minutos </span>
                                </div>
                                <div class="dropdown-post">
                                    <button class="dropdown-button" @click.stop="toggle(all_user_comment.id_coment)"
                                        v-if="all_user_comment.user_id  === user_logado">
                                        <fa :icon="['fas', 'ellipsis-vertical']" />
                                    </button>
                                    <ul class="opcoes-dropdown" v-if="isDropdownOpen(all_user_comment.id_coment)">
                                        <li><button data-toggle="modal" data-target="#modal_edit_comment"
                                                @click="showModalComentPost(all_user_comment.id_coment)" type="button"
                                                class="button-drop">Editar</button></li>
                                        <li><button data-toggle="modal" data-target="#EditComentModal"
                                                @click="showModalComentPost(all_user_comment.id_coment)" type="button"
                                                class="button-drop">Excluir</button></li>

                                    </ul>
                                </div>
                            </div>
                            <div class="conteudo">
                                <p class="texto-postagem" style="bottom: 38px;">{{ all_user_comment.content }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="modal_edit_comment" tabindex="-1" role="dialog" ref="modal_edit_coment_post">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="EditComentModalLabel">Editar Comentário</h5>
                        <button type="button" class="close" data-dismiss="modal" id="close_edit_comment"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="content">Comentário</label>
                            <textarea class="form-control" v-model="comment_edit.content" id="conteudo"
                                name="conteudo"></textarea>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="updateComments" class="btn btn_envia update-coment">Salvar
                            alterações</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="EditComentModal" tabindex="-1" aria-labelledby="EditComentModalLabel"
            ref="modal_delete_comment_post">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Excluir Comentário</h5>
                        <button type="button" class="close" data-dismiss="modal" id="close_delete_comment"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <h4>Você tem certeza que deseja deletar este comentário?</h4>
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="deleteComments" class="btn btn_envia">Delete</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="LikesUsuarios" tabindex="-1" aria-labelledby="LikesUsuariosLabel"
            aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="LikesUsuariosLabel">Curtidas</h5>
                        <button type="button" class="close" id="dropLikesUsuarios" data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div v-for="all_user_like in all_users_likes" style="margin: 10px 0 20px 0;">
                            <Router-link to="/login">
                                <div class="perfil_lista_link">
                                    <div class="show_photo_perfil_likes"
                                        :style="{ 'background-image': `url(${ all_user_like.picture })` }"></div>
                                    <span class="perfil_lista_nome">{{ all_user_like.nome }}</span>
                                </div>
                            </Router-link>

                        </div>
                    </div>
                    <div class="modal-footer">
                    </div>
                </div>
            </div>
        </div>
    </div>



</template>

<script>
    import { v4 as uuidv4 } from 'uuid';
    import axios from '../../axios';
    import io from 'socket.io-client';
    import moment from 'moment';



    export default {

        props: {
            user_ativo: Object
        },

        data() {
            return {
                socket_liked: null,
                postagem: '',
                comment: '',
                user_logado: '',
                comment_edit: {},
                button_envia_post: '',
                dropdown_button: '',
                dropdown_post: '',
                post_edit: '',
                post_edit_uuid: '',
                posts: [],
                all_users_likes: [],
                all_users_comments: [],
                id_user: '',
                dropdownStates: {},


            }
        },
        watch: {
            user_ativo() {

                this.user_logado = this.$store.state.user_logado._id
                axios.get(`/a/post/fetch/posts/${this.user_ativo._id}`)
                    .then((res) => {
                        this.posts = res.data.posts
                    })
                    .catch((error) => {
                        console.error('Erro ao enviar dados:', error);

                    })


            },
        },
        mounted() {
            window.addEventListener('click', this.closeAllDropdowns);
            this.user_logado = this.$store.state.user_logado._id
            if (this.user_ativo._id) {
                axios.get(`/a/post/fetch/posts/${this.user_ativo._id}`)
                    .then((res) => {
                        this.posts = res.data.posts
                    })
                    .catch((error) => {
                        console.error('Erro ao enviar dados:', error);

                    })
            }
            //const socketUrl = process.env.VUE_APP_BASE_URL || 'http://localhost:3000';
            this.socket_liked = io({ transports: ["websocket"] });


        },
        methods: {

            checksEmptyPost() {
                const texto = this.postagem.trim();
                const texto_excedeu_limite = texto.length > 240;

                this.$refs.postagem.style.color = texto_excedeu_limite ? 'red' : '#000';
                this.$refs.button_envia_post.disabled = texto === '' || texto_excedeu_limite;
            },
            adicionaDropdown() {

                var botoesDropdown = document.querySelectorAll('.dropdown-button');
                var menusDropdown = document.querySelectorAll('.dropdown-post');

                botoesDropdown.forEach(function (botaoDropdown, index) {
                    var menuDropdown = menusDropdown[index];

                    botaoDropdown.addEventListener('click', function () {
                        menuDropdown.classList.toggle('aberto');
                    });

                    document.addEventListener('click', function (event) {
                        if (!menuDropdown.contains(event.target) && !botaoDropdown.contains(event.target)) {
                            menuDropdown.classList.remove('aberto');
                        }
                    });
                });

            },

            showModal(uuid) {
                const post = this.posts.find(post => post.uuid == uuid);
                this.post_edit = post.content
                this.post_edit_uuid = post.uuid
            },
            showModalPost(uuid) {
                this.post_edit_uuid = uuid
            },

            showModalComentPost(comment_id) {
                this.comment_edit = this.all_users_comments.find(comment => comment.id_coment === comment_id);
            },
            toggle(id) {
                this.dropdownStates[id] = !this.dropdownStates[id];

            },

            isDropdownOpen(id) {
                return this.dropdownStates[id] || false;
            },
            closeAllDropdowns() {
                for (const id in this.dropdownStates)
                    this.dropdownStates[id] = false;

            },


            createPost() {

                let postagem = {
                    uuid: uuidv4(),
                    user_id: this.user_logado,
                    content: this.postagem,
                    likes: [],
                    comments: [],
                    data: Date.now()
                }
                axios.post('/a/post/create/posts', postagem)
                    .then(() => {
                        this.posts.push(postagem)
                        this.posts.reverse();
                        this.postagem = ''
                    })
                    .catch((error) => {
                        console.error('Erro ao enviar dados:', error);

                    })


            },

            updatePost(uuid) {
                const postagem = { uuid: uuid, content: this.post_edit }

                axios.post('/a/post/update/posts', postagem)
                    .then(() => {

                        this.posts.find(post => {

                            if (post.uuid === uuid)
                                post.content = this.post_edit
                        })
                        document.getElementById('close_edit_post').click();


                    })
                    .catch((error) => {
                        console.error('Erro ao enviar dados:', error);

                    })


            },
            deletePost(uuid) {
                axios.post(`/a/post/delete/posts/${uuid}`)
                    .then(() => {
                        this.posts = this.posts.filter(post => post.uuid !== uuid);
                        this.show_modal_delete_post = !this.show_modal_delete_post
                        document.getElementById('dropModalDelete').click();
                    })
                    .catch((error) => {
                        console.error('Erro ao enviar dados:', error);

                    })
            },
            likePost(uuid) {

                const postagem = {
                    uuid: uuid,
                    user_id: this.user_logado,
                }

                const index = this.posts.findIndex(post => post.uuid === uuid);
                const userLiked = this.posts[index].likes.includes(this.user_logado);
                if (userLiked)
                    this.posts[index].likes = this.posts[index].likes.filter(userId => userId !== this.user_logado);
                else {
                    this.posts[index].likes.push(this.user_logado);
                    if (this.user_ativo._id !== this.user_logado) {

                        const mensagem = `O usuário ${this.$store.state.user_logado.nome} curtiu sua postagem`

                        this.socket_liked.emit('join-chat', user_id);
                        this.socket_liked.emit('like-message', { mensagem });
                        postagem.user_ativo = this.user_ativo._id
                        postagem.mensagem = mensagem

                    }

                }


                axios.post('/a/post/like/posts', postagem)
                    .catch((error) => {
                        console.error('Erro ao enviar dados:', error);

                    })

            },
            commentPost() {

                const postagem = {
                    uuid: this.post_edit_uuid,
                    user_id: this.user_logado,
                    comment: this.comment
                }

                const index = this.posts.findIndex(post => post.uuid === this.post_edit_uuid);
                this.posts[index].comments.push({ user_id: this.user_logado, comment: this.comment });
                document.getElementById('dropModalComentario').click();

                if (this.user_ativo._id !== this.user_logado) {
                    const mensagem = `O usuário ${this.$store.state.user_logado.nome} comentou na sua postagem`
                    this.socket_liked.emit('like-message', { mensagem });
                    postagem.user_ativo = this.user_ativo._id
                    postagem.mensagem = mensagem

                }
                this.comment = ''
                this.post_edit_uuid = ''

                axios.post('/a/post/comment/posts', postagem)
                    .catch((error) => {
                        console.error('Erro ao enviar dados:', error);

                    })

            },
            userLikedPost(post) {
                return post.likes.includes(this.user_logado);
            },

            recuperaLikes(uuid) {
                axios.get(`/a/post/fetch/likes/post/${uuid}`)
                    .then((response) => {
                        this.all_users_likes = response.data.likes
                    })
                    .catch((error) => {
                        console.error('Erro ao enviar dados:', error);

                    })
            },
            recuperaComments(uuid) {
                axios.get(`/a/post/fetch/comments/post/${uuid}`)
                    .then((response) => {
                        this.all_users_comments = response.data.comments
                    })
                    .catch((error) => {
                        console.error('Erro ao enviar dados:', error);

                    })
            },
            updateComments() {

                axios.post('/a/post/update/comments/post', this.comment_edit)
                    .then(() => {
                        document.getElementById('close_edit_comment').click();
                    })
                    .catch((error) => {
                        console.error('Erro ao enviar dados:', error);

                    })
            },
            deleteComments() {

                axios.post('/a/post/delete/comments/post', this.comment_edit)
                    .then(() => {

                        document.getElementById('close_delete_comment').click();
                        this.all_users_comments = this.all_users_comments.filter(
                            all_user_comment => all_user_comment.id_coment !== this.comment_edit.id_coment
                        )
                        Object.keys(this.comment_edit).forEach(key => {
                            this.comment_edit[key] = '';
                        });
                    })
                    .catch((error) => {
                        console.error('Erro ao enviar dados:', error);

                    })
            },
            formattedDate(created_at) {
                return moment(created_at).calendar();
            },


        }
    };
</script>

<style scoped>
    .icon_post {
        cursor: pointer;
        margin: 0 0 0 6px;
        font-size: 25px;
    }

    #modal_edit_comment {
        z-index: 7000;
    }

    .dropdown-post {
        position: relative
    }

    .dropdown-button {
        background-color: transparent;
        border: none;
        cursor: pointer
    }

    .dropdown-button {
        padding: 3px;
        margin-right: 15px;
        margin-top: 3px;
        font-size: 20px
    }

    #botao_timeline_enviar:hover {
        color: #ccc
    }

    .opcoes-dropdown {
        position: absolute;
        display: block;
        background-color: #fff;
        box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
        z-index: 10;
        top: 100%;
        right: 5px;
        border: 1px solid #ccc;
        border-radius: 5px;
        width: 150px
    }



    .button-drop {

        background-color: transparent;
        border: none;
        color: #333;
        display: block;
        font-size: 14px;
        width: 150px;
        padding: .8rem .8rem .8rem 2px
    }

    .opcoes-dropdown li {
        cursor: pointer
    }

    .opcoes-dropdown li:hover {
        background-color: #f1f1f1
    }

    .foto-perfil {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 10px;
        border: 5px solid #dcdcdc;
        background-size: 100% 100%;
        overflow: hidden
    }

    .cabecalho {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 8px
    }

    .div_perfil {
        display: flex;
        align-items: flex-start
    }

    .footer_post {
        margin: 20px 0 15px 0;
        display: flex;
        justify-content: space-around;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
    }

    .nome-usuario,
    .tempo-postagem {
        display: inline-block;
        white-space: nowrap
    }



    .nome-usuario {
        margin-right: 9px;
        margin-top: 8px;
        font-size: 16px
    }

    .tempo-postagem {
        font-size: 14px;
        color: #999;
        margin-top: 7.8px
    }

    .texto-postagem {
        position: relative;
        bottom: 30px;
        padding-right: 10px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
    }

    .interacao {
        display: flex;
        max-width: 300px
    }

    .botao-interacao,
    .icone-coment,
    .icone-interacao {
        display: flex;
        align-items: center;
        color: #999;
        text-decoration: none;
        cursor: pointer
    }

    .icone-interacao {
        margin: 4px 2px 0 0
    }

    .icone-coment {
        margin: 4px 3px 0 5px
    }

    .botao_coments,
    .botao_like_coments {
        border: none;
        background: 0 0;
        width: 60px;
        padding: 10px;
        margin-top: 19.2px
    }

    .botao-interacao:hover {
        color: #333
    }
</style>