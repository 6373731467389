<template>

  <article v-if="not_users">
    <div class="background"></div>
    <div class="div_notFund_user">
        <h1 class="h1_notFound_user">Opss...! Nenhum usuário encontrado</h1>
        <h5>Você ainda não tem conecxões.</h5>
    </div>
  </article>
  <article v-else>
    <ul v-for="user in users_found" class="container_mostra_user">
        <li class="perfil_lista_item">
              <Router-link :to="`/timeline/${user.user_id}`"> 
                <div class="perfil_lista_link">
                   <div class="mostra_usuario_perfil" :style="{ 'background-image': `url(${ user.picture })` }"></div>
                   <span class="perfil_lista_nome">{{ user.nome }}</span>
                </div>
              </Router-link>
              <div v-if="user.user_id !== $store.state.user_logado._id">
                 <button v-if="userSeguindo(user)" @click="connectUser(user.user_id)" class="btn_seguindo">conectado</button>
                 <button v-else class="btn_seguir" @click="connectUser(user.user_id)">conectar</button>
              </div>
        </li>
    </ul>

  </article>
</template>

<script>
  import axios from '../../axios';


export default {
  data(){
    return{
      user_logado: '',
      users_found: '',
      not_users: false,

    }

  },
  watch: {
    '$store.state.user_logado._id': function() {
          this.searchUsers();
    }
  },
  
  mounted(){
    this.searchUsers();
  },

  methods: {

       async searchUsers(){
          
          this.user_logado = this.$store.state.user_logado._id
          axios.get(`/a/user/connections/${this.$route.params.user_ativo}`)
          .then((response) => {
              this.users_found = response.data.user
              this.not_users = this.users_found == '' ? true : false

          })
          .catch((error) => {
            console.error('Erro ao enviar dados:', error);

          })         

      },

      connectUser(user_id)
      {
          const users_connections = { user_connect: this.user_logado, user_connection: user_id };

          const index = this.users_found.findIndex(user_found => user_found.user_id === user_id);

          if(this.users_found[index].connections.includes(this.user_logado)){
              this.users_found[index].connections = this.users_found[index].connections.filter(id => id !== this.user_logado);
          }
          else{
            this.users_found[index].connections.push(this.user_logado);
          }
          
          axios.post('/a/user/follow/user', users_connections)
          .catch((error) => {
              console.error('Erro ao enviar dados:', error);
          });
       },

       userSeguindo(user) {
          return user.connections.includes(this.user_logado);
        },
  

  }

}
</script>
