<template>
   
    <article v-if="not_repus" class="div_notFund_user">
        <h1 class="h1_notFound_user">Opss...! Nenhum ímovel encontrado</h1>
        <img id="cachorro" src="../assets/images/cachorro_1.webp" alt="">
        <Router-Link to="/anuncia/vaga"><button class="button_anuncia_sua_vaga">Anunciar sua vaga</button></Router-link>
         <button class="button_anuncia_sua_vaga"  data-toggle="modal" data-target="#FollowUser" @click="fetchUsersFollow">Pessoas que seguir</button>

    
    </article>
    <div class="modal fade" id="FollowUser" tabindex="-1" aria-labelledby="FollowUserLabel" ref="modalFollowUser">
        <div class="modal-dialog">
            <div class="modal-content">
               <div class="modal-header">
                    <h5 class="modal-title" id="FollowUser">Pessoas para seguir</h5>
                     <button type="button" class="close" id="close_follow_seguir" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                     </button>
                </div>
                <div class="modal-body">
                        <ul  v-for="user_follow in users_follow"  :key="user_follow._id" class="">
                            <li class="perfil_lista_item">
                                  <Router-link :to="`/timeline/visita/${user_follow._id}`" data-dismiss="modal"> 
                                    <div class="perfil_lista_link">
                                       <div class="show_users_follow" :style="{ 'background-image': `url(${ user_follow.picture })` }"></div>
                                       <span style="font-size: 0.9em;">{{ user_follow.nome }}</span>
                                    </div>
                                  </Router-link>
                                  <button v-if="userSeguindo(user_follow)" @click="followUser(user_follow._id)" class="btn_seguindo_users_follow">Seguindo</button>
                                  <button v-else class="btn_seguir" @click="followUser(user_follow._id)">Seguir</button>

                            </li>
                        </ul>
                </div>
                 <div class="modal-footer">
                     <button type="button" class="btn btn_envia delete-post" data-dismiss="modal">Fechar</button>
                 </div>
            </div>
        </div>
    </div>
    
    <div class="carousel-container">
        <div v-for="republica in republicas" :key="republica.matricula" class="carousel-container">
            <div class="carousel-card">
                <RouterLink :to="`/republica/${republica._id}`" class="link_carousel">
                    <div class="flex welcome_div_arrow_carousel">
                        <div class="carousel-button carousel-button-left">
                        <fa :icon="['fas', 'arrow-left']" />
                        </div>
                        <div class="carousel-button carousel-button-right" >
                        <fa :icon="['fas', 'arrow-right']" />
                        </div>
                    </div>
                    <div class="image-carousel image_imoveis" :data-carousel-id="republica._id" ref="image_imoveis">
                        <div class="image-carousel-content" v-for="imagesArray in republica.pictures" :key="imagesArray">
                        <img :src="'https://alueasy.s3.sa-east-1.amazonaws.com/' + imagesArray" alt="">
                        </div>
    
                        <div class="carousel-indicators"></div>
                    </div>
                    <div>
                        <ul>
                            <li class="perfil_card_imoveis flex">
                                <RouterLink :to="`/timeline/visita/${republica.userId}`" class="router_home_user">
                                <div class="welcome_perfil" :style=" {'background-image': `url(${republica.picture})`}"></div>
                                <span class="perfil_lista">{{republica.nome}}</span>
                                </RouterLink>
                            </li>
    
                            <li class="lista-card">Aluguel: <strong>R${{republica.aluguel}}</strong></li>
                            <li class="lista-card">Metros quadrados: {{republica.metros_quadrados}} m²</li>
                            <li class="lista-card">Numero de pessoas: {{ republica.numero_pessoas }}</li>
                            <li v-if="republica.sexo === 'fem'" class="lista-card">Vaga: feminina</li>
                            <li v-if="republica.sexo === 'mista'" class="lista-card">Vaga:{{ republica.sexo}}</li>
                            <li v-if="republica.sexo === 'masc'" class="lista-card">Vaga: Masculina</li>
                            <li class="lista-card">Quartos: {{republica.quartos}}</li>

                        </ul>
                    </div>
                </RouterLink>
            </div>
        </div>
    </div>
    </template>
    <script>
    import axios from '../axios';
    import { carouselImages } from '../assets/js/global.js';

    export default {
      data() {
        return {
          republicas: [],
          not_repus: false,
          users_follow: [],
          user_logado: '',
    
        };
      },
      mounted(){
            axios.get('/g/republica/fetch/republicas')
            .then(response => {
                 this.republicas = response.data;
                 this.not_repus = this.republicas == '' ? true : false
                 
                 this.$nextTick(() => {
                   this.carouselImages();
                 });
                 
    
            })
            .catch(error => {
                console.error('Erro ao enviar o arquivo:', error);
            });
        
        
        
      },
      methods: 
      { 
          carouselImages,
          fetchUsersFollow()
          {
            this.user_logado = this.$store.state.user_logado._id
            axios.get(`/a/user/fetch/userfollow/${this.user_logado}`)
            .then(response => {
                 this.users_follow = response.data.users;
            })
            .catch(error => {
                console.error('Erro ao enviar o arquivo:', error);
            });
          },
                
         followUser(user_id)
         {
            const usuario_seguindo = {
                user_seguidor: this.user_logado,
                user_seguindo: user_id
            }
            const index = this.users_follow.findIndex(user_found => user_found._id === user_id)
            const user_following = this.users_follow[index].seguidores.includes(this.user_logado) 

            if(user_following)
                this.users_follow[index].seguidores = this.users_follow[index].seguidores.filter(id => id !== this.user_logado)
            else
            this.users_follow[index].seguidores.push(this.user_logado)

            axios.post('/a/user/follow/user', usuario_seguindo)
            .catch((error) => {
                console.error('Erro ao enviar dados:', error);

            })
            
         },
         userSeguindo(user) {
             return user.seguidores.includes(this.user_logado);
          },
         
      }
    
    }
    </script>
