<template>

  <header class="navigation-header">
     <nav class="flex">
          <div class="flex">
           <RouterLink to="/"><img src="../assets/images/alueasy2.png" alt="" id="logo"></RouterLink> 
            <form @submit.prevent="searchRepublicas">
                <div class="search">
                  <input type="text" class="searchTerm borda" placeholder="Digite a rua ou bairro" name="pesquisaRepu" v-model="pesquisaRepublica">
                  <button type="submit" class="searchButton"><fa :icon="['fas', 'magnifying-glass']" /></button>
                </div>
              </form>
          </div>
          <div class="navigation-items" id="navigation-items" >
              <Router-Link to="/view/show/users/follow"><div class="buttons_navbar"><fa :icon="['fas', 'user-plus']" class="icon_navbar"/>Procurar pessoas</div></Router-Link>

               <Router-Link to="/anuncia/vaga"><div class="buttons_navbar"><fa :icon="['fas', 'house']" class="icon_navbar"/> Anunciar vaga</div></Router-Link>
               <div class="dropdown">
                  <div v-if="$store.getters.firstLoginUserId">
                      <button @click="myFunction()" class="dropbtn"><fa :icon="['fas', 'user']" class="icon_navbar" style="pointer-events: none;"/> Perfil</button>
                  </div>
                  <div v-else>
                    <button @click="myFunction()" class="dropbtn"><fa :icon="['fas', 'user']" class="icon_navbar" style="pointer-events: none;"/> Login</button>
                  </div>
                  <div id="myDropdown" class="dropdown-content">
                      <div v-if="!$store.getters.firstLoginUserId">
                        <div class="ch_dropdown_content"><Router-link to="/login">Entrar</Router-link></div>
                        <div class="ch_dropdown_content"><Router-link to="/cadastro">cadastro</Router-link></div>
                      </div>
                      <div v-else>
                          <div class="ch_dropdown_content"><Router-link to="/timeline"><fa :icon="['fa', 'user']" class="perfil icones-perfil"/> Perfil</Router-link></div>
                          <div class="ch_dropdown_content"><Router-link to="/chat"><fa :icon="['fa', 'envelope']" class="icones-perfil" /> Mensagens <span class="span_messages_navbar">{{$store.state.count_messages}}</span></Router-link></div>
                          <div class="ch_dropdown_content"><Router-link to="/config"><fa :icon="['fa', 'gear']" class="icones-perfil" /> Configurações</Router-link></div>
                          <div class="ch_dropdown_content"><Router-link to="/suporte"><fa :icon="['fa', 'question']" class="icones-perfil" /> Suporte</Router-link></div>
                          <div  class="ch_dropdown_content">
                          <div class="dropdown-itens" @click="logout()"><fa :icon="['fas', 'right-from-bracket']" class="icones-perfil"/> Sair</div></div>

                      </div>        

                  </div>

               </div>
          </div>
          <div class="navigation-items-active" ref="navbar">
              <ul>
                <li @click="$event.stopPropagation();">
                    <form @submit.prevent="searchRepublicas">
                      <div class="search_welcome">
                          <input type="text" class="searchTerm borda" placeholder="Digite a rua ou bairro" name="pesquisaRepu" v-model="pesquisaRepublica">
                          <button type="submit" class="searchButton"><fa :icon="['fas', 'magnifying-glass']" /></button>
                      </div>
                    </form>
                </li>
                <li v-if="$store.getters.firstLoginUserId" class="lista_navbar"><Router-link to="/timeline"><fa :icon="['fa', 'user']" class="perfil icones-perfil"/> Perfil</Router-link></li>
                <li class="lista_navbar"><Router-Link to="/view/show/users/follow"><fa :icon="['fas', 'user-plus']" class="perfil icones-perfil"/> Procurar pessoas</Router-Link></li>
                <li class="lista_navbar"><Router-Link to="/anuncia/vaga"><fa :icon="['fas', 'house']" class="perfil icones-perfil"/> Anunciar vaga</Router-Link></li>
                <li v-if="!$store.getters.firstLoginUserId" class="lista_navbar"><Router-Link to="/login">Entrar</Router-Link></li>
                <li v-if="!$store.getters.firstLoginUserId" class="lista_navbar"><Router-Link to="/cadastro">Cadastre-se</Router-Link></li>
                <li v-if="$store.getters.firstLoginUserId" class="lista_navbar"><Router-link to="/chat"><fa :icon="['fa', 'envelope']" class="icones-perfil" /> Mensagens<span class="span_messages_navbar_active">{{$store.state.count_messages}}</span></Router-link></li>
                <li v-if="$store.getters.firstLoginUserId" class="lista_navbar"><Router-link to="/config"><fa :icon="['fa', 'gear']" class="icones-perfil" /> Configurações</Router-link></li>
                <li v-if="$store.getters.firstLoginUserId" class="lista_navbar"><Router-link to="/suporte"><fa :icon="['fa', 'question']" class="icones-perfil" /> Suporte</Router-link></li>
                <li v-if="$store.getters.firstLoginUserId" class="lista_navbar" @click="logout"><div class="logout"><fa :icon="['fas', 'right-from-bracket']" class="icones-perfil"/> Sair</div></li>
              </ul>
          </div>
          <div class="hamburger" @click.stop="toggleNavbar">
              <span id="openHam">&#9776;</span>
          </div>
     </nav>
  </header>
  <div style="height: 81px;"></div>


</template>

<script>
  import axios from '../axios';
  import router from '../routes'


export default {
    data(){
      return{
        isOpen: false,
        pesquisaRepublica: '',

      }
      
    },
    watch: {
      '$store.state.user_logado._id': function () {

            if(this.$store.state.user_logado._id)
            {
              axios.get(`/a/chat/count/messages/${this.$store.state.user_logado._id}`)
              .then(res => {
                  this.$store.commit('countMessages', res.data.count_messages)
              })
              .catch(error => {
                  console.error('Erro ao enviar o arquivo:', error);
              });
            }
       
         },
    },
    mounted(){
      window.addEventListener('click', this.handleWindowClick);
      window.addEventListener('click', this.closeNavbar);
      window.addEventListener('resize', this.checkNavigationItem);
   

      
    }, 
    methods: 
    {
      myFunction() 
      {
          document.getElementById("myDropdown").classList.toggle("show");
      },
      handleWindowClick(event) 
      {
          if (!event.target.matches('.dropbtn')) 
          {
              var dropdowns = document.getElementsByClassName("dropdown-content");
              var i;
              for (i = 0; i < dropdowns.length; i++) 
              {
                var openDropdown = dropdowns[i];
                if (openDropdown.classList.contains('show'))
                  openDropdown.classList.remove('show');
              }
          }
       },

       toggleNavbar() {
          this.$refs.navbar.style.transform = this.isOpen ? 'translateX(100%)' : 'translateX(0)';
          this.isOpen = !this.isOpen;

      },
      closeNavbar() {
          this.$refs.navbar.style.transform = 'translateX(100%)';
          this.isOpen = false;

      },

      checkNavigationItem()
      {
        if( window.innerWidth > 768)
          this.$refs.navbar.style.transform = 'translateX(100%)';
   
      },

       async logout() 
       {

          if (this.$store.getters.firstLoginUserId) 
          {

            const user = {
              userID: this.$store.state.user_logado._id,
              refreshToken: localStorage.getItem('refreshToken')
            }
                      
            await axios.post('/a/user/logout', user).then((res) =>{
              localStorage.removeItem('accessToken')
              localStorage.removeItem('refreshToken')
           
            }).catch((error) =>{
              console.error('Erro ao enviar dados:', error);

            })
            this.$store.commit('logado', '')
            window.location.href = "/";


          } 

       },
       searchRepublicas(){
        router.push(`/view/show/repu/${this.pesquisaRepublica}`); 

        }

    },
    
  

    
};
</script>
<style scoped>
.navigation-items-active  {

    position:fixed;
    background-color:#fff;
    display:flex;
    transform:translate(100%);
    flex-direction:column;
    justify-content:flex-start;
    top:81px;
    right:0;
    height:100vh;
    padding:16px;
    box-shadow:-25px 30px 15px #00000008;
    transition:.1s ease-in-out
}

.buttons_navbar{
  min-width: 10vw;
  padding: 10px;
}
</style>