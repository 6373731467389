<template>
  
  <div class="background"></div>
  
  <article class="user_container">
      <div class="notifications"  v-for="notification in notifications"  :key="notification._id">
        <div class="notification-content">
            <h3>Notificação</h3>
            <p>{{ notification.notificacao }}</p>
      
            <span class="timestamp"><time datetime="{{ formattedDate(notification.created_at) }}"></time>{{ formattedDate(notification.created_at) }}</span>
      
  
        </div>
      </div>
  </article>
  
  
  </template>
  <script>
      import axios from '../../axios';
      import moment from 'moment';
  
  export default {
    
    data() {
      return {
        notifications: '',
        not_notifications: false,
        user_logado: '',
  
      };
    },

    watch: {

      '$store.state.user_logado._id': function() {
          this.fetchNotifications();
      }

    },

  
    mounted(){
       this.fetchNotifications();
    },
    methods: {

        fetchNotifications(){

          axios.get(`/a/user/fetch/notifications/${this.$store.state.user_logado._id}`)
            .then((response) => {
      
                this.notifications = response.data.notifications; 
                
                if( this.notifications == ''){
                    this.not_notifications = true;
                }
      
            })
            .catch((error) => {
                  console.error('Erro ao enviar dados:', error);
      
            })    
        },
      
        formattedDate(created_at) {
            return moment(created_at).calendar();
        },
        
    }
  };
  </script>