import { createApp } from 'vue'
import App from './App.vue'
import router from './routes'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './assets/css/app.css';
import './assets/js/global.js';
import store from './store'
import vue3GoogleLogin from 'vue3-google-login'
import VueGtag from "vue-gtag";
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { 
    faUserSecret, 
    faArrowRight, 
    faArrowLeft, 
    faEllipsisVertical, 
    faMagnifyingGlass, 
    faUser, 
    faHouse,
    faRightFromBracket,
    faEnvelope,
    faGear,
    faGears,
    faQuestion,
    faPerson,
    faBell,
    faCamera,
    faHeart,
    faComment,
    faPlus,
    faMinus,
    faVenus,
    faMars,
    faCheck,
    faUserPlus,
    faX

} from '@fortawesome/free-solid-svg-icons'
import {faHeart as farHeart, faComment as farComment, faBuilding as farBuilding, faEnvelope as farEnvelope, faBell as farBell} from '@fortawesome/free-regular-svg-icons'

library.add(
    faUserSecret, 
        faArrowRight, 
        faArrowLeft, 
        faEllipsisVertical, 
        faMagnifyingGlass, 
        faUser, 
        farHeart, 
        faHouse, 
        faRightFromBracket,
        faEnvelope,
        farEnvelope,
        faGear,
        faGears,
        faQuestion,
        faPerson,
        faBell,
        farBell,
        faCamera,
        farComment,
        faHeart,
        faComment,
        faPlus,
        faMinus,
        faVenus,
        faMars,
        farBuilding,
        faCheck,
        faUserPlus,
        faX

);
const CLIENT_ID = '429761098086-fesabu75cafjf9d4j5cgop1eu13ms4i7.apps.googleusercontent.com';
const GA_MEASUREMENT_ID = '4G-2RW5K782YJ';

const loadFontAwesomeIcon = () => import('@fortawesome/vue-fontawesome');
loadFontAwesomeIcon().then(({ FontAwesomeIcon }) => {
    createApp(App)
    .use(store).component('fa', FontAwesomeIcon)
    .use(router).use(vue3GoogleLogin, {clientId: CLIENT_ID}).use(ToastPlugin).mount('#app');
});