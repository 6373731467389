<template>
    <div class="div_create">
            <aside class="coluna_1">
                <div class="div_columns_1">
                    <button class="button_colums">1</button>
                    <p class="p_columns">Precisamos de alguns dados da sua republica</p>
                </div>
            </aside>
            <main class="coluna_2">
                <header class="menu_superior">
                 <button id="#tab1">1</button><p class="p_imovel_dado">Dados da republica</p>
                    
    
                </header>
                <form @submit.prevent="updateRepu" id="my_form">
                       <article class="div_informa">
                            <h3 class="titulo_infos"><strong>Informações do Anfitrião</strong></h3>
                            <label for="">Matrícula: <span style="color: red">*</span></label>
                            <input class="inputs_info" type="text" name="matricula" required v-model="formFields.matricula">
                       </article>
                        <article class="div_informa">
                            <h3 class="titulo_infos"><strong>Endereço</strong></h3>
                            <label for="">CEP: <span style="color: red">*</span></label>
                            <input class="inputs_info" type="text" id="cep" v-model="formFields.cep" @input="formatCEP" placeholder="_____-__"  name="cep"  maxlength="9" required>
                            <label for="">Bairro: <span style="color: red">*</span></label>
                            <input class="inputs_info" type="text" name="bairro" required v-model="formFields.bairro" id="bairro" maxlength="50">
                            <label for="">Rua: <span style="color: red">*</span></label>
                            <input class="inputs_info" type="text" name="rua" required v-model="formFields.rua" id="rua" maxlength="50">
                            
                            <label for="">Número: <span style="color: red">*</span></label>
                            <input class="inputs_info" type="text" name="numero" required v-model="formFields.numero" id="numero" maxlength="20">
                        
                            <label for="">Complemento: <span style="color: red">*</span></label>
                            <input class="inputs_info" type="text" name="comple" v-model="formFields.comple" id="comple" maxlength="100">
                            <label for="">Andar:</label>
                            <input class="inputs_info" type="text" name="andar" v-model="formFields.andar" id="andar" placeholder="Somente para apartamentos" maxlength="70">
                        </article>
                    <article class="div_informa">
                        <h3 class="titulo_infos"><strong>Tipo de anuncio</strong></h3>
                        <label for="">Qual é a sua relação com a republica: <span style="color: red">*</span></label>
                        <div class="flex">
                                <input type="radio" v-model="formFields.relacao" id="radio_proprietario" value="proprietario" class="radio_info" required>
                                <label for="radio_proprietario" class="label_carac">
                                    <div class="btn_tipo">Proprietário</div>
    
                                </label>
                                <input type="radio" v-model="formFields.relacao" id="radio_inquilino" value="inquilino" class="radio_info" required>
                                <label for="radio_inquilino" class="label_carac">
                                    <div class="btn_tipo">Inquilino</div>
    
                                </label>
                        </div>
                    </article>
                    <article class="div_informa">
                        <label for="">A republica é: <span style="color: red">*</span></label>
                        <div class="flex">
                                <input type="radio" required v-model="formFields.sexo" id="radio_masc" value="masc" class="radio_info">
                                <label for="radio_masc" class="label_carac">
                                    <div class="btn_tipo">Masculina</div>
    
                                </label>
                                <input type="radio" required v-model="formFields.sexo" id="radio_fem" value="fem" class="radio_info">
                                <label for="radio_fem" class="label_carac">
                                    <div class="btn_tipo">Feminina</div>
    
                                </label>
                                <input type="radio" required v-model="formFields.sexo" id="radio_mista" value="mista" class="radio_info">
                                <label for="radio_mista" class="label_carac">
                                    <div class="btn_tipo">Mista</div>
    
                                </label>
                        </div>
                    </article>
    
                    <article class="div_informa">
                        <h3 class="titulo_infos"><strong>Características da republica</strong></h3>
                        <label for="">Qual é o tipo de ímovel: <span style="color: red">*</span></label>
                        <ul>
                            <li>
                                <input v-model="formFields.tipo_imovel" class="radio_info" type="radio" id="apto-info" name="tipo_imovel" value="apto">
                                <label for="apto-info" class="label_carac" @click="selecionaTipoImovel('apto-info')">
                                    <div class="radio-btn"></div>
                                    <span>Apartamento</span>
    
                                </label>
                            </li>
                            <li>
                                <input v-model="formFields.tipo_imovel" type="radio" class="radio_info" id="kit-info" name="tipo_imovel" value="kit" required>
                                <label for="kit-info" class="label_carac"  @click="selecionaTipoImovel('kit-info')">
                                    <div class="radio-btn"></div>
                                    <span>Kitnet</span>
    
                                </label>
                            </li>
                            <li>
                                <input  class="radio_info" v-model="formFields.tipo_imovel" type="radio" id="casa-info" name="tipo_imovel" value="casa" required>
                                <label for="casa-info" class="label_carac" @click="selecionaTipoImovel('casa-info')">
                                    <div class="radio-btn"></div>
                                    <span>Casa</span>
    
                                </label>
    
                            </li>
                            <li>
                                <input v-model="formFields.tipo_imovel" type="radio" class="radio_info" id="casa-condo-info" name="tipo_imovel" value="casa_condo" required>
                                <label for="casa-condo-info" class="label_carac" @click="selecionaTipoImovel('casa-condo-info')">
                                    <div class="radio-btn"></div>
                                    <span>Casa em condomínio</span>
    
                                </label>
                            </li>
    
                        </ul>
                        <label for="">Area do imóvel: <span style="color: red">*</span></label>
                        <input type="text" class="inputs_info input_area_info" placeholder="Digite a area: m²" name="area" required v-model="formFields.metros_quadrados">
                    
                    
                        <label for="">Qual é o número de vagas na garagem:</label>
                        <div class="flex div_num_info">
                            <button type="button" class="btn-info_more_minus" @click="modifyValue('1', '-')"><fa :icon="['fas', 'minus']" /></button><input type="number" class="inputs_info_plus_minus" v-model="formFields.vagas" id="vagas" 
                                value="0" min="0" data-id="1" required> <button type="button" class="btn-info_more_minus"
                                @click="modifyValue('1', '+')"><fa :icon="['fas', 'plus']" /></button>
                        </div>
    
                        <label for="">Número de quartos:</label>
                        <div class="flex div_num_info">
                            <button type="button" class="btn-info_more_minus" @click="modifyValue('2', '-')"><fa :icon="['fas', 'minus']" /></button><input type="number" class="inputs_info_plus_minus" v-model="formFields.quartos" id="quartos" 
                                data-id="2" value="0" min="0" required> <button type="button" class="btn-info_more_minus"
                                @click="modifyValue('2', '+')"><fa :icon="['fas', 'plus']" /></button>
                        </div>
    
                        <label for="">Número de banheiros:</label>
                        <div class="flex div_num_info">
                            <button type="button" class="btn-info_more_minus" @click="modifyValue('3', '-')"><fa :icon="['fas', 'minus']" /></button><input type="number" class="inputs_info_plus_minus" v-model="formFields.banheiros" id="banheiros" 
                                data-id="3" value="0" min="0" required> <button type="button" class="btn-info_more_minus"
                                @click="modifyValue('3','+')"><fa :icon="['fas', 'plus']" /></button>
                        </div>
    
                        <label for="">Número de animais:</label>
                        <div class="flex div_num_info">
                            <button type="button" class="btn-info_more_minus" @click="modifyValue('4', '-')"><fa :icon="['fas', 'minus']" /></button><input type="number" class="inputs_info_plus_minus" v-model="formFields.animais" id="animais" 
                                data-id="4" value="0" min="0" required> <button type="button" class="btn-info_more_minus"
                                @click="modifyValue('4', '+')"><fa :icon="['fas', 'plus']" /></button>
                        </div>
                        <label for="">Número de pessoas:</label>
                        <div class="flex div_num_info">
                            <button type="button" class="btn-info_more_minus" @click="modifyValue('5', '-')"><fa :icon="['fas', 'minus']" /></button><input type="number" class="inputs_info_plus_minus" id="numero_pessoas" v-model="formFields.numero_pessoas" 
                                data-id="5" value="0" min="0" required> <button type="button" class="btn-info_more_minus"
                                @click="modifyValue('5', '+')"><fa :icon="['fas', 'plus']" /></button>
                        </div>
                    </article>
                    <article class="div-condo">
                        <h3 class="titulo_infos"><strong>Condomínio</strong></h3>
                        <label for="">Valor do condomínio:</label>
                        <div class="input-wrapper">
                            <input v-model="formFields.valor_condominio" @input="formatMoney('valor_condo')" type="text" class="inputs_info input_reais" name="valor_condominio" id="valor_condominio" placeholder="0,00">
    
                            <span class="currency-symbol">R$</span>
                        </div>
                    </article>
    
                    <article class="div_informa">
                        <label>Aluguel: <span style="color: red">*</span></label>
                        <input v-model="formFields.aluguel" @input="formatMoney('aluguel')"   class="inputs_info"  type="text" name="aluguel" id="aluguel" placeholder="0,00" value="0,00" maxlength="20">
                        <label>Luz: <span style="color: red">*</span></label>
                        <input v-model="formFields.luz" @input="formatMoney('luz')"  class="inputs_info" type="text" name="luz" id="luz" maxlength="20" value="0,00" placeholder="0,00">
                        <label>Agua:</label>
                        <input v-model="formFields.agua" @input="formatMoney('agua')"  class="inputs_info" type="text" name="agua" id="agua" maxlength="20" value="0,00" placeholder="0,00">
                        <label>IPTU:</label>
                        <input v-model="formFields.iptu" @input="formatMoney('iptu')" class="inputs_info" type="text" name="iptu" id="iptu" maxlength="10" value="0,00" placeholder="0,00">
                    </article>
    
                    <article class="div_informa">
                    <h3 class="titulo_infos"><strong>Descrição da republica:</strong></h3>
                      <textarea id="desc" v-model="formFields.desc" cols="30" rows="5"></textarea>
                    </article>
                    <footer class="div-btn-continue">
                        <button type="submit" class="btn-continue">Salvar alterações <fa :icon="['fas', 'arrow-right']" /></button>
                    </footer>
    
                </form>
    
            </main>
      </div>
    
    
    </template>
    <script>
    import axios from '../../axios';
    import router from '../../routes'
    
    export default {
        data(){
            return{
                republica: [],
                formFields: {
                    _id: '',
                    matricula: '',
                    bairro: '',
                    rua: '',
                    numero: '',
                    comple: '',
                    andar: '',
                    metros_quadrados: '',
                    tipo_imovel: '',
                    sexo: '',
                    relacao: '',
                    vagas: 0,
                    quartos: 0,
                    banheiros: 0,
                    animais: 0,
                    numero_pessoas: 0,
                    desc: '',
                    cep: '',
                    aluguel: '0,00',
                    iptu: '0,00',
                    luz: '0,00',
                    agua: '0,00',
                    valor_condominio: '0,00'
                }
            }
        },
        mounted(){
            axios.get(`/g/republica/find/republica/${this.$route.params.id_repu}`)
            .then(response => {
                this.republica = response.data.republica;
               
                this.formFields = { ...this.republica };
                 
            })
            .catch(error => {
                console.error('Erro ao enviar o arquivo:', error);
            });
        },
        methods: {
            modifyValue(inputId, i) 
            {
                const quantityInput = document.querySelector(`.inputs_info_plus_minus[data-id="${inputId}"]`);
                if(i === '+')
                this.formFields[quantityInput.id] = parseInt(quantityInput.value) + 1;
    
                else if(i === '-' && quantityInput.value > 0)
                this.formFields[quantityInput.id] = parseInt(quantityInput.value) - 1;
        
            },
            formatCEP() 
            {
                let numericCep = this.formFields.cep.replace(/\D/g, '');
    
                if (numericCep.length > 5) 
                  this.formFields.cep = numericCep.slice(0, 5) + '-' + numericCep.slice(5);
            
            },
            formatMoney(value_format) 
            {
                this.formFields[value_format] = this.formFields[value_format].replace(/\D/g, '');
    
                if (this.formFields[value_format].length > 0) 
                {
                    this.formFields[value_format] = parseFloat(this.formFields[value_format]) / 100; 
                    this.formFields[value_format] = this.formFields[value_format].toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    
                }
    
            },
            selecionaTipoImovel(value_input) 
            {
                let tipo_imovel = document.querySelector(`#${value_input}`);
                const condo = document.querySelector('.div-condo');
                const coluna= document.querySelector('.coluna_1');
                const campoObrigatorioInput = document.querySelector('#andar');
    
                campoObrigatorioInput.required = tipo_imovel.value === 'apto';
    
                condo.classList.toggle('div-condo-active', tipo_imovel.value === 'casa_condo');
    
            },
            updateRepu() {
               
               axios.put('/a/republica/update/republica', this.formFields)
                .then(() => {
                    router.push('/');         
                    
                }).catch((error) =>{
                    console.error('Erro na solicitação HTTP:', error);
    
                });
           }
        }
    
    }
    </script>