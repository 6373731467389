<template>
  <NavBar></NavBar>  
  <RouterView />

    <ul id="notification-list">
      <li v-for="(notification, index) in notifications" :key="index" :style="{ bottom: `${index * 50}px` }" class="notification">
        {{ notification.message }}
      </li>
    </ul>

</template>

<script>
import NavBar from './components/NavBar.vue'
import io from 'socket.io-client';
import axios from './axios';



export default {
  name: 'App',
  components: {
    NavBar
  }, 
 data(){
   return {
    socket_like: null,
    notifications: [],
    }
  }, 

  
 async mounted() {
    if (this.$store.state.user_logado._id === '')
    {
      const accessToken = localStorage.getItem("accessToken");
      const refreshToken = localStorage.getItem("refreshToken");
      if (accessToken && refreshToken)
      {
        axios.post(`/a/user/token`, { reqType: 'login' })
        .then(res =>{
            this.$store.commit('logged', res.data.result)
        }) 
        .catch(error => 
        {
          console.error('Erro ao enviar o arquivo:', error);  
          localStorage.removeItem('accessToken')
          localStorage.removeItem('refreshToken')
        }) 
        .catch(error => 
        {
          this.$toast.open({
            message: error.response.data.message,
            type: 'error',
            position: 'top-right',
            duration: 5000,
          });
                  
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
        })
      }
    }

    this.socket_like = io({transports: ["websocket"]});
    this.socket_like.on('like-recebido', (mensagem) => {
        this.showNotification(mensagem)
    });
  },
  methods: {
    showNotification(message) {
      const newNotification = {
        message,
      };

      this.notifications.unshift(newNotification);

      setTimeout(() => {
        this.notifications.pop();
      }, 10000);
    },

  }

 }
</script>
