import {createRouter, createWebHistory} from 'vue-router';
import store from '../store/index';

import Login from "../views/Auth/Login.vue"
import Cadastro from "../views/Auth/Cadastro.vue"
import Chat from "../views/Chat.vue"
import Home from "../views/Home"
import Timeline from "../views/usuario/Timeline"
import TimelineVisita from "../views/usuario/TimelineVisita"
import MostraUser from "../views/usuario/MostraUser"
import Sobre from "../views/usuario/Sobre"
import AnunciarVaga from "../views/republica/AnunciarVaga"
import ConfirmarProposta from "../views/republica/ConfirmarProposta"
import FotoUpdate from "../views/republica/FotoUpdate"
import Proposta from "../views/republica/Proposta"
import InfoRepublica from "../views/republica/InfoRepublica"
import UpdateRepublica from "../views/republica/UpdateRepublica"
import Suporte from "../views/Suporte.vue"
import PropostaUser from "../views/usuario/PropostasUser"
import ViewUserConnections from "../views/usuario/ViewUserConnections"
import NewFoto from "../views/NewFoto.vue"
import SearchRepublicas from "../views/SearchRepublicas"
import Notifica from "../views/usuario/Notifica"
import Config from "../views/usuario/Config"
import ConfirmEmail from "../views/Auth/ConfirmEmail"
import EnterCode from "../views/Auth/EnterCode"
import ResetPassword from "../views/Auth/ResetPassword"
import ShowUsersFollow from "../views/usuario/ShowUsersFollow"

const routes = [
  { path: '/', component: Home},
  {name:'login', path: '/login', component: Login},
  { path: '/suporte', component: Suporte},
  { path: '/cadastro', component: Cadastro},
  { path: '/chat/:user_ativo?', component: Chat, meta: { isAuth: true }},
  { path: '/timeline', component: Timeline, meta: { isAuth: true }},
  { name: 'visita', path: '/timeline/visita/:user_ativo', component: TimelineVisita, meta: { isAuth: true }},
  { path: '/anuncia/vaga', component: AnunciarVaga, meta: { isAuth: true }},
  { path: '/confirma/proposta/:user_id/:repu_id/:propo_id', component: ConfirmarProposta, meta: { isAuth: true }},
  { path: '/foto/update/repu/:id_repu', component: FotoUpdate, meta: { isAuth: true }},
  { path: '/republica/:id_repu', component: InfoRepublica, meta: { isAuth: true }},
  { path: '/update/republica/:id_repu', component: UpdateRepublica, meta: { isAuth: true }},
  { path: '/proposal/user', component: PropostaUser, meta: { isAuth: true }},
  { path: '/connections/:user_ativo?', component: ViewUserConnections, meta: { isAuth: true },  props: { headerText: 'Você ainda não tem conecxões.' }},
  { path: '/new/foto/:user_id', component: NewFoto, meta: { isAuth: true }},
  { path: '/notifica/:user_ativo?', component: Notifica, meta: { isAuth: true }},
  { path: '/config', component: Config, meta: { isAuth: true }},
  { path: '/reset/password/:token', component: ResetPassword},
  { path: '/view/show/users/:user_pesquisa', component: MostraUser, meta: { isAuth: true }, props: { headerText: 'Tente pesquisar por outro usuário.' }},
  { path: '/view/show/repu/:repu_pesquisa?', component: SearchRepublicas},
  { path: '/view/show/sobre/user', component: Sobre, meta: { isAuth: true }},
  { path: '/view/show/users/follow', component: ShowUsersFollow, meta: { isAuth: true }},
  { path: '/reset', component: ConfirmEmail},
  { path: '/enter/code/:token', component: EnterCode},

  { path: '/proposta/vaga',
    name: 'proposta_vaga',
    props: (route) => ({ dados_proposta: JSON.parse(route.query.dados_proposta) }),
    component: Proposta, meta: { isAuth: true }
  },


]


const router = createRouter({
  history: createWebHistory(),
  routes,
})
router.beforeEach((to, from, next) => {

  if (to.name && to.name === 'login' && localStorage.getItem('accessToken')) 
    return next('/timeline'); 

  if (to.name && to.name === 'visita') 
    if (to.params.user_ativo == store.state.user_logado._id) 
      return next('/timeline'); 
    
  
  if (to.matched.some(record => record.meta.isAuth)) 
  {
    if (!localStorage.getItem('accessToken')) 
      next('/login');

    else 
      next();
  }
  else next();


});


export default router;
