function carouselImages()
{
    const carousels = document.querySelectorAll('.image_imoveis');
    carousels.forEach(carousel => {

    const images = carousel.querySelectorAll('img');
    let index = 0;
    function showImage() {
        images.forEach(function(image, idx) {
            image.style.display = idx === index ? 'inline-block' : 'none'
        });

      let indicators = carousel.querySelector('.carousel-indicators');
      let bullets = indicators.querySelectorAll('.carousel-indicator');
      bullets.forEach(function(bullet, idx) {
          bullet.classList.toggle('active', idx === index);
      });


      }


    function nextImage(event) {
        event.preventDefault(); 
        index = (index + 1) % images.length;
        showImage()

    }
    function prevImage(event) {

        event.preventDefault(); 
        index = (index - 1 + images.length) % images.length;
        showImage()

    }

    let buttonLeft = carousel.parentElement.querySelector('.carousel-button-left');
    buttonLeft.onclick = prevImage;
    let buttonRight = carousel.parentElement.querySelector('.carousel-button-right');
    buttonRight.onclick = nextImage;


    let indicators = carousel.querySelector('.carousel-indicators');
    images.forEach(function(image, idx) {
        let indicator = document.createElement('div');
        indicator.className = 'carousel-indicator';
        indicator.onclick = function(event) {
            event.preventDefault(); 
            index = idx;
            showImage()

        };
        indicators.appendChild(indicator);
    });

    showImage()
    })
  
}
module.exports = {carouselImages}


