<template>
    
    <article v-if="not_proposals_sent" class="container_sem_proposta">
        <h1>Nenhuma Proposta enviada!</h1>
        <p>Você ainda não fez nenhuma proposta para uma vaga.</p>
    </article>
    <article class="notifications">
        <div class="notification-content" v-for="Proposal_sent in Proposals_sent">
            <p>Vc fez uma proposta para morar na republica de {{Proposal_sent.nome }}</p>
        </div>
    </article>
</template>
<script>
import axios from '../../axios';

export default {

    data(){
        return{
            Proposals_sent: [],
            not_proposals_sent: false

        }
    },

    watch: {
        '$store.state.user_logado._id': function() {
            this.searchProposals();   

        }
    },

    mounted(){
        this.searchProposals();
       
    },

    methods:{

        searchProposals(){

            axios.get(`/a/republica/proposals/sent/${this.$store.state.user_logado._id}`)
            .then((response) => {
                this.Proposals_sent = response.data.propostas  
                if(this.Proposals_sent == '')
                this.not_proposals_sent = true
            })
            .catch((error) => {
               console.error('Erro ao enviar dados:', error);
 
            })
        }
    }
}
</script>