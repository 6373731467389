
<template>

  <div class="background_login" v-if="blur"></div>
  <article class="container">
    <section class="row justify-content-center">
        <div class="col-md-8 ">
            <section class="card">
                <section class="card-header">
                    <img src="../../assets/images/alueasy2.png" alt="" class="img_login">

                </section>

                <section class="card-body">
                    <form  @submit.prevent="login">

                        <div class="row mb-3">
                            <label for="email" class="col-md-4 col-form-label text-md-end">Email</label>

                            <div class="col-md-6">
                                <input  
                                type="email" 
                                name="email" 
                                id="email"
                                class="form-control" 
                                :class="{ valid : isValidEmail  , inValid : isValidEmail == false}" 
                                v-model="email" 
                                autocomplete="email" 
                                autofocus
                                ref="email">
                            </div>
                           
                        </div>
                        <section class="error" v-if="startValidationEmail && email != '' && !isValidEmail">
                            <p>Email inválido!</p>
                        </section>
                        <section class="error" v-if="showErrorEmail" style="margin: 1px 0 0 50px;">
                            <p>Email não encontrado!</p>
                        </section>


                        <div class="row mb-3">
                            <label for="password" class="col-md-4 col-form-label text-md-end">password</label>
                            <div class="col-md-6">
                                <input 
                                :class="{ valid : isStrongPassword == true, inValid : isStrongPassword == false}"
                                 type="password" 
                                 id="password"
                                 class="form-control" 
                                 name="password" 
                                 v-model="password" 
                                 ref="password" 
                                 autocomplete="current-password">
                            </div>
                        </div>
                        <section  class="error" style="margin: 1px 0 0 50px;" v-if="startValidationSenha && password != '' && !isStrongPassword">
                                <p id="paragrafo_senha">Senha muito fraca!</p>
                        </section>
                        <section class="error" v-if="showErrorSenha">
                            <p>Senha incorreta!</p>
                        </section>

                        <div class="row mb-3">
                            <div class="col-md-6 offset-md-4">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" name="remember" id="remember">

                                    <label class="form-check-label" for="remember">
                                      remember
                                    </label>
                                </div>
                            </div>
                        </div>
                        

                        <div class="row mb-0">
                            <div class="col-md-8 offset-md-4">
                                <button type="submit" class="btn" style="background: #E2085C; color: white">
                                    Login
                                </button>
                                <GoogleLogin :callback="callback" id="google"/>
                                <br/>
                                <button type="button" class="btn btn-link" data-toggle="modal" data-target="#modalForgetPass" @click="blur = !blur">Forgot Your Password</button>
                            </div>
                        </div>
                    </form>
                </section>
            </section>
        </div>
        <div class="modal fade" id="modalForgetPass" tabindex="-1" aria-labelledby="modalForgetPassLabel" ref="modal_send">
            <div class="modal-dialog">
               <div class="modal-content modal_forget">
                    <div class="modal-header">
                       <h5 class="modal-title" id="modalForgetPassLabel"><img src="../../assets/images/alueasy2.png" alt="" style="height: 50px; width: 50px;"></h5>
                        <button type="button" class="close" id="close_forget" @click="blur = !blur" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                      <main class="caixa_principal">
                        <div class="caixa__geral">
                            <div class="caixa__geral--interno">
                              <div class="caixa__geral--interno-rosto">
                                  <div class="caixa__interna">
                                    <div class="caixa_titulo">
                                      <h2>Encontre sua conta</h2>
                                      <p >Digite o e-mail, número de telefone ou nome de usuário associado à sua conta para alterar sua senha.</p>
                                    </div>
                                    <form>
                                        <div class="caixa__login-input">
                                          <input 
                                          type="text" 
                                          v-model="email_confirmacao" 
                                          required />
                                          <label>Email</label>
                                        </div>                                    
                                    </form>
                                  </div>
                              </div>
                              
                            </div>
                        </div>
                      </main>
                      
                    </div>
                    <footer class="modal-footer">
                      <a class="a_envia" @click="emailConfirm">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        Enviar email
                     </a>
                    </footer>
                 </div> 
             </div>
        </div>
    </section>
  </article>

  </template>
  
  <script>
  import axios from '../../axios';
  import router from '../../routes';
  import {decodeCredential} from 'vue3-google-login'
  import { ref } from 'vue';


  export default {

   

    data() {
      return {
        email: ref(''),
        password: ref(''),
        email_confirmacao: '',
        showErrorEmail: false,
        showErrorSenha: false,
        startValidationEmail: ref(false),
        startValidationSenha: false,
        blur: false
      };
    },

    methods: {

       callback(response){

        const credential = decodeCredential(response.credential)
        axios.post('/g/user/auth/google', { credential })
        .then(res =>{ 
            localStorage.setItem('accessToken', res.data.accessToken);
            localStorage.setItem('refreshToken',  res.data.refreshToken);
            this.$store.commit('logged', res.data.user)
            router.push('/timeline');
        }
        ).catch(err => console.error(err));


       },
       login() {


          if (this.isValidEmail && this.isStrongPassword ) 
          {
              const login = {email: this.email, senha: this.password, reqType: 'login' }
              axios.post('/g/user/auten', login)
                .then((response) => {
                  localStorage.setItem('accessToken', response.data.accessToken);
                  localStorage.setItem('refreshToken',  response.data.refreshToken);
                  this.$store.commit('logged', response.data.user)
                  router.push('/timeline');

                })
                .catch((error) => {

                  if(error.response.data.status === 400)
                  {
                    this.$refs.password.style.borderColor = "#8b0000";
                    this.showErrorSenha = true;
                    this.startValidationSenha = false

                  }
                  if(error.response.data.status === 500)
                  {
                    this.$refs.email.style.borderColor = "#8b0000";
                    this.showErrorEmail = true;
                    this.startValidationEmail= false

                  }

              })
          }
    
      },
      emailConfirm(){

        if(this.email_confirmacao !== '')
        {
          const login = {email: this.email_confirmacao}

          axios.post('/g/user/verify/email', login)
          .then((response) => {

                this.blur=false;
                document.getElementById('close_forget').click();
                router.push(`/enter/code/${response.data}`)

          }).catch((error) =>{
              console.error('Erro na solicitação HTTP:', error);

          });
        }
    }
    },
    computed: {
      isValidEmail() {
        return this.startValidationEmail ? /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email) : null;
     },
     isStrongPassword() {
        return this.startValidationSenha ? /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(this.password) : null;

     },
    },
    watch: {
      email() {
        this.startValidationEmail = true
        if(this.startValidationEmail)
         this.$refs.email.style.borderColor = this.isValidEmail ? "#ccc" : "red";
      },
      password() {
        this.startValidationSenha = true
        if(this.startValidationSenha)
          this.$refs.password.style.borderColor = this.isStrongPassword ? "#ccc" : "red";

       
      }
    }

   

  };
  </script>
  
  <style scoped>


.container  {
    max-width:960px;
    margin: 20px auto;
    padding:20px
}
.caixa__geral {
  height: 450px;

}

.caixa__geral--interno {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: all .6s ease-out;
}


.caixa__geral--interno-rosto {

  width: 100%;
  height: 100%;
  background: white;
  position: absolute;
  border-radius: 6px;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;

}

.caixa__geral--interno-rosto .caixa__interna {
  position: absolute;
  width: 100%;
  padding: 0 35px;
  top: 30%;
  left: 0;
  transform: translate3d(0, -50%, 35px) perspective(100px);
  z-index: 20;
  display: block;
}

.caixa_titulo{
  margin: 0 0 40px;
  padding: 0;

}
.caixa__geral--interno .caixa__login-input {
  position: relative;
}

.caixa__geral--interno .caixa__login-input input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
  background: transparent;
  margin-bottom: 28px;
  padding-left: 10px;
}
.caixa__geral--interno .caixa__login-input label {
  position: absolute;
  top: 0;
  left: 10px;
  padding: 10px 0;
  font-size: 16px;
  color: black;
  pointer-events: none;
  transition: 0.5s;
}
.caixa__geral--interno .caixa__login-input input:focus ~ label,
.caixa__geral--interno .caixa__login-input input:valid ~ label {
  top: -30px;
  left: 0;
  color: black;
  font-size: 12px;
}
.a_envia {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #e2085c;
  font-size: 13px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 5px;
  letter-spacing: 2px;
  cursor: pointer;
}

.a_envia:hover {
  background: #e2085c;
  color: #e2085c;
  border-radius: 5px;
  box-shadow: 0 0 5px #e2085c, 0 0 25px #e2085c, 0 0 50px #e2085c,
    0 0 100px #e2085c;
}


.a_envia span {
  position: absolute;
  display: block;
}

.a_envia span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #e2085c);
  animation: animacaoBotao1 1s linear infinite;
}


@keyframes animacaoBotao1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

.a_envia span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #e2085c);
  animation: animacaoBotao2 1s linear infinite;
  animation-delay: 0.25s;
}


@keyframes animacaoBotao2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

.a_envia span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #e2085c);
  animation: animacaoBotao3 1s linear infinite;
  animation-delay: 0.5s;
}

@keyframes animacaoBotao3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

.a_envia span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #e2085c);
  animation: animacaoBotao4 1s linear infinite;
  animation-delay: 0.75s;
}

@keyframes animacaoBotao4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}

  .error{
    position: relative;
    display: flex;
    bottom: 13px;
    justify-content: center;
    color:  #8b0000;
    margin: 1px 0 0 2px;
 
  }

  .error p{
    font-size: 0.8em;
    margin-right: 38px;
  }
  #paragrafo_senha{margin-right: 80px;}
 

  .modal_forget{
    min-height: 60vh;
  }
  #modalForgetPass{
    z-index: 7000;
  }
  .background_login  {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color: gray;
    filter: blur(5px); 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 2000; 

}
#google{
  margin: 0 0 3px 15px;
  width: 35px;
  cursor: pointer;
  position: relative;
  top: 18px;
}
  
  
  </style>
  
