<template>

    <div class="modal fade" id="modal-pesquisa" ref="modal_pesquisa">
         <div class="modal-dialog">
             <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="UserPesquisaLabel">Pesquisar usuario</h5>
                    <button type="button" class="close" ref="Close" id="closes" data-dismiss="modal"><span>×</span></button>
                </div>
                <div class="modal-body">
                    <form  @submit.prevent="searchUsers">
                        <input class="noPesquisa" type="search" placeholder="Search..." v-model="pesquisaUsuario" name="pesquisaUsuario" @keydown.enter="searchUsers">
                        <button type="submit"></button>
    
                    </form>
                </div>
            </div>
         </div>
    </div>
     <footer class="bar_down">
            <nav class="nav_flex">
                <button type="button" class="btn_pesquisa sidebar_link" id="button_pesquisa" data-toggle="modal" data-target="#modal-pesquisa"><fa :icon="['fas', 'magnifying-glass']" /></button>
                <RouterLink to="/chat" class="sidebar_link"><fa :icon="['far', 'envelope']" class="icone_sidebar" /><span class="span_count">{{$store.state.count_messages}}</span></RouterLink>
                <RouterLink to="/proposal/user" class="sidebar_link"><img :src="require('@/assets/images/business-proposal-nav.svg')" alt="propostas" class="img_menu_navdown" ><span class="span_count">{{count_proposals}}</span></RouterLink>
                <RouterLink to="/view/show/sobre/user" class="sidebar_link"><img src="../../assets/images/user.svg" alt="" class="img_person_navdown"></RouterLink>
                <RouterLink to="/notifica" class="sidebar_link"><fa :icon="['far', 'bell']" class="icone_sidebar" /></RouterLink>            
    
            </nav>
    </footer>
    </template>
    <script>
      import router from '../../routes'
      import axios from '../../axios';
    
    export default {
        props: {
            user_logado: Object
        },
        data(){
            return{
                pesquisaUsuario: '',
                count_proposals: '',
            }
        },
        watch: {
            user_logado(){
               axios.get(`/a/republica/count/proposals/${this.user_logado._id}`)
               .then(res => {
                    this.count_proposals = res.data.count_proposals 
                })
                .catch(error => {
                    console.error('Erro ao enviar o arquivo:', error);
                });
            }
        },
        methods: {
            searchUsers(){
                document.getElementById('closes').click();
                router.push(`/view/show/users/${ this.pesquisaUsuario}`); 
        
            }
        }
    
    
    }
    </script>
    <style scoped>
    
    .img_menu_navdown, .img_person_navdown{
        width: 18px;
        margin-bottom: 2px;
    }
    .img_person_navdown{
        width: 23px;
    }
    .span_count{
        font-size: 15px;
        position: relative;
        bottom: 5px;
        left: 3px;
        color: var(--color);
    }
    .sidebar_link {
        font-size: 20px;
      padding:10px 0 0 3.90625vw;
      margin-bottom: 5px;
     }
    </style>
