<template>
    <div class="div_center">
        <article class="reset-password-container">
            <h2 class="reset-title">Resetar Senha</h2>
            <form @submit.prevent =updatePassword class="reset-form" id="resetPasswordForm">
                <label class="reset-label" for="newPassword">Nova Senha:</label>
                <input 
                 type="password" 
                 ref="new_password" 
                 class="reset-password-input" 
                 v-model="new_password" 
                 autocomplete="new-password" 
                 :class="{ valid : isStrongPassword == true , inValid : isStrongPassword == false}">
                 <div class="error" v-if="startValidationSenha && new_password != '' && !isStrongPassword">
                  <p>Senha muito fraca! Use Letras maiúsculas, números, e caracteres especiais</p>
                </div>
                <label class="reset-label" for="confirmPassword">Confirmar Senha:</label>
                <input
                 type="password" 
                 v-model="confirm_password" 
                 ref="confirm_password" 
                 class="reset-password-input" 
                 autocomplete="confirm-new-password"
                 :class="{ valid : isPasswordConfirmed == true , inValid : isPasswordConfirmed == false}">
                <button type="submit" class="reset-button">Resetar Senha</button>
            </form>
            <section class="token_expirred" v-if="token_expirred">
                <p>Sua Sessão expirou, faça o processo novamente!</p>
            </section>
        </article>
    </div>
    
    
    </template>
    <script>
      import axios from '../../axios';
    
        export default {
            data(){
                return{
                    new_password: '',
                    confirm_password: '',
                    startValidationSenha: false,
                    startValidationConfirmPass: false,
                    token_expirred: false
                }
            },
            methods:{
    
                updatePassword(){
                  const user = {
                    new_password: this.new_password,
                    token: this.$route.params.token,
                  }
                  axios.put('/g/user/reset/password', user)
                  .then(() => {
                        this.$router.push('/login');         
    
                  })
                  .catch(error => {
                    if (error.response && error.response.status === 400) 
                        this.token_expirred = true;
                  });
                }
            },
            computed: {
                isStrongPassword() {
                    return this.startValidationSenha ? /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(this.new_password) : null;
                },
                isPasswordConfirmed() {
                return this.startValidationSenha ? this.new_password == this.confirm_password : null;
                }
            },
            watch: {
                new_password() {
                    this.startValidationSenha = true
                    if(this.startValidationSenha)
                    this.$refs.new_password.style.borderColor = this.isStrongPassword ? "#ccc" : "red";
                },
                confirm_password() {
                    if(this.startValidationSenha)
                      this.$refs.confirm_password.style.borderColor = this.isPasswordConfirmed ? "#ccc" : "red";
                }
            },
       
        }
    </script>
    <style scoped>
    
    .div_center{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 70vh;
    }
    .reset-password-container {
       
        width: 400px;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
    
    .reset-title {
        text-align: center;
        color: #333;
    }
    
    .reset-form {
        display: flex;
        flex-direction: column;
    }
    
    .reset-label {
        margin-bottom: 8px;
        color: #333;
    }
    
    .reset-password-input {
        padding: 8px;
        margin-bottom: 16px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }
    
    .reset-button {
        background-color: var(--color);
        color: #fff;
        padding: 10px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }
    
    .reset-button:hover {
        background-color: #0056b3;
    }
    
    .error{
      position: relative;
      display: flex;
      bottom: 13px;
      justify-content: center;
      color:  #8b0000;
      margin: 1px 0 0 2px;
     
    }
    .error p{
      font-size: 0.8em;
      margin-right: 38px;
    }
    
    #paragrafo_senha{margin-right: 80px;}
    
    .token_expirred{
        margin-top: 7px;
        color: #8b0000;
    }
    
    
    </style>
    