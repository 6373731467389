<template>
    
    <div class="flex">
        <main class="timeline_principal">
            
            <section class="foto-capa">
                <section class="div_infos_perfil" v-if="showPerfil">
                    <div>
                        <div class="wrapper"
                                :style="{ 'background-image': `url(${ user.picture })` }">
 
                        </div>
 
                    </div>
                    <section class="div_perfil_info">
                        <div class="div_infos_perfil">
                            <p class="info_perfil_timeline nome_perfil">{{user.nome}}</p>
                            <div class="flex">
                               <p v-if="userSeguindo(user)" class="info_perfil_timeline"><button @click="followUser(user._id)" class="botao_seguir">Seguindo</button></p>
                                 <p class="info_perfil_timeline" v-else><button class="botao-edit-perfil-timeline" @click="followUser(user._id)" style="font-weight: normal;" >Seguir</button></p>
                                <RouterLink :to="`/chat/${user_ativo}`"><p class="info_perfil_timeline"><button type="button" class="botao-chat-perfil-timeline"><fa :icon="['fa', 'envelope']"  /></button></p></RouterLink>
                            </div>
 
                        </div>
                        <div class="flex">
                        <RouterLink :to="`/mostra/seguindo/${user_ativo}`"><p class=info_perfil_timeline>Seguindo {{ user.count_seguindo }}</p></RouterLink>
                            <RouterLink :to="`/mostra/seguidores/${user_ativo}`"><p class=info_perfil_timeline>Seguidores {{ user.count_seguidores }}</p></RouterLink>
                        </div>
                        <div class="bio_perfil_timeline">
                            <p class=info_perfil_timeline>{{user.bio}}</p>
                        </div>
                     </section>
                </section>
                <section class="div_infos_perfil_mobile" v-if="showPerfilMobile">
               
                    <div class="">
                        <div class="wrapper"
                        :style="{ 'background-image': `url(${ user.picture })` }">

                        </div>

                    </div>
                    <div class="div_image_perfil_mobile"></div>
                    <p class="paragrafo_nome">{{ user.nome }}</p>
                    <div style="display:flex; flex-direction: column;">
                       <RouterLink :to="`/chat/${user_ativo}`"><button type="button" class="botao_mobile"><fa :icon="['fa', 'envelope']"  /></button></RouterLink> 
                       <button v-if="userSeguindo(user)" @click="followUser(user._id)" class="botao_mobile" style=" background-color: rgba(226,8,92,.8); font-weight: normal;">Seguindo</button>
                       <button v-else class="botao_mobile" @click="followUser(user._id)" style="font-weight: normal;" >Seguir</button>
                    </div>
                    <p>{{user.bio}}</p>
 
                    <div class="table_seguir">
                        <RouterLink :to="`/mostra/seguindo/${user_ativo}`"><p>Seguindo {{ user.count_seguindo }}</p></RouterLink>
                        <RouterLink :to="`/mostra/seguidores/${user_ativo}`"><p>Seguidores {{ user.count_seguidores }}</p></RouterLink>
                    </div>
 
                    
                </section>
            </section>          
            <section class="conteudo_timeline">
                <div class="tabs">
                    <span
                        v-for="(tab, index) in tabs"
                        :key="index"
                        :data-tab-value="tab.value"
                        @click="changeTab(tab.value)"
                        :class="{ active: tab.value === activeTab }"
                    >
                        {{ tab.label }}
                    </span>
                </div>
                <div class="tab-content">
                    <div
                        v-for="(tabInfo, index) in tabInfos"
                        :key="index"
                        :class="{ 'tabs__tab': true, active: tabInfo.value === activeTab }"
                        :id="tabInfo.value.substring(1)"
                        data-tab-info
                    >
                        <component v-if="user" :user_ativo="user" :is="getComponentForTab(tabInfo.value)"></component>
 
                    </div>
                </div>
            </section>
        </main>
        <Aside :user_logado="user"></Aside>
    </div>
    <Footer :user_logado="user"></Footer>
 </template>
 
 <script>
 import Aside from './Aside.vue'
 import Footer from './Footer.vue'
 import Republica from './Republica.vue'
 import Hobbie from './Hobbie.vue'
 import axios from '../../axios';
 
 
 
 export default{
    components: {
       Aside,
       Footer,
       Republica,
       Hobbie,      
    }, 
    data(){
        return{ 
            user_ativo: '',
            payload: '',
            activeTab: "#tab_1",
            tabs: [
                { label: "Republicas", value: "#tab_1" },
                { label: "Hobbies e gostos", value: "#tab_2" },
            ],
            tabInfos: [
                { value: "#tab_1" },
                { value: "#tab_2" },
            ],
            user: {},
            showPerfil: true,
            showPerfilMobile: false
           };
    },
    watch:{
 
        '$route.params.user_ativo': function () {
            this.user_ativo = this.$route.params.user_ativo
            axios.get(`/a/user/get/logado/${this.user_ativo}`).then((response) => {
                    this.user = response.data.user; 
 
            });
       
         },
 
    },
    async mounted(){
    
        this.user_ativo = this.$route.params.user_ativo   
        await axios.get(`/a/user/get/logado/${this.user_ativo}`).then((response) => {
                this.user = response.data.user;
 
        });
        this.checkMobile();
        window.addEventListener('resize', this.checkMobile)
 
      
    
    },
    methods: {

        userSeguindo(user) {

            if(user.seguidores){    
                return user.seguidores.includes(this.$store.state.user_logado._id) 
            }
        },

        changeTab(tabValue) {
            this.activeTab = tabValue;
        },
 
        getComponentForTab(tabValue) {
            switch (tabValue) {
                case "#tab_1":
                return "Republica";
                case "#tab_2":
                return "Hobbie";
                default:
                return null;
            }
        },

        checkMobile()
        {
            if(window.innerWidth <= 480){ 

                 this.showPerfilMobile = true
                 this.showPerfil = false
 
            } 
            else {

                this.showPerfilMobile = false
                 this.showPerfil = true
            }
 
 
        },

        followUser(user_id)
        {
          const usuario_seguindo = {

              user_seguidor: this.$store.state.user_logado._id,
              user_seguindo: user_id

          }
            
          const user_following = this.user.seguidores.includes(this.$store.state.user_logado._id)

          if(user_following){

            this.user.seguidores = this.user.seguidores.filter(id => id !== this.$store.state.user_logado._id)
          }
          else{

            this.user.seguidores.push(this.$store.state.user_logado._id)
          }
                     
 
          axios.post('/a/user/follow/user', usuario_seguindo).catch((error) => {
             console.error('Erro ao enviar dados:', error);
          })
              
          
        },
       
    }
 }
 </script>