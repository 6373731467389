<template>
    <div class="caixa_reset">
        <h3>Como você quer redefinir a sua senha?</h3>
        <div>
          <div class="usuario_foto">
            <img :src="$store.state.user_logado.picture" alt="Foto do Usuário"  />
            <p>{{ $store.state.user_logado.nome }}</p>
          </div>
          <p> Enviar email para {{ obfuscateEmail($store.state.user_logado.email) }}</p>
       </div>
       <button @click="enviarEmail" class="btn_envia">próximo</button>
   </div>
  </template>
  
  <script>
    import axios from '../../axios';

  export default {
    data() {
      return {
      
      };
    },
  
    methods: {

      obfuscateEmail(email) {
        const [username, domain] = email.split('@');
        const obfuscatedEmail = `${username.slice(0, 3)}***@${domain.charAt(0)}****.***`;
        return obfuscatedEmail;
      },
      enviarEmail() {

        const login = {email: this.$store.state.user_logado.email}

        axios.post('/g/user/verify/email', login)
        .then((response) => {
              this.$router.push(`/enter/code/${response.data}`)

        }).catch((error) =>{
            console.error('Erro na solicitação HTTP:', error);

        });

      },
    },
  };
  </script>
  
  <style scoped>
   .caixa_reset{
      max-width: 620px;
      margin: 30px auto 20px;
   }
   .usuario_foto{
      display: flex;
      margin: 20px 0 20px 0;
   }
   .usuario_foto p{
     font-size: 1.4em;
     padding: 2px 0 0 8px;
     font-weight: bold;

   }
   h3{
    font-weight: bold;

   }
   .usuario_foto img{
      width: 50px;
      height: 50px;
      border-radius: 40px;
   }

   @media only screen and (max-width: 580px){
      .caixa_reset{
        width: 88%;
    }
   }

  </style>
  