<template>
    <div class="flex">
            <aside class="coluna_1">
                <div class="div_columns_1">
                    <button class="button_colums">2</button>
                    <p class="p_columns">Escolha algumas fotos para sua republica</p>
                </div>
            </aside>
            <main class="coluna_2">
            
                <header class="menu_superior">
                    <button type="button">2</button><p class="p_imovel_dado">Fotos da republica</p>
                </header>
                <article id="form_envia_image">    
                        <h3 class="titulo_image_repu"><strong>Fotos da republica</strong></h3>
                        <div id="fotos_anuncia">
                            <section class="foto_input">
    
                                <label for="upload_foto">
    
                                    <div class="overlay-repu">
                                        <div class="icone_anun_foto"><fa :icon="['fas', 'camera']" /></div>
                                    </div>
                                    <input 
                                       type="file" 
                                       name="image" 
                                       id="upload_foto" 
                                       style="display:none"
                                       @change="handleImageSelect" />
                                </label>
    
                            </section>
                            <section id="preview">
                                <img
                                v-for="(image, index) in selectedImages"
                                :key="index"
                                :src="image.url"
                                :width="80"
                                :height="80"
                                class="image-preview"
                                />
                            </section>
                        </div>
                        <footer class="div-btn-continue div-btn-foto">
                            <button type="button" class="btn-continue btn_enviar_repu" id="uploadButton" @click="uploadImages">Criar republica <fa :icon="['fas', 'arrow-right']" /></button>    
                        </footer>
                </article>
    
            </main>
        
    </div>
    
    </template>
    <script>
       import { formToJSON } from 'axios';
import axios from '../../axios';
       import router from '../../routes'
    
        export default {
    data() {
        return {
            selectedImages: [],
        };
    },
    methods: {
        handleImageSelect(event) {
            const files = event.target.files;
            for (const file of files) {
                const extension = file.name.split('.').pop().toLowerCase();
                if (extension === 'jpg' || extension === 'png' || extension === 'webp') {
                    const url = URL.createObjectURL(file);
                    this.selectedImages.push({ file, url });
                }
                else {
                    this.mostraModalAlerta('Só são permitidos formatos de imagens em JPG, PNG ou WebP');
                }
            }
        },
        uploadImages() {
            const formData = new FormData();
            this.selectedImages.forEach(image => {
                formData.append('images[]', image.file);
            });
            axios.post(`/a/republica/store/pictures/${this.$route.params.id_repu}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(() => {
                router.push('/');
            }).catch((error) => {
                console.error('Erro na solicitação HTTP:', error);
            });
        }
    },
    components: { formToJSON }
}
    
    </script>