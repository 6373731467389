<template>
  <div v-if="visible" class="modal-overlay" @click="closeModal">
    <div class="modal-content" @click.stop>
      <header class="modal-header">
        <button  type="button" @click="closeModal" class="close-modal">x</button>
      </header>
      <section class="modal-body">
        <slot></slot>
      </section>
      <footer class="modal-footer">
          <button class="modal-save" type="button" @click="save">Salvar</button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    save(){
      this.$emit('save');
    }
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 50%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.modal-header {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.close-modal{

    cursor: pointer;
    background: none;
    font-weight: bold;
    font-size: 1.1em;
    margin-top: -10px;
}
.modal-save{
  background: var(--color);
  color: var(--color4);
  padding: 9px 30px 9px 30px;
  border-radius: 8px;
  margin-bottom: -20px;
  font-size: 0.9em;
}
.modal-body {
  margin: 20px 0;
}
.modal-footer {
  display: flex;
  justify-content: flex-end;
}
.default-button {
  padding: 10px 20px;
  cursor: pointer;
}
</style>
