<template>
  
  <div class="div_cadastro">
    
      <form @submit.prevent="submitForm">
          <div class="image_cadastro">
            <img src="../../assets/images/alueasy2.png" alt="logo" class="img_cadastro">
          </div>
          <label class="label_form_register" for="name">Nome:</label>
          <input class="inputs_cadastro" v-model="formData.name" type="text" id="name" name="name" required>
        

          <label class="label_form_register" for="email">Email:</label>
          <input 
          v-model="formData.email" 
          type="email" 
          ref="email" 
          name="email" 
          id="input_email"
          autocomplete="email"
          :class="{ valid : isValidEmail, inValid : isValidEmail == false}">

          <div class="error" v-if="startValidationEmail && formData.email != '' && !isValidEmail">
              <p>Email inválido!</p>
          </div>
          <div class="error" v-if="showErrorEmail">
            <p>Esse email já existe, tente outro!!</p>
          </div>

          <label class="label_form_register" for="password">Senha:</label>
          <input 
          class="inputs_cadastro" 
          v-model="formData.password" 
          type="password" id="password" 
          ref="password" 
          name="password" 
          required 
          autocomplete="current-password"
          :class="{ valid : isStrongPassword == true , inValid : isStrongPassword == false}">
          <div class="error" v-if="startValidationSenha && formData.password != '' && !isStrongPassword">
            <p>Senha muito fraca! Use Letras maiúsculas, números, e caracteres especiais</p>
          </div>

          <label class="label_form_register" for="password">Confirma Senha:</label>
          <input 
          class="inputs_cadastro" 
          v-model="formData.confirmPassword" 
          type="password"
          ref="confirm_pass" 
          autocomplete="confirm-password"
          :class="{ valid : isPasswordConfirmed == true , inValid : isPasswordConfirmed == false}">
       

          <label class="label_form_register gender-label">Gênero:</label>
          <div class="gender-container">
            <div class="gender-radio-group">
              <input v-model="formData.gender"  @change="apagaColor" class="radio_info" type="radio" id="sexo-masc" name="gender" value="masc">
              <label for="sexo-masc" class="label_carac">
                  <div class="sexo-btn"></div>
                    <span>Masculino</span>
              </label>
              <input type="radio" v-model="formData.gender"  @change="apagaColor" class="radio_info" id="sexo-fem" name="gender" value="fem" >
              <label for="sexo-fem" class="label_carac">
                  <div class="sexo-btn"></div>
                  <span>feminino</span>

              </label>
              <input v-model="formData.gender"  @change="apagaColor" class="radio_info" type="radio" id="outro" name="gender" value="outro" >
                  <label for="outro" class="label_carac label_gender">
                    <div class="sexo-btn"></div>
                    <span>Outro</span>

                </label>

            </div>
          </div>
          <div class="error" v-if="showErrorGender">
            <p>Preencha essa campo!!</p>
          </div>

          <label class="label_form_register" >Estado:</label>
          <div class="dob-container">
            <select v-model="formData.selectedState" @change="fetchCitys" required>
              <option value="" disabled></option>
              <option v-for="state in states" :key="state.id" :value="state.sigla">
                {{ state.nome }}
              </option>
            </select>
          </div>

          <label class="label_form_register" >Cidade:</label>
          <div class="dob-container">
            <select v-model="formData.selectedCity" :disabled="!formData.selectedState" required>
              <option value="" disabled></option>
              <option v-for="city in citys" :key="city.id" :value="city.nome">
                {{ city.nome }}
              </option>
            </select>
          </div>

          <label class="label_form_register" >Fumante:</label>
          <div class="dob-container">
            <select v-model="formData.smoker" required>
              <option value="" disabled></option>
              <option>Não</option>
              <option>Sim</option>
            </select>
          </div>


          <label class="label_form_register" >Curso:</label>
          <div class="dob-container">
            <select v-model="formData.course" required>
              <option value="" disabled></option>
              <option v-for="course in courses" :value="course.nome">{{ course.nome }}</option>
            </select>
          </div>

          <label class="label_form_register" >Data de Nascimento:</label>
          <div class="dob-container">
            <select v-model="formData.day" required>
              <option value="" disabled>Dia</option>
              <option v-for="day in days" :key="'day-' + day" :value="day">{{ day }}</option>
            </select>

            <select v-model="formData.month" required>
              <option value="" disabled>Mês</option>
              <option v-for="(month, index) in months" :key="'month-' + index" :value="index + 1">{{ month }}</option>
            </select>

            <select v-model="formData.year" required>
              <option value="" disabled>Ano</option>
              <option v-for="year in years" :key="'year-' + year" :value="year">{{ year }}</option>
            </select>
          </div>
          <button class="btn_cadastrar" type="submit">Cadastrar</button>
        </form>
  </div>
  
</template>
  
  <script>
  import axios from '../../axios';
  import router from '../../routes'


  export default {
    

    data() {
      const currentYear = new Date().getFullYear();

      return {
        formData: {
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        gender: '',
        day: '',
        month: '',
        year: '',
        course: '',
        smoker: '',
        selectedState: '',
        selectedCity: '',
        },
        citys: [],
        states: [],
        days: Array.from({ length: 31 }, (_, i) => i + 1),
        months: [
          'Janeiro', 'Fevereiro', 'Março', 'Abril',
          'Maio', 'Junho', 'Julho', 'Agosto',
          'Setembro', 'Outubro', 'Novembro', 'Dezembro',
        ],
        courses: [
          { id: 1, nome: "ABI - Artes Cênicas" },
          { id: 2, nome: "ABI - Ciências Biológicas" },
          { id: 3, nome: "Administração" },
          { id: 4, nome: "Agronegócio" },
          { id: 5, nome: "Agronomia" },
          { id: 6, nome: "Alimentos" },
          { id: 7, nome: "Arquitetura e Urbanismo" },
          { id: 8, nome: "Arquivologia" },
          { id: 9, nome: "Artes Cênicas - Direção Teatral" },
          { id: 10, nome: "Artes Cênicas - Interpretação Teatral" },
          { id: 11, nome: "Artes Visuais" },
          { id: 12, nome: "Ciência da Computação" },
          { id: 13, nome: "Ciências Biológicas" },
          { id: 14, nome: "Ciências Contábeis" },
          { id: 15, nome: "Ciências Econômicas" },
          { id: 16, nome: "Ciências Sociais" },
          { id: 17, nome: "Comunicação Social - Produção Editorial" },
          { id: 18, nome: "Comunicação Social - Publicidade e Propaganda" },
          { id: 19, nome: "Comunicação Social - Relações Públicas" },
          { id: 20, nome: "Dança - Bacharelado" },
          { id: 21, nome: "Dança - Licenciatura" },
          { id: 22, nome: "Desenho Industrial" },
          { id: 23, nome: "Direito" },
          { id: 24, nome: "Educação Especial" },
          { id: 25, nome: "Educação Física" },
          { id: 26, nome: "Eletrônica Industrial" },
          { id: 27, nome: "Enfermagem" },
          { id: 28, nome: "Engenharia Acústica" },
          { id: 29, nome: "Engenharia Aeroespacial" },
          { id: 30, nome: "Engenharia Ambiental e Sanitária" },
          { id: 31, nome: "Engenharia Civil" },
          { id: 32, nome: "Engenharia Elétrica" },
          { id: 33, nome: "Engenharia Florestal" },
          { id: 34, nome: "Engenharia Mecânica" },
          { id: 35, nome: "Engenharia Química" },
          { id: 36, nome: "Engenharia da Computação" },
          { id: 37, nome: "Engenharia de Controle e Automação" },
          { id: 38, nome: "Engenharia de Produção" },
          { id: 39, nome: "Engenharia de Telecomunicações" },
          { id: 40, nome: "Estatística" },
          { id: 41, nome: "Fabricação Mecânica" },
          { id: 42, nome: "Farmácia" },
          { id: 43, nome: "Filosofia" },
          { id: 44, nome: "Fisioterapia" },
          { id: 45, nome: "Fonoaudiologia" },
          { id: 46, nome: "Física" },
          { id: 47, nome: "Geografia" },
          { id: 48, nome: "Geoprocessamento" },
          { id: 49, nome: "Gestão Ambiental" },
          { id: 50, nome: "Gestão de Cooperativas" },
          { id: 51, nome: "Gestão de Turismo" },
          { id: 52, nome: "História" },
          { id: 53, nome: "Jornalismo" },
          { id: 54, nome: "Letras - Espanhol" },
          { id: 55, nome: "Letras - Inglês" },
          { id: 56, nome: "Letras - Língua Portuguesa" },
          { id: 57, nome: "Letras - Português" },
          { id: 58, nome: "Matemática" },
          { id: 59, nome: "Medicina" },
          { id: 60, nome: "Medicina Veterinária" },
          { id: 61, nome: "Meteorologia" },
          { id: 62, nome: "Música" },
          { id: 63, nome: "Música e Tecnologia" },
          { id: 64, nome: "Nutrição" },
          { id: 65, nome: "Odontologia" },
          { id: 66, nome: "Pedagogia" },
          { id: 67, nome: "Processos Químicos" },
          { id: 69, nome: "Psicologia" },
          { id: 70, nome: "Química" },
          { id: 71, nome: "Química Industrial" },
          { id: 72, nome: "Redes de Computadores" },
          { id: 73, nome: "Relações Internacionais" },
          { id: 74, nome: "Serviço Social" },
          { id: 75, nome: "Sistemas de Informação" },
          { id: 76, nome: "Sistemas para Internet" },
          { id: 77, nome: "Teatro" },
          { id: 78, nome: "Terapia Ocupacional" },
          { id: 79, nome: "Zootecnia" }
        ],
        currentYear: currentYear,
        years: Array.from({ length: 100 }, (_, i) => currentYear - i),
        showErrorEmail: false,
        showErrorEmailInvalido: false,
        showErrorSenha: false,
        showErrorGender: false,
        startValidationEmail: false,
        startValidationSenha: false,
        startValidationConfirmPass: false      
      };
    },
  
    async mounted(){
         this.showModal = true;
          const { data } = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
          this.states = data;
    },
    methods: {

      submitForm() {
        
        if(this.isValidEmail && this.isStrongPassword && this.isPasswordConfirmed)
        {

            axios.post('/g/user/form', this.formData)
            .then((response) => {
              localStorage.setItem('accessToken', response.data.accessToken);
              localStorage.setItem('refreshToken',  response.data.refreshToken);
              this.$store.commit('logged', response.data.user)

              router.push(`new/foto/${response.data.user._id}`);
            })
            .catch((error) => {
             
              if(error.response.data.status === 401)
              {
                this.$refs.email.style.borderColor = "#8b0000";
                this.showErrorEmail = true;
              }

              if(error.response.data.status === 402)
                this.showErrorGender = true;
            
        
            })
        }
      },
      async fetchCitys() {
        try {

          if (!this.formData.selectedState) {
            return;
          }
          const { data } = await axios.get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${this.formData.selectedState}/municipios`
          );
          this.citys = data;
        }
        catch (error) {
          console.log(error)
        }

      },
      apagaColor(){
          
           if(this.showErrorSenha)
           {   
              this.$refs.password.style.borderColor = "#ccc";
              this.showErrorSenha = false;
           }
           if(this.showErrorEmail)
           {
              this.$refs.email.style.borderColor = "#ccc";
              this.showErrorEmail = false;
           }
           if(this.showErrorEmailInvalido)
           {
              this.$refs.email.style.borderColor = "#ccc";
              this.showErrorEmailInvalido = false;
           }
           
           if(this.showErrorGender)
              this.showErrorGender = false; 
           
      }

    },
    computed: {
      isValidEmail() {
        return this.startValidationEmail ? /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.formData.email) : null;

      },
      isStrongPassword() {
        return this.startValidationSenha ? /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(this.formData.password) : null;

      },
      isPasswordConfirmed() {
        return this.startValidationConfirmPass ? this.formData.password == this.formData.confirmPassword : null;
      }
    },
    watch: {
      'formData.email': function () {
        this.startValidationEmail = true
        if(this.startValidationEmail)
         this.$refs.email.style.borderColor = this.isValidEmail ? "#ccc" : "red";
      },
      'formData.password': function() {
        this.startValidationSenha = true
        if(this.startValidationSenha)
          this.$refs.password.style.borderColor = this.isStrongPassword ? "#ccc" : "red";

       
      },
      'formData.confirmPassword': function() {
        this.startValidationConfirmPass = true
        if(this.startValidationConfirmPass)
         this.$refs.confirm_pass.style.borderColor = this.isPasswordConfirmed ? "#ccc" : "red";

      }
    }

  };
  </script>
  
  <style scoped>

  .error{
    position: relative;
    bottom: 10px;
    color:  #8b0000;
    margin: 1px 0 20px 30px;
 
  }
  .error p{
    font-size: 0.8em;
  }

  .div_cadastro {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 30px 0 30px 0;
    }
    .image_cadastro{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
   
    }
    .img_cadastro{
      width: 110px;
      height: 110px;
      border-radius: 20px;
      padding: 10px;
      background: white;
      box-shadow:  5px 5px 10px #a1a1a1,
             -5px -5px 100px #ffffff;
    }

    form {
      padding: 20px;
      border-radius: 20px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      width: 100%;
      max-width: 700px;
    }

    .label_form_register {
      display: block;
      margin-bottom: 8px;
      font-weight: bold;
      margin-left: 20px;

    }

   input,
    select {
      width: 80%;
      padding: 8px;
      border-radius: 10px;
      margin-bottom: 16px;
      margin-left: 20px;
      box-sizing: border-box;
      border: 1px solid #ccc;
    }

  


    .gender-container {
       display: flex;
      align-items: center;
      margin-bottom: 16px;
      }

    .gender-radio-group {
      display: flex;
      gap: 8px;
      margin-left: 20px;

    }
    .gender-label {
          margin-bottom: 8px;
          display: block;
        }

    .gender-radio {
      margin-right: 8px;
    }

    @media only screen and (max-width: 480px) {
      .label_carac {
        flex-direction: column; 
        align-items: flex-start; 
       }
      .sexo-btn {
            margin-bottom: 5px; 
        }
        form {box-shadow: none }

    }

    .dob-container {
      display: flex;
      gap: 8px;
      margin-bottom: 16px;
    }

    .dob-input {
      flex: 1;
    }

    .btn_cadastrar {
      background-color: var(--color);
      color: #fff;
      padding: 10px;
      border: none;
      border-radius: 10px;
      cursor: pointer;
      width: 100%;
    }

    .btn_cadastrar:hover {
      background-color: rgba(128, 128, 128, 0.7);
      color: black;
      border: 1px solid #ccc;


    }
  

  </style>
  