<template>
    <article v-if="not_proposals_receive" class="container_sem_proposta">
        <h1>Nenhuma Proposta recebida!</h1>
        <p>Você ainda não recebeu nenhuma proposta.</p>
    </article>
    <article class="notifications">
      <div class="notification-content" v-for="Proposal_receive in Proposals_receive">
        <RouterLink :to="`/confirma/proposta/${Proposal_receive.userSendProposal}/${Proposal_receive.repuId}/${Proposal_receive._id}`">
            <p>{{Proposal_receive.nome.charAt(0).toUpperCase() + Proposal_receive.nome.slice(1)}} aceitou a proposta para sua republica <fa v-if="Proposal_receive.anfitriao_aceitou" :icon="['fas', 'check']" /></p>
        </RouterLink> 
      </div>
    </article>

</template>
<script>
import axios from '../../axios';

 export default {

    data(){
        return{
            Proposals_receive: [],
            not_proposals_receive: false,
        }
    },

    watch: {
        '$store.state.user_logado._id': function() {
            this.searchProposals();   

        }
    },

    mounted(){

        this.searchProposals();   
    },
   
    methods:{

        searchProposals(){

            axios.get(`/a/republica/proposals/receive/${this.$store.state.user_logado._id}`)
            .then((response) => {

                  this.Proposals_receive = response.data.propostas
                  
                  if(this.Proposals_receive == '')
                    this.not_proposals_receive = true
            })
            .catch((error) => {
                console.error('Erro ao enviar dados:', error);

            }) 
        }
    }
 }
</script>