<template>
     <div class="background"></div>
        <div id="central_suporte">     
            <h1>Central de Suporte</h1>
        </div>
        <div class="user_container">
            <section class="faq">
                <h2>Perguntas Frequentes</h2>
                <div class="question">
                    <h3>Como entro em contato com o suporte?</h3>
                    <p>Você pode entrar em contato com nossa equipe de suporte através do nosso email suporte@alueasy.com.br</p>
                </div>
            </section>
        </div>
</template>
<script>
</script>

<style scoped>
</style>