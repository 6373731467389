<template>

    <article id="slide_show_imoveis">
        <div class="div_arrow_repu">
            <button class="nav-button-left" @click="moverBarra('esquerda')">
                <fa :icon="['fas', 'arrow-left']" />
            </button>
            <button class="nav-button-right" @click="moverBarra('direita')">
                <fa :icon="['fas', 'arrow-right']" />
            </button>
        </div>

        <div class="div_republicas">
            <div v-for="picture in republica.pictures" @scroll="verificarOverflowX()">
                <img class="img_republicas" :src="'https://alueasy.s3.sa-east-1.amazonaws.com/' + picture"
                    alt="imagens da republica">
            </div>
        </div>
    </article>

    <article v-if="republica.userId === $store.state.user_logado._id" class="div_delete_update_repu">
        <ul class="ul_minha_repu">
            <RouterLink :to="`/update/republica/${republica._id}`" class="lista_minha_repu">
                <button class="button_minha_repu">Editar</button>
            </RouterLink>
            <RouterLink :to="`/foto/update/repu/${republica._id}`" class="lista_minha_repu">
                <li class="lista_minha_repu"><button class="button_minha_repu">Adicionar mais fotos</button></li>
            </RouterLink>
            <div class="lista_minha_repu" @click="deleteRepu(republica._id)">
                <button class="button_minha_repu">Excluir Republica</button>
            </div>
        </ul>
    </article>
    <div class="grid_container" style="margin-top: 100px">
        <article class="info_republica">
            <h2 class="repu_h2_info" style="margin-left: 22px">Informações da republica</h2>
            <section id="div_endereco">
                <h5>{{ republica.rua}} </h5>
                <p> Bairro: {{ republica.bairro}}</p>
            </section>
            <section class="grid_infos_republicas">
                <p><img src="../../assets/images/dog.svg" alt="" class="icones_republica"> Animais: {{
                    republica.animais}}</p>
                <p><img class="icones_republica" src="https://img.icons8.com/badges/48/car.png" alt="car" /> Vagas:
                    {{republica.vagas}}</p>
                <p><img width="30" height="30" src="https://img.icons8.com/ios/50/shower.png" alt="shower" /> Banheiros:
                    {{republica.banheiros}}</p>
                <p><img src="../../assets/images/user.svg" alt="" class="icones_republica"> Pessoas:
                    {{republica.numero_pessoas}}</p>
                <p><img id="ruler_vertical" src="https://img.icons8.com/windows/32/ruler-vertical.png"
                        alt="ruler-vertical" />{{republica.metros_quadrados}} m²</p>
                <p><img class="icones_republica" src="https://img.icons8.com/windows/32/bedroom.png" alt="bedroom" />
                    Quartos: {{republica.quartos}}</p>
                <p v-if="republica.sexo === 'fem'" class="p_info_repu">
                    <fa :icon="['fas', 'venus']" class="icone-info" />feminina
                </p>
                <p v-if="republica.sexo === 'mista'" class="p_info_repu"><img class="icones_republica"
                        src="https://img.icons8.com/ios/50/unisex--v2.png" alt="unisex--v2" /> {{ republica.sexo}}</p>
                <p v-if="republica.sexo === 'masc'" class="p_info_repu">
                    <fa :icon="['fas', 'mars']" class="icone-info" />Masculina
                </p>

            </section>
            <section id="other-infos-rep">
                <section v-if="republica.aluguel" class="desc_repu" style="margin: 35px">
                    <h5>Valor aluguel: </h5>
                    <p class="p_cifrao_repu" style="margin: 20px"><span class="cifrao_repu">R$</span>: {{
                        republica.aluguel}}</p>
                </section>

                <section v-if="republica.valor_condominio" class="desc_repu" style="margin: 35px">
                    <h5>Valor Condominio: </h5>
                    <p class="p_cifrao_repu" style="margin: 20px"><span class="cifrao_repu">R$</span>: {{
                        republica.valor_condominio}}</p>
                </section>

                <section v-if="republica.agua" class="desc_repu" style="margin: 35px">
                    <h5>Valor água: </h5>
                    <p class="p_cifrao_repu" style="margin: 20px"><span class="cifrao_repu">R$</span>: {{
                        republica.agua}}
                    </p>
                </section>
                <section v-if="republica.luz" class="desc_repu" style="margin: 35px">
                    <h5>Valor luz: </h5>
                    <p class="p_cifrao_repu" style="margin: 20px"><span class="cifrao_repu">R$</span>: {{
                        republica.luz}}
                    </p>
                </section>

                <section v-if="republica.iptu" class="desc_repu" style="margin: 35px">
                    <h5>Valor IPTU: </h5>
                    <p class="p_cifrao_repu" style="margin: 20px"><span class="cifrao_repu">R$</span>: {{
                        republica.iptu}}
                    </p>
                </section>

                <section v-if="republica.desc" class="desc_repu" style="margin: 35px">
                    <h5>Descrição: </h5>
                    <p class="p_info_repu" style="margin: 20px">{{republica.desc}}</p>
                </section>
            </section>
        </article>
        <article class="info_anfitriao">
            <h2 class="repu_h2_info">Informações do anfitrião</h2>
            <section class="div_perfil_user">
                <router-link :to="`/timeline/visita/${user._id}`">
                    <div class="section_image_perfil">
                        <img :src="user.picture" alt="foto de perfil do anfitrião" class="foto_user">
                        <h4 class="">{{ user.nome }}</h4>


                    </div>
                </router-link>
            </section>
            <router-link v-if="republica.userId !== $store.state.user_logado._id"
                :to="{ name: 'proposta_vaga', query: { dados_proposta: JSON.stringify(this.dados_proposta) } }"><button
                    class="button_minha_repu">Fazer proposta</button></router-link>
        </article>
    </div>
</template>
<script>
    import axios from '../../axios';
    import router from '../../routes'


    export default {
        data() {
            return {
                republica: [],
                user: [],
                dados_proposta: {}

            }
        },
        mounted() {

            axios.get(`/g/republica/find/republica/${this.$route.params.id_repu}`)
                .then(response => {

                    this.republica = response.data.republica;
                    this.user = response.data.user;
                    const campos = [
                        '_id', 'luz', 'iptu', 'aluguel', 'agua', 'valor_condominio',
                        'numero', 'rua', 'bairro', 'userId'
                    ];
                    campos.forEach(campo => {
                        this.dados_proposta[campo] = this.republica[campo];
                    });


                })
                .catch(error => {
                    console.error('Erro ao enviar o arquivo:', error);
                });

            window.addEventListener('resize', this.verificarOverflowX)

        },
        methods: {


            verificarOverflowX() {

                const divRepublicas = document.querySelector(".div_republicas");
                const navButtonLeft = document.querySelector(".nav-button-left");
                const navButtonRight = document.querySelector(".nav-button-right");
                let verificacaoRealizada = true;


                if (!divRepublicas || verificacaoRealizada) {
                    return;
                }
                if (divRepublicas.scrollWidth > divRepublicas.clientWidth) {
                    navButtonLeft.style.display = "block";
                    navButtonRight.style.display = "block";

                }
                else {
                    navButtonLeft.style.display = "none";
                    navButtonRight.style.display = "none";
                }
            },

            moverBarra(direcao) {
                const divReps = document.querySelector('.div_republicas');
                const scrollStep = 100;
                if (direcao === 'esquerda') {
                    divReps.scrollLeft -= scrollStep;
                }
                else {
                    divReps.scrollLeft += scrollStep;
                }
                this.verificarOverflowX();
            },
            deleteRepu(repu_id) {
                axios.delete(`/a/republica/delete/republica/${repu_id}`)
                    .then(() => {
                        router.push('/');
                    })
                    .catch(error => {
                        console.error('Erro ao enviar o arquivo:', error);
                    });
            }
        }
    }
</script>
<style scoped>
    .section_image_perfil {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #other-infos-rep{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }
</style>