<template>
  <div class="background"></div>
  
  <div class="container_notification user_container">
      <div class="tabelas">
      <button class="tablink" @click="openTabela(0)">Minhas propostas</button>
      <button class="tablink" @click="openTabela(1)">Propostas Recebidas</button>
      </div>
  
      <transition name="fade" mode="out-in">
        <div v-if="ShowProposalsSent" :key="1">
          <PropostasEnviadas></PropostasEnviadas>
        </div>
        <div v-else :key="2">
          <PropostasRecebidas></PropostasRecebidas>
        </div>
      </transition>
  </div>
  
  
  </template>
  
  <script>
  import PropostasRecebidas from './PropostasRecebidas.vue'
  import PropostasEnviadas from './PropostasEnviadas.vue'
  
    export default {
      components: {
         PropostasRecebidas,
         PropostasEnviadas,
        
      }, 
      data(){
  
          return{
              ShowProposalsReceive: false,
              ShowProposalsSent: true,
              currentIndex: 0
          }
      },
      methods: {
          openTabela(index){
              if(this.currentIndex !== index)
              {
                  this.currentIndex = index   
                  this.ShowProposalsReceive = !this.ShowProposalsReceive
                  this.ShowProposalsSent = !this.ShowProposalsSent
              }
  
          }
      }
  }
  </script>
  
  <style global>
  
  .container_sem_proposta {
      max-width:100%;
      margin:20px auto;
      padding:20px;
      background-color:#fff;
      text-align:center
  }
  .container_sem_proposta h1 {
      margin-bottom:10px;
      color:#e2085c;
      font-size: 1.5em;
  }
  
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.2s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  </style>