import axios from 'axios';


const instance = axios.create({
  baseURL: 'https://alueasy-44d33b86890f.herokuapp.com/',  
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use((config) => {
  const { url } = config;
  if (url.startsWith('/a/'))
  {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    config.headers.accesstoken = accessToken;
    config.headers.refreshtoken = refreshToken;
  }

  return config;
}
);

instance.interceptors.response.use(
  (res) => 
  {
    if (res.data.newAccessToken)
      localStorage.setItem('accessToken', res.data.newAccessToken);

    return res;
  },
  (err) => { return Promise.reject(err) }
);

export function setResponseError(err, notificationState) {
  if (err.clientSide) {
    notificationState.type = 'error';
    notificationState.header = err.header;
    notificationState.message = err.message;
  } else if (err.response) {
    notificationState.type = 'error';
    notificationState.header = err.response.data.header;
    notificationState.message = err.response.data.message;
  } else {
    notificationState.type = 'error';
    notificationState.header = 'Failed to perform action';
    notificationState.message = 'Failed to communicate with the server';
  }

  notificationState.notificationShown = true;
}

export function setResponseConfirmation(header, message, notificationState) {
  notificationState.type = 'confirmation';
  notificationState.header = header;
  notificationState.message = message;
  notificationState.notificationShown = true;
}


export default instance