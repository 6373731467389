<template>
 
    <div class="grid-container" ref="appRoot">
          <article class="item" v-if="!isMobile  || (isMobile && !user_ativo.id)">
              <h1 id="titulo_mensagem">Mensagens</h1>
  
              <form @submit.prevent="pesquisaUsuario" class="pesquisa_chat">
                  <input type="text" placeholder="pesquise um chat" class="pesquisa_chat" v-model="pesquisaChat">
              </form>
  
              <ul class="ul_users" v-for="user in users">
                  <li class="item-lista_mensagem" @click="() => { loadMessages(user._id) }">
                      <img :src="user.picture" alt=""
                          class="foto_user_ativo">
                      <span class="nome_user_ativo">{{ user.nome }}</span>
                      <div class="dropdown-lista">
                          <button @click.stop="toggle(user._id)" class="dropdown_botao">
                              <fa :icon="['fas', 'ellipsis-vertical']" />
                          </button>
                          <ul v-if="isDropdownOpen(user._id)" class="usuarios-dropdown">
                              <li @click="deleteUser(user._id); $event.stopPropagation();">excluir</li>
                          </ul>
                      </div>
  
  
                  </li>
              </ul>
        
          </article>
          <article class="item" v-if="!isMobile || (isMobile && user_ativo.id)">
  
              <fa :icon="['fas', 'arrow-left']" class="seta_sair"  @click="sairChat();"/>
              <section class="chat-usuario-container">
                  <div v-if="user_ativo.id" class="div_foto">
                      <div class="chat_foto_nome_user">
                          <img :src="user_ativo.foto" alt="foto de perfil do usuario do chat" class="foto_user">
                          <h4 class="nome_perfil_chat">{{user_ativo.nome}}</h4>
                      </div>
                      <section class="chat-container">
                          <div class="message-container" ref="hasScrolledToBottom">
                              <div v-for="message in messages" :key="message._id" class="message-wrapper">
  
                                  <div :class="['message', { 'message-right': message.sendId === user_logado, 'message-left': message.sendId !== user_logado}]">
                                      {{ message.text }}
                                      <span class="formata_data">{{ formattedDate(message.created_at) }}</span>
  
                                  </div>
  
                              </div>
                          </div>
  
                          <form @submit.prevent="addMessage" class="message-form">
                              <input v-model="mensagem" type="text" placeholder="Digite uma mensagem"
                                  class="message-input" />
                              <button type="submit" class="send-button"><fa :icon="['fas', 'arrow-right']" /></button>
  
                          </form>
  
                      </section>
                  </div>
                  <section v-else>
                      <div class="div_alerta">
                          <h1 class="mensagem_selecionar_chat">Escolha uma mensagem</h1>
                          <p class="paragrafo_selecionar_chat">Selecione entre suas conversas existentes</p>
                      </div>
                  </section>
              </section>
          </article>
      </div>
  </template>
  
  <script>
  import io from 'socket.io-client';
  import axios from '../axios';
  import moment from 'moment';
  import router from '../routes'
  
  
  
  
  export default {
   
    data() {
     
      return {
        socket: null,
        mensagem: '',
        messages: [],
        users: [],
        dropdownStates: {},
        user_logado: '',
        chatId: '',
        hasScrolledToBottom: '',
        user_ativo: {},
        pesquisaChat: '',
        isMobile: false
  
      };
    },

  
    async mounted() {
    
  
      this.checkMobile();
      window.addEventListener('resize', this.checkMobile);
      document.addEventListener('click', this.closeAllDropdowns);
    
      this.user_logado= JSON.parse(atob(localStorage.getItem('accessToken').split('.')[1]))._id;
      
      if(this.$route.params.user_ativo && this.$route.params.user_ativo !== this.user_logado)
         this.loadMessages(this.$route.params.user_ativo);
  
      await axios.get(`/a/chat/${this.user_logado}`)
      .then((response) => {
        this.users = response.data.chats  
      })
      .catch((error) => {
          console.error('Erro ao enviar dados:', error);
  
      })
  
      this.socket = io({transports: [ "websocket" ]});
  
      this.socket.emit('join-chat', this.user_logado);
  
      this.socket.on('mensagem-recebida', (mensagem) => {
        this.messages.push(mensagem);
      });
  
    },
  
    methods: {
  
      scrollBottom() {
      
              this.$nextTick(() => {
                  let el = this.$refs.hasScrolledToBottom;
                  el.scrollTop = el.scrollHeight;
              });
      },
      async loadMessages(user_id){
  
          this.user_ativo.id = user_id;  
  
          axios.get(`/a/chat/find/${this.user_logado}/${user_id}`)
          .then((response) => {
  
              if(user_id)
              {
                  this.messages = response.data.messages
                  this.user_ativo.foto = response.data.user_ativo.picture
                  this.user_ativo.nome = response.data.user_ativo.nome
                  this.user_ativo.email =response.data.user_ativo.email
  
              }
          
          })
          .catch((error) => {
            console.error('Erro ao enviar dados:', error);
  
           })
  
  
      },
  
      formattedDate(created_at) {
        return moment(created_at).calendar();
      },
      addMessage(){
         
          if(this.mensagem.length > 0)
          { 
             
              let user_message = {
                  to: this.user_ativo.id,
                  sendId: this.user_logado,
                  text: this.mensagem,
                  email: this.user_ativo.email
              };
  
              this.messages.push(user_message);
  
  
              axios.post('/a/chat/message', user_message).catch((error) => {
                  console.error('Erro na solicitação HTTP:', error);
              });
  
              this.socket.emit('enviar-mensagem', { mensagem: user_message, userId: this.user_ativo.id });
              this.mensagem = ''
              this.scrollBottom();
  
          }
         
      },
      deleteUser(userId){
              let bate_papo = {
                  from: this.user_logado,
                  to: userId,
              };
  
              axios.post('/a/chat/message/delete', bate_papo)
              .then(() => {
                  if (this.user_ativo === userId)
                  {
                      this.user_ativo = null;
                      router.push('/chat');
                  }
                  this.loadMessages(null);
  
              })
              .catch((error) => {
                 console.error('Erro ao enviar dados:', error);
  
              });
  
  
      },
  
      pesquisaUsuario() 
      {
  
          axios.get(`/a/chat/pesquisa/${this.user_logado}/${this.pesquisaChat}`)
          .then((response) => {
              this.users = response.data.users
          })
          .catch((error) => {
            console.error('Erro ao enviar dados:', error);
  
           });
  
  
      
      },
  
      sairChat()
      {
          this.user_ativo.id = null 
      },
  
      
      checkMobile() {
         this.isMobile = window.innerWidth <= 800;
      },
      toggle(userId){
          this.dropdownStates[userId] = !this.dropdownStates[userId];
      },
  
      isDropdownOpen(userId) {
          return this.dropdownStates[userId] || false;
  
      },
  
      closeAllDropdowns(){
         for (const userId in this.dropdownStates) 
           this.dropdownStates[userId] = false;
              
       },
    },
  
    Updated(){scrollBottom();}
  
  
  
  
  };
  </script>
  
  
  <style scoped>
  .grid-container {display: grid; grid-template-columns: 1fr 1fr;}

  #titulo_mensagem{
    font-size: 1.4em;
  }
  
  
  .item {padding: 20px; border-right: 1px solid #ccc; height: 88vh;}
  
  
  /*Chat*/
  
  .chat-container { max-width: 100vw; border-top: 1px solid #ccc;}
  
  .message-container {
      height: 48vh;
      overflow-y: auto;
      padding: 10px;
      margin-bottom: 10px;
  
  
  }
  
  ::-webkit-scrollbar { width: 5px;}
  
  ::-webkit-scrollbar-thumb {background-color: #E2085C;}
  .message-wrapper {display: flex; justify-content: flex-start;}
  
  .formata_data {font-size: 11px; padding-left: 2px;}
  
  .message {
      padding: 8px 12px;
      border-radius: 8px;
      margin-bottom: 5px;
      max-width: 70%;
      background-color: #f0f0f0;
  }
  .message-left { align-self: flex-start; color: black; margin-right: auto;}
  .message-right {
  
      background-color: rgba(226, 8, 92, 0.8);
      align-self: flex-end;
      color: black;
      margin-left: auto;
      color: white;
  
  }
  
  .message-form {display: flex;}
  
  .message-input {
      flex: 1;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 10px;
      margin-right: 10px;
  }
  
  
  .send-button {
      padding: 8px 16px;
      background-color: #F50049;
      color: white;
      border: none;
      border-radius: 30px;
  }
  .chat_foto_nome_user{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  
  }
  .foto_user {
      width: 8vw;
      height: 8vw;
      position: relative;
      border: 1px solid #dcdcdc;
      border-radius: 90px;
      margin-top: 1vw;
  }
  
  .nome_perfil_chat {margin-top: 1vw;}
  
  .seta_sair{
      display: none;
      padding: 2px 0 0 2px;
      color: rgba(128, 128, 128, 0.9);
      cursor: pointer;
  }
  
  
  /* Lista de mensagens*/
  .dropdown-lista {position: relative;}
  .dropdown_botao {
      background-color: transparent;
      border: none;
      padding: 0;
      margin-left: 45vw;
      position: relative;
      bottom: 40px;
  }
  .dropdown_botao:focus{
      box-shadow: 0 0 0 0;
      outline: 0;
  }
  .dropdown-botao i {font-size: 16px; color: #333;}
  .usuarios-dropdown {
      position: absolute;
      right: 0;
      top: 0;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 5px;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
      width: 8vw;
      height: 50px;
  
  }
  
  .button-excluir {
      display: block;
      color: #333;
      text-decoration: none;
      font-size: 14px;
      background: transparent;
      border: none;
      width: 10vw;
      padding: 0.8rem;
      padding-left: 2px;
  
  }
  .usuarios-dropdown li {display: flex; justify-content: center; align-items: center; width: 100%;height: 100%; cursor: pointer;}
  .usuarios-dropdown li:hover {background-color: #f1f1f1;}
  .menu-dropdown:hover .usuarios-dropdown, .dropdown-lista.aberto .usuarios-dropdown{display: block;}
  
  .ul_users{
      margin-top: 20px;
  }
  .item-lista_mensagem {padding: 10px 0 10px 0;}
  .item-lista_mensagem:hover {background-color: #ccc;  cursor: pointer; border-radius: 10px;}
  
  .foto_user_ativo {
      width: 46px;
      height: 46px;
      position: relative;
      border: 1px solid #dcdcdc;
      border-radius: 90px;
      margin-top: 1vw;
      margin-left: 1vw;
  }
  
  .nome_user_ativo {position: relative; top: 0.4vh; font-size: 20px; padding: 10px;}
  
  /*pesquisar por algum chat em especifico*/
  
  form.pesquisa_chat {border: none; padding: 10px;}
  
  input.pesquisa_chat::placeholder {padding: 5px;}
  
  input.pesquisa_chat {
      margin: 8px 0 0 25px;
      width: 75%;
      border: 1px solid #555;
      border-radius: 30px;
      display: block;
      padding: 9px 4px 9px 40px;
      background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
  }
  
  
  /* caso não temha uma conversa selecionada*/
  .div_alerta {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 50vh;
  }
  
  .mensagem_selecionar_chat {white-space: nowrap; font-weight: bold;}
  
  @media (min-width: 802px) and (max-width: 850px) {.mensagem_selecionar_chat{font-size: 1.90em;}}
  @media (max-width: 768px) {.message-container {height: 38vh;}}
  
  
  @media only screen and (max-width: 800px) {
    .grid-container { grid-template-columns: 1fr;}
    .item {border-right: none; padding: 10px;}
    .foto_user {width: 20vw; height: 20vw; margin-top: 3vw}
    .nome_perfil_chat {margin-top: 3vw;}
    .dropdown_botao {margin-left: 90vw;}
    .seta_sair{display: inline-block;}
  
    #titulo_mensagem{
      margin-top: 8px;
      font-size: 1.3em;
    }
  
  
  }
  
  
  </style>
  