<template>

  <article v-if="not_users">
    <div class="background"></div>
    <div class="div_notFund_user">
        <h1 class="h1_notFound_user">Opss...! Nenhum Usuário encontrado</h1>
        <h5>headerText</h5>
        <form class="" @submit.prevent="searchUsers">
          <input class="pesquisaUserNotFund" type="search" placeholder="Search..." name="searchUserIfNotFund" v-model="searchUserIfNotFund">
        </form>
    </div>
  </article>
  <article>
    <ul v-for="user in users_found"  :key="user._id" class="container_mostra_user">
        <li class="perfil_lista_item">
              <Router-link :to="`/timeline/visita/${user._id}`"> 
                <div class="perfil_lista_link">
                   <div class="mostra_usuario_perfil" :style="{ 'background-image': `url(${ user.picture })` }"></div>
                   <span class="perfil_lista_nome">{{ user.nome }}</span>
                </div>
              </Router-link>
              <button v-if="userConnection(user)" @click="connectUser(user._id)" class="btn_seguindo">Conectado</button>
              <button v-else class="btn_seguir" @click="connectUser(user._id)">Conectar</button>
        </li>
    </ul>

  </article>

  
</template>

<script>
  import axios from '../../axios';


export default {
  
  props:{
    headerText: {
      type: String,
      required: false,
      default: ''
    }
  },
  data(){
    return{
      user_auth: '',
      users_found: '',
      searchUserIfNotFund: null,
      not_users: false,
    }

  },
  watch: {
    '$store.state.user_logado._id': function() {
      this.searchUsers();   
    }
  },
  async created(){
      this.searchUsers();   
  },
  methods: {

    async searchUsers(){
      
        this.user_auth = this.$store.state.user_logado._id
        const search_users = this.searchUserIfNotFund ?? this.$route.params.user_pesquisa;

        await axios.get(`/a/user/search/users/${search_users}/${this.user_auth}`)
        .then((response) => {
            this.users_found = response.data.users_found  
            this.not_users = this.users_found == '' ? true : false
            console.log("sexo", response.data)
        })
        .catch((error) => {
          console.error('Erro ao enviar dados:', error);

        })

     },

     
      connectUser(user_id)
      {
        const user_connection = {
            user_connect: this.user_auth,
            user_connection: user_id
        }
        const index = this.users_found.findIndex(user_found => user_found._id === user_id)

        if(this.users_found[index].connections.includes(this.user_auth)){
            this.users_found[index].connections = this.users_found[index].connections.filter(id => id !== this.user_auth);
        }
        else{
          this.users_found[index].connections.push(this.user_auth);
        }

        axios.post('/a/user/follow/user', user_connection)
        .catch((error) => {
             console.error('Erro ao enviar dados:', error);
        })
        
       },
       userConnection(user) {
           return user.connections.includes(this.user_auth);
        },
  

  }

}
</script>