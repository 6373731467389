<template>
    <div class="container_code">
      <h3 class="title">Verifique seu email</h3>
      <p class="">Você irá receber um código para verificar aqui e resetar sua senha</p>
  
      <input class="input" type="text" id="verificationCode" v-model="verificationCode" placeholder="Adicione seu código" />
      <button class="button" @click="verifyCode">Verificar</button>
  
      <p>Se você não reecebeu esse email, verifique em outros locais que ele possa estar, como  caixa de spam ou lixeira</p>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        verificationCode: "",
      };
    },
    mounted(){
     
    },
    methods: {
      verifyCode() 
      {
        const token = this.$route.params.token
        const codigo = JSON.parse(atob(token.split('.')[1])).codigo;
        if(this.verificationCode.trim() == codigo)
           this.$router.push(`/reset/password/${token}`)
      },
    },
  };
  </script>
  
  <style scoped>
  /* Estilos globais do componente */

  .container_code{
    display: flex;
    flex-direction: column;
    max-width: 800px;
    margin: 50px auto;
    padding: 10px
  }
  .title {
    margin-bottom: 20px;
    color: black;
    font-weight: bold;

  }
  
  p{
    font-size: 15px;
  }
  .input {
    width: 40%;
    margin: 10px 0 20px 0;
    padding: 5px 0 5px 10px;
    border-radius: 20px;
    border: 1px solid #ccc;
    box-sizing: border-box;
  }
  
  .button {
    max-width: 100px;
    background-color: var(--color);
    color: white;
    padding: 5px 10px;
    border-radius: 30px;
    font-size: 16px;
    margin: 8px 0 15px 0;
  }
  
  .button:hover {
    background-color: #00d7ff;
  }
  
  /* Mensagem de aviso */
  .warning {
    font-weight: bold;
  }
  </style>
  