<template>

    <article v-if="not_repus" class="container_semrepu">
          <h1>Nenhuma Republica</h1>
           <p>O usuario não tem nenhuma vaga publicada.</p>
    </article>
    
    <article class="carousel-timeline" v-for="republica in republicas" :key="republica.matricula">
      <div class="imoveis_card">
         <RouterLink :to="`/republica/${republica._id}`" class="link_carousel">
          <section class="img_timeline image_imoveis" :data-carousel-id="republica._id">
             <div class="div_arrow_carousel">
                  <div class="carousel-button carousel-button-left"><fa :icon="['fas', 'arrow-left']" /></div>
                  <div class="carousel-button carousel-button-right"><fa :icon="['fas', 'arrow-right']" /></div>
              </div>
              <div class="time-image-carousel-content" v-for="imagesArray in republica.pictures" :key="imagesArray">
                  <img :src="'https://alueasy.s3.sa-east-1.amazonaws.com/' + imagesArray" alt="">
              </div>
              <div class="carousel-indicators"></div>
          </section>
          <section class="grid_infos_time">
              <p v-if="republica.andar" class="p_info_repu"><fa :icon="['far', 'building']" class="icone-info" /> Andar: {{republica.andar}}º</p>   
              <p class="p_info_repu" v-if="republica.sexo === 'fem'"><fa :icon="['fas', 'venus']" class="icone-info"/> feminina</p>     
              <p class="p_info_repu" v-if="republica.sexo === 'masc'"><fa :icon="['fas', 'mars']" class="icone-info" /> Masculina</p>
              <p class=""><img src="../../assets/images/dog.svg" alt="" class="icones_time"> Animais: {{republica.animais}}</p>
              <p class=""><img src="../../assets/images/user.svg" alt="" class="icones_time">Pessoas: {{republica.numero_pessoas}}</p>          
              <p class=""><img class="icones_time" src="https://img.icons8.com/badges/48/car.png" alt="car"/> Vagas: {{republica.vagas}}</p>
              <p class=""><img id="ruler_time" src="https://img.icons8.com/windows/32/ruler-vertical.png" alt="ruler-vertical"/>{{republica.metros_quadrados}} m²</p>
              <p class=""><img class="icones_time" src="https://img.icons8.com/windows/32/bedroom.png" alt="bedroom"/> Quartos: {{republica.quartos}}</p>
              <p class=""><img width="30" height="30" src="https://img.icons8.com/ios/50/shower.png" alt="shower"/> Banheiros: {{republica.banheiros}}</p>
              <p class="p_info_repu" v-if="republica.sexo === 'mista'"><img class="icones_time" src="https://img.icons8.com/ios/50/unisex--v2.png" alt="unisex--v2"/> {{ republica.sexo}}</p>
  
          </section>
      </RouterLink>
      </div>
    </article> 
  </template>
    
    <script>
      import axios from '../../axios';
  
    export default {
      props: {
          user_ativo: Object
      },
      data(){
          return{
              republicas: [],
              not_repus: false,
          }
      },
      watch:{
          user_ativo(){
          axios.get(`/a/republica/find/republicas/user/${this.user_ativo._id}`)
          .then(response => {
               this.republicas = response.data;
               if(this.republicas == '')
                  this.not_repus = true
  
              this.$nextTick(() => {
                this.carouselImages();
              });
  
          })
          .catch(error => {
              console.error('Erro ao enviar o arquivo:', error);
          });
      
              
          },
      },
  
  
      mounted(){
          
          if(this.user_ativo._id)
          {
              axios.get(`/a/republica/find/republicas/user/${this.user_ativo._id}`)
              .then(response => {
                  this.republicas = response.data;
                  if(this.republicas == '')
                      this.not_repus = true
  
                  this.$nextTick(() => {
                  this.carouselImages();
                  });
  
              })
              .catch(error => {
                  console.error('Erro ao enviar o arquivo:', error);
              });
          }
      },
      methods: {
        
          carouselImages(){
              const carousels = document.querySelectorAll('.image_imoveis');
              carousels.forEach(carousel => {
  
              const images = carousel.querySelectorAll('img');
              let index = 0;
              function showImage() {
                  images.forEach(function(image, idx) {
                      if (idx === index) {
                          image.style.display = 'inline-block';
                      } else {
                          image.style.display = 'none';
                      }
                  });
  
                let indicators = carousel.querySelector('.carousel-indicators');
                let bullets = indicators.querySelectorAll('.carousel-indicator');
                bullets.forEach(function(bullet, idx) {
                    bullet.classList.toggle('active', idx === index);
                });
  
  
                }
  
  
              function nextImage(event) {
                  event.preventDefault(); 
                  index = (index + 1) % images.length;
                  showImage()
  
              }
              function prevImage(event) {
  
                  event.preventDefault(); 
                  index = (index - 1 + images.length) % images.length;
                  showImage()
  
              }
  
              let buttonLeft = carousel.parentElement.querySelector('.carousel-button-left');
              buttonLeft.onclick = prevImage;
              let buttonRight = carousel.parentElement.querySelector('.carousel-button-right');
              buttonRight.onclick = nextImage;
  
  
              let indicators = carousel.querySelector('.carousel-indicators');
              images.forEach(function(image, idx) {
                  let indicator = document.createElement('div');
                  indicator.className = 'carousel-indicator';
                  indicator.onclick = function(event) {
                      event.preventDefault(); 
                      index = idx;
                      showImage()
  
                  };
                  indicators.appendChild(indicator);
              });
  
              showImage()
              })
            
          }
      }
  
    };
    </script>
    
    <style scoped>
  
  .imoveis_card {
      width:100%;
      border-radius:5px;
      box-sizing:border-box;
      margin:5px;
      padding:5px
  }
  .grid_infos_time {
      display:grid;
      margin:30px;
      grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
       gap: 10px;
  }
  .icone-info {
      font-size:25px;
      padding-right:10px
  }
  .grid_infos_time p {
      font-size:1em;
      padding:10px;
      color:rgba(0,0,0,.9);
      white-space:nowrap
  }
  #ruler_time,.icones_time {
      position:relative;
      width:35px;
      bottom:5px
  }
  .img_timeline {
      overflow:hidden;
      white-space:nowrap;
      position:relative
  }
  .img_timeline img {
      width:100%;
      height: 54vh;
      display:inline-block;
      vertical-align:top;
      border-radius:10px
  }
  
  
  @media only screen and (max-width: 570px){
      .imoveis_card {width: 97%;}
      
  }
  
  @media only screen and (max-width:430px) {    
      .img_timeline img {
          height:250px
      }
  }
  @media only screen and (max-width:391px) {
      #ruler_time,.icones_time {
          width:27px
      }
      .grid_infos_time p {
          font-size:.9em
      }
  }
  @media only screen and (max-width:360px) {
      
      .icone-info {
          font-size:20px
      }
      .grid_infos_time p {
          font-size:.8em
      }
      #ruler_time,.icones_time {
          width:25px
      }
  }
  @media only screen and (max-width:320px) {
    
      .icone-info {
          font-size:15px
      }
      .grid_infos_time p {
          font-size:.7em
      }
      #ruler_time,.icones_time {
          width:20px
      }
      .img_timeline img {
          height:200px
      }
  }
    </style>
    