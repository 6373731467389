<template>
    <aside class="secundario">
        <form @submit.prevent="searchUsers">
            <input class="nosubmit" type="search" placeholder="Search..." v-model="pesquisaUsuario">
        </form>

        <article class="pessoas_conhecem">
            <ul>
                <li class="flex menu_perfil">
                    <Router-link to="/chat">
                        <div class="menu_perfil_link flex">
                            <fa :icon="['far', 'envelope']" class=" icone_menu_lateral" />Mensagens <span
                                class="span_messages">{{$store.state.count_messages}}</span>
                        </div>
                    </Router-link>
                </li>
                <li class="flex menu_perfil">
                    <RouterLink to="/proposal/user" class=" flex menu_perfil_link"><img
                            :src="require('@/assets/images/business-proposal.svg')" alt="propostas"
                            class="img_menu_lateral"> Propostas<span class="span_messages">{{ count_proposals }}</span>
                    </RouterLink>
                </li>

                <li class="flex menu_perfil">
                    <Router-Link to="/view/show/sobre/user" class="menu_perfil_link">
                        <img src="../../assets/images/user-aside.svg" alt="" class="img_menu_lateral_person"> Sobre
                    </Router-Link>
                </li>

                <li class="flex menu_perfil"><Router-link to="/notifica" class="menu_perfil_link">
                        <fa :icon="['far', 'bell']" class="icone_menu_lateral" style="top: 2px" /> Notificações
                    </Router-link></li>
            </ul>
        </article>

    </aside>
</template>
<script>
    import router from '../../routes'
    import axios from '../../axios';


    export default {
        props: {
            user_logado: Object
        },
        data() {
            return {
                pesquisaUsuario: '',
                users_found: [],
                count_proposals: '',
            }
        },
        watch: {
            user_logado() {
                axios.get(`/a/republica/count/proposals/${this.user_logado._id}`)
                    .then(res => {
                        this.count_proposals = res.data.count_proposals
                    })
                    .catch(error => {
                        console.error('Erro ao enviar o arquivo:', error);
                    });
            }
        },
        mounted() {
            if (this.user_logado._id) {
                axios.get(`/a/republica/count/proposals/${this.user_logado._id}`)
                    .then(res => {
                        this.count_proposals = res.data.count_proposals
                    })
                    .catch(error => {
                        console.error('Erro ao enviar o arquivo:', error);
                    });

            }
        },
        methods: {
            searchUsers() {
                router.push(`/view/show/users/${this.pesquisaUsuario}`);
            }
        }
    }
</script>
<style scoped>
    .menu_perfil:hover {
        background-color: #ccc
    }


    .pessoas_conhecem {
        border-radius: 10px;
        width: 22.786458333333332vw;
        margin: 60px 0 5px 5vw;
        border-top: 1px solid #ccc
    }

    .menu_perfil {
        cursor: pointer;
        border-bottom: 1px solid #ccc;
        height: 12,32876712328767vh;
    }


    .icone_menu_lateral {
        position: relative;
        top: 3px;
        padding-right: 8px;
        color: #f50049;
        transition: .2s;
        font-size: 20px;

    }

    .img_menu_lateral {
        width: 20px;
        color: #f50049;
        margin: 1px 5px 0 0;

    }

    .img_menu_lateral_person {
        width: 25px;
        position: relative;
        right: 5px;
        margin-bottom: 6px;
    }

    .menu_perfil_link {
        font-size: 18px;
        color: #000;
        width: 100%;
        padding: 33px
    }
</style>