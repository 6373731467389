<template>

    <article class="grid_container" style="margin-top: 10px;" v-if="showAceitaProposta">
        <section class="info_republica" id="box1">
            <div class="div_form">
                <h2  style="margin-left: 22px">Proposta ínicial</h2>
                <h4 style="margin: 25px 0 0 25px">Valores</h4>
                <ul class="lista_form_proposta">
                    <li class="li_form_proposta">Valor do Condominio <span class="custos_republica">R$ {{dados_proposta.valor_condominio  }}</span></li>
                    <li class="li_form_proposta">Aluguel <span class="custos_republica">R$  {{dados_proposta.aluguel}}</span></li>
                    <li class="li_form_proposta">Água <span class="custos_republica">R$  {{dados_proposta.agua}} </span></li>
                    <li class="li_form_proposta">Luz <span class="custos_republica">R$  {{dados_proposta.luz}}</span></li>
                    <li class="li_form_proposta">IPTU <span class="custos_republica">R$  {{dados_proposta.iptu}}</span></li>
                    <li class="valores_totais">Total <span class="custos_republica">R$ {{ total }}</span></li>
                </ul>   
                <div>
                  <button id="button_aceita" @click="showForm">Aceitar proposta</button>
                </div>
                <div>
                <RouterLink :to="`/chat/${formFields.userReceiveProposal}`"><button id="button_negocia">Negociar</button></RouterLink>
                </div>
            </div>
    
        </section>
        <section class="info_anfitriao" id="box2">
           <div id="div_endereco">
                <h5>{{ dados_proposta.rua}}</h5>
                <p> Número: {{dados_proposta.numero}} - {{ dados_proposta.bairro}}</p>
            </div>
    
        </section>
    </article>
    
    <article class="div_envia_proposta" v-if="showFormaProposta">
        <form @submit.prevent="createProposal">
            <h4 class="titulo_form_proposta">Morador</h4>
            <p>Precisamos de algumas informações sobre você</p>
    
            <label>Nome e sobrenome</label>
            <div class="div_informa" style="margin: 2px">
                <input type="text" v-model="formFields.nome" class="inputs_info" style="width: 100%;" required name="nome" maxlength="255">
            </div>
            <label>Pretende morar: </label>
            <div class="flex div_escolha">
                 <input type="radio" required v-model="formFields.morador" name="morador" id="radio_sozinho" value="sozinho" class="radio_info">
                <label for="radio_sozinho" class="label_carac" @click="selecionaTotalFamilia('radio_sozinho')">
                    <div class="btn_tipo btn_quem_mora">Sozinho</div>
    
                </label>
                <input type="radio" required v-model="formFields.morador" name="morador" id="radio_familia" value="familia" class="radio_info">
                <label for="radio_familia" class="label_carac" @click="selecionaTotalFamilia('radio_familia')">
                    <div class="btn_tipo btn_quem_mora">Familia</div>
    
                </label>
                <input type="radio" required v-model="formFields.morador" name="morador" id="radio_amigos" value="amigos" class="radio_info">
                <label for="radio_amigos" class="label_carac" @click="selecionaTotalAmigos('radio_amigos')">
                    <div class="btn_tipo btn_quem_mora">Amigos</div>
    
                </label> 
            </div>
            <div class="div_total_pessoas">
                <label class="">Quantas pessoas no total?</label>
                <div class="flex">
                    <input type="radio" v-model="formFields.total_pessoas" name="total_pessoas" id="radio_pessoais_totais" value="2" class="radio_info">
                    <label for="radio_pessoais_totais" class="label_total">
                        <div class="pessoas-btn"><span style="padding-left: 5px">2</span></div>
    
                    </label>
                    <input type="radio" v-model="formFields.total_pessoas" name="total_pessoas" id="radio_pessoas_totais_plus" value="3+" class="radio_info">
                    <label for="radio_pessoas_totais_plus" class="label_total">
                        <div class="pessoas-btn">3+</div>
                    </label>
                </div>
            </div>
            <div class="div_total_pessoas_familia">
                <label class="">Quantas são crianças?</label>
                <div class="flex">
                    <input type="radio" v-model="formFields.total_criancas" name="total_criancas" id="radio_total_criancas" value="0" class="radio_info">
                    <input type="radio" v-model="formFields.total_criancas" name="total_criancas" id="radio_total_criancas_1" value="1" class="radio_info">
                    <label for="radio_total_criancas_1" class="label_total">
                        <div class="pessoas-btn"><span style="padding-left: 4px">1</span></div>
                    </label>
                    <input type="radio" v-model="formFields.total_criancas" name="total_criancas" id="radio_total_criancas_plus" value="2+" class="radio_info">
                    <label for="radio_total_criancas_plus" class="label_total">
                        <div class="pessoas-btn">2+</div>
                    </label>
                </div>
            </div>
            <label>Pretende ter ou tem animais de estimação?</label>
            <div class="flex div_escolha">
                 <input type="radio" required  v-model="formFields.animal" name="animal" id="radio_animais_sim" value="sim" class="radio_info">
                <label for="radio_animais_sim" class="label_carac" @click="selecionaAnimal('radio_animais_sim')">
                    <div class="btn_tipo">Sim</div>
    
                </label>
                <input type="radio" required v-model="formFields.animal" name="animal" id="radio_animais_nao" value="nao" class="radio_info">
                <label for="radio_animais_nao" class="label_carac" @click="selecionaAnimal('radio_animais_nao')">
                <div class="btn_tipo btn_inquilino">Não</div>
    
                </label>
            </div>
            <div class="div-condo">
            <p class="p_subtitle">Dê detalhes sobre quantos são, o tipo e o tamanho dos seus animais de estimação.</p>
               <textarea class="desc_animais" v-model="formFields.desc_animal" name="desc_animais" cols="60" rows="5" maxlength="240"></textarea>
    
            </div>
            <p style="margin: 1px">Se apresente ao anfitrião</p>
            <p class="p_subtitle">Fale um pouco sobre você. Sobre hobbies, curso etc.</p>
            <p class="">Exemplos de apresentação: </p>
            <textarea id="desc" v-model="formFields.desc" name="desc" cols="60" rows="5" maxlength="240" required></textarea>
            <div>
            <button type="submit" class="btn_salva_proposta">Salvar informações</button>
            </div>
    
        </form>
    </article>
    </template>
    <script>
    import axios from '../../axios';
    
    
    export default {
        props: {
         dados_proposta: {
          type: Object, 
        }
        },
        data(){
            return {
                total: 0,
                showAceitaProposta: true,
                showFormaProposta: false,
                formFields: {
                    
                    repuId: this.dados_proposta._id,
                    userReceiveProposal: this.dados_proposta.userId,
                    userSendProposal: this.$store.state.user_logado._id,
                    nome: '',
                    animal: '0',
                    total_criancas: '0',
                    total_pessoas: '0',
                    morador: '',
                    desc: '', 
                    desc_animal: '', 
                },
    
            }
        },
        mounted(){
            this.total = parseFloat(this.dados_proposta.agua) + parseFloat(this.dados_proposta.luz) 
             + parseFloat(this.dados_proposta.iptu) + parseFloat(this.dados_proposta.valor_condominio)
             + parseFloat(this.dados_proposta.aluguel)
    
        },
        methods:{
            showForm(){
                this.showAceitaProposta = !this.showAceitaProposta,
                this.showFormaProposta = !this.showFormaProposta
            },
    
            selecionaAnimal(value_input) 
            {
                
                let animal = document.querySelector(`#${value_input}`);
                const desc_animal = document.querySelector('.div-condo');
                desc_animal.classList.toggle('div-condo-active', animal.value === 'sim');  
            
            },
    
            selecionaTotalFamilia(value_input) 
            {
                let tipo_imovel = document.querySelector(`#${value_input}`);
                const total_people = document.querySelector('.div_total_pessoas');
                const total_kids = document.querySelector('.div_total_pessoas_familia');
                    
                total_kids.classList.toggle('div_total_pessoas_familia-active', tipo_imovel.value === 'familia');
                total_people.classList.toggle('div_total_pessoas-active', tipo_imovel.value === 'familia');
    
    
                
            },
    
            selecionaTotalAmigos(value_input) 
            {
                const total_kids = document.querySelector('.div_total_pessoas_familia');
    
                let tipo_imovel = document.querySelector(`#${value_input}`);
                const total_people = document.querySelector('.div_total_pessoas');
                total_people.classList.toggle('div_total_pessoas-active', tipo_imovel.value === 'amigos');
    
                if(total_kids.classList.contains('div_total_pessoas_familia-active'))
                    total_kids.classList.toggle('div_total_pessoas_familia-active')
            
            },
            createProposal(){
                axios.post('/a/republica/create/proposal', this.formFields)
                .then(() => {
                    this.$router.push('/proposal/user')
                }).catch((error) =>{
                    console.error('Erro na solicitação HTTP:', error);
    
                });
            }
    
        }
    }
    </script>
    