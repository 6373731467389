import { createStore } from 'vuex'

export default createStore({
  state: {
    token: '',
    user_logado: {
      _id: '',
      nome: '',
      email: '',
      number_connections: '',
      bio: ''
    },
    count_messages: '',
  },
  getters: {
    firstLoginUserId(state){
      return state.user_logado._id
    }
  },
  mutations: {
      logged(state, data){
        if(data)
          state.user_logado = { ...data };
        else
        {
           state.user_logado.id = ''
           state.user_logado.nome = ''
           state.user_logado.email = ''
           state.user_logado.number_connections = ''
        }

      },
      countMessages(state, data){
        state.count_messages = data
        

      }

  },
  actions: {
  },
  modules: {
  }
})