<template>

  <Modal :visible="showModal" @close="showModal = false" title="Custom Modal Title" @save="searchUsers">
    <template v-slot>
      <section class="section-users">
        <ul class="ul-users">
          <li class="li-users">
            <label>Estado: </label>
            <select v-model="form.selectedState" @change="fetchCitys" class="styled-select">
              <option value="" disabled selected>Selecione um estado</option>
              <option v-for="state in states" :key="state.id" :value="state.sigla">
                {{ state.nome }}
              </option>
            </select>
          </li>
          <li class="li-users">
            <label>Cidade: </label>
            <select v-model="form.selectedCity" :disabled="!form.selectedState" class="styled-select">
              <option value="" disabled selected>Selecione uma cidade</option>
              <option v-for="city in citys" :key="city.id" :value="city.nome">
                {{ city.nome }}
              </option>
            </select>
          </li>
          <li class="li-users">
            <label>Fumantes: </label>
            <select v-model="form.smoker" class="styled-select">
              <option value="" disabled selected></option>
              <option value="n">Não</option>
              <option value="s">Sim</option>
            </select>
          </li>
          <li class="li-users">
            <label>Genero: </label>
            <select v-model="form.gender" class="styled-select">
              <option value="" disabled selected></option>
              <option value="masc">Masculino</option>
              <option value="fem">Feminino</option>
              <option value="lgbt">LGBT</option>

            </select>
          </li>
          <li class="li-users">Curso:
            <select v-model="form.selectedCourse" name="curso" id="curso" class="styled-select">
              <option disabled selected>Selecione um curso</option>
              <option v-for="course in courses" :key="course.id" :value="course.nome">
                {{course.nome}}
              </option>
            </select>
          </li>
        </ul>

      </section>
    </template>
  </Modal>

  <header id="box-users">
    <form @submit.prevent="searchUsers" class="search-container">
      <input v-model="form.search_users" type="search" class="search-users" placeholder="Pesquisar usuário...">
      <fa :icon="['fas', 'magnifying-glass']" class="search-icon" />
    </form>
  </header>

  <section class="container_mostra_user">
    <div class="box-filter">
      <button type="button" @click="showModalPattern" id="show-modal" class="btn-icon">
        <img src="../../assets/images/slider.svg" alt="icone de filtro" class="icon-filter">
        Filtros
      </button>
    </div>
    <ul v-for="user in users_connection" :key="user._id" style="padding-bottom: 50px;">
      <li class="perfil_lista_item">
        <Router-link :to="`/timeline/visita/${user._id}`">
          <div class="perfil_lista_link">
            <div class="mostra_usuario_perfil" :style="{ 'background-image': `url(${ user.picture })` }"></div>
            <span class="perfil_lista_nome">{{ user.nome }}</span>
          </div>
        </Router-link>
        <button v-if="userConnection(user)" @click="connectionUser(user._id)" class="btn_seguindo">Conectado</button>
        <button v-else class="btn_seguir" @click="connectionUser(user._id)">Conectar</button>
      </li>
    </ul>

  </section>


</template>

<script>
  import axios from '../../axios';
  import Modal from '../../components/Modal.vue'

  export default {

    components: {
      Modal
    },

    data() {
      return {
        user_logado: '',
        users_connection: '',
        searchUser: '',
        selectedCity: '',
        form: {
          selectedState: '',
          selectedCity: '',
          selectedCourse: '',
          smoker: '',
          gender: '',
          search_users: ''
        },

        citys: [],
        states: [],
        courses: [
          { id: 1, nome: "ABI - Artes Cênicas" },
          { id: 2, nome: "ABI - Ciências Biológicas" },
          { id: 3, nome: "Administração" },
          { id: 4, nome: "Agronegócio" },
          { id: 5, nome: "Agronomia" },
          { id: 6, nome: "Alimentos" },
          { id: 7, nome: "Arquitetura e Urbanismo" },
          { id: 8, nome: "Arquivologia" },
          { id: 9, nome: "Artes Cênicas - Direção Teatral" },
          { id: 10, nome: "Artes Cênicas - Interpretação Teatral" },
          { id: 11, nome: "Artes Visuais" },
          { id: 12, nome: "Ciência da Computação" },
          { id: 13, nome: "Ciências Biológicas" },
          { id: 14, nome: "Ciências Contábeis" },
          { id: 15, nome: "Ciências Econômicas" },
          { id: 16, nome: "Ciências Sociais" },
          { id: 17, nome: "Comunicação Social - Produção Editorial" },
          { id: 18, nome: "Comunicação Social - Publicidade e Propaganda" },
          { id: 19, nome: "Comunicação Social - Relações Públicas" },
          { id: 20, nome: "Dança - Bacharelado" },
          { id: 21, nome: "Dança - Licenciatura" },
          { id: 22, nome: "Desenho Industrial" },
          { id: 23, nome: "Direito" },
          { id: 24, nome: "Educação Especial" },
          { id: 25, nome: "Educação Física" },
          { id: 26, nome: "Eletrônica Industrial" },
          { id: 27, nome: "Enfermagem" },
          { id: 28, nome: "Engenharia Acústica" },
          { id: 29, nome: "Engenharia Aeroespacial" },
          { id: 30, nome: "Engenharia Ambiental e Sanitária" },
          { id: 31, nome: "Engenharia Civil" },
          { id: 32, nome: "Engenharia Elétrica" },
          { id: 33, nome: "Engenharia Florestal" },
          { id: 34, nome: "Engenharia Mecânica" },
          { id: 35, nome: "Engenharia Química" },
          { id: 36, nome: "Engenharia da Computação" },
          { id: 37, nome: "Engenharia de Controle e Automação" },
          { id: 38, nome: "Engenharia de Produção" },
          { id: 39, nome: "Engenharia de Telecomunicações" },
          { id: 40, nome: "Estatística" },
          { id: 41, nome: "Fabricação Mecânica" },
          { id: 42, nome: "Farmácia" },
          { id: 43, nome: "Filosofia" },
          { id: 44, nome: "Fisioterapia" },
          { id: 45, nome: "Fonoaudiologia" },
          { id: 46, nome: "Física" },
          { id: 47, nome: "Geografia" },
          { id: 48, nome: "Geoprocessamento" },
          { id: 49, nome: "Gestão Ambiental" },
          { id: 50, nome: "Gestão de Cooperativas" },
          { id: 51, nome: "Gestão de Turismo" },
          { id: 52, nome: "História" },
          { id: 53, nome: "Jornalismo" },
          { id: 54, nome: "Letras - Espanhol" },
          { id: 55, nome: "Letras - Inglês" },
          { id: 56, nome: "Letras - Língua Portuguesa" },
          { id: 57, nome: "Letras - Português" },
          { id: 58, nome: "Matemática" },
          { id: 59, nome: "Medicina" },
          { id: 60, nome: "Medicina Veterinária" },
          { id: 61, nome: "Meteorologia" },
          { id: 62, nome: "Música" },
          { id: 63, nome: "Música e Tecnologia" },
          { id: 64, nome: "Nutrição" },
          { id: 65, nome: "Odontologia" },
          { id: 66, nome: "Pedagogia" },
          { id: 67, nome: "Processos Químicos" },
          { id: 69, nome: "Psicologia" },
          { id: 70, nome: "Química" },
          { id: 71, nome: "Química Industrial" },
          { id: 72, nome: "Redes de Computadores" },
          { id: 73, nome: "Relações Internacionais" },
          { id: 74, nome: "Serviço Social" },
          { id: 75, nome: "Sistemas de Informação" },
          { id: 76, nome: "Sistemas para Internet" },
          { id: 77, nome: "Teatro" },
          { id: 78, nome: "Terapia Ocupacional" },
          { id: 79, nome: "Zootecnia" }
        ],
        showModal: false,
      }
    },

    watch: {
      '$store.state.user_logado._id': function () {
        this.user_logado = this.$store.state.user_logado._id
        axios.get(`/a/user/fetch/userfollow/${this.user_logado}`)
          .then((response) => {
            this.users_connection = response.data.users;

          })
          .catch((error) => {
            console.error('Erro ao enviar dados:', error);

          })
      }
    },
    created() {
      this.user_logado = this.$store.state.user_logado._id
      axios.get(`/a/user/fetch/userfollow/${this.user_logado}`)
        .then(response => {
          this.users_connection = response.data.users;
          console.log("conenc", this.users_connection);

        })
        .catch(error => {
          console.error('Erro ao enviar o arquivo:', error);
        });
    },

    methods: {
      connectionUser(user_id) {
        const users_connections = {
          user_connect: this.user_logado,
          user_connection: user_id
        }

        const index = this.users_connection.findIndex(user_found => user_found._id === user_id)

        const user_connection = this.users_connection[index].connections.includes(this.user_logado)

        if (user_connection) {
          this.users_connection[index].connections = this.users_connection[index].connections.filter(id => id !== this.user_logado);
        }
        else {
          this.users_connection[index].connections.push(this.user_logado);
        }

        axios.post('/a/user/follow/user', users_connections)
          .catch((error) => {
            console.error('Erro ao enviar dados:', error);

          })

      },

      userConnection(user) {
        return user.connections.includes(this.user_logado);
      },

      async showModalPattern() {
        try {
            this.showModal = true;
            const { data } = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
            this.states = data;
        }
        catch (error) {
          console.error(error);
        }
      },

      async fetchCitys() {
        try {

          if (!this.form.selectedState) {
            return;
          }

          const { data } = await axios.get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${this.form.selectedState}/municipios`
          );

          this.citys = data;
        }
        catch (error) {
          console.log(error)
        }
      },

      async searchUsers() {
        try {

          this.showModal = false;

          this.form.user_logado = this.$store.state.user_logado._id;

          const { data } = await axios.post('/a/user/search/users/filters', { filters: this.form });
  
          this.users_connection = data.users_found;

        } catch (error) {
          console.error(error);
        }
      }
    }

  }
</script>

<style scoped>
  #box-users {
    margin-top: 25px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .search-container {
    position: relative;
  }

  .search-users {
    width: 70vw;
    height: 60px;
    border-radius: 50px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px 80px 10px 20px;
    margin-top: 5px;
    border: 1px solid #dcdcdc;
  }

  .search-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-44%);
    font-size: 15px;
    background: var(--color);
    border-radius: 20px;
    padding: 10px;
    color: white;
    cursor: pointer;
  }

  .box-filter {
    margin: 0 0 20px 25px;
  }

  .icon-filter {
    padding: 2px;
    width: 18px;
  }

  .btn-icon {
    background: none;
    padding: 15px;
    border-radius: 20px;
    border: 1px solid #dcdcdc;
    font-weight: bold;
    font-size: 0.6em;
  }

  .btn-icon:hover {
    background: #ccc;
  }

  .ul-users {
    display: flex;
    flex-wrap: wrap;
  }

  .li-users {
    padding: 20px;
  }

  .checkbox-list-users {
    position: relative;
    top: 5px;
    width: 18px;
    height: 18px;

  }

  .styled-select {
    margin-top: 8px;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 24 24'><path fill='none' stroke='%23666' stroke-width='2' d='M7 10l5 5 5-5z'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 50%;
    cursor: pointer;
  }

  .styled-select:disabled {
    background-color: #f0f0f0;
    cursor: not-allowed;
  }

  @media screen and (max-width:768px) {

    .h1_users_follow {
      font-size: 1.2em;
    }


  }
</style>