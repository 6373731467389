<template>
    <div class="background"></div>
    
    <article class="user_container">
        <section class="profile">
            <div class="mostra_usuario_perfil"  
                :style="{ 'background-image': `url(${ $store.state.user_logado.picture })` }">
            </div>
            <p style="font-size: 2.6em;">{{$store.state.user_logado.nome}}</p>
        </section>
        <section class="details">
                    <p><strong>Email:</strong> {{$store.state.user_logado.email}}</p>
                    <p><strong>Bio:</strong> {{$store.state.user_logado.bio}}</p>                
                    <p><strong>Sexo:</strong> Masculino</p>
    
        </section>
    </article>
    
    </template>
    <script>
    export default {
     data(){
      return{
    
      }
     }
    
    }
    </script>