<template>
   
    <div v-if="not_repus" class="div_notFund_user">
        <h2 class="h2_notFound_user">Opss...! Nenhum ímovel encontrado</h2>
        <img id="cachorro" src="../assets/images/cachorro_1.webp" alt="">
        <Router-Link to="/anuncia/vaga"><button class="button_anuncia_sua_vaga">Anunciar sua vaga</button></Router-link>
    
    </div>
    <div class="carousel-container">
        <div v-for="republica in republicas" :key="republica.matricula" class="carousel-container">
            <div class="carousel-card">
                <RouterLink :to="`/republica/${republica._id}`" class="link_carousel">
                    <div class="flex welcome_div_arrow_carousel">
                        <div class="carousel-button carousel-button-left">
                        <fa :icon="['fas', 'arrow-left']" />
                        </div>
                        <div class="carousel-button carousel-button-right" >
                        <fa :icon="['fas', 'arrow-right']" />
                        </div>
                    </div>
                    <div class="image-carousel image_imoveis" :data-carousel-id="republica._id" ref="image_imoveis">
                        <div class="image-carousel-content" v-for="imagesArray in republica.pictures" :key="imagesArray">
                        <img :src="'https://alueasy.s3.sa-east-1.amazonaws.com/' + imagesArray" alt="">
                        </div>
    
                        <div class="carousel-indicators"></div>
                    </div>
                    <div>
                        <ul>
                            <li class="perfil_card_imoveis flex">
                                <RouterLink :to="`/timeline/visita/${republica.userId}`">
                                <div class="welcome_perfil" :style=" {'background-image': `url(${republica.picture})`}"></div>
                                <span class="perfil_lista">{{republica.nome}}</span>
                                </RouterLink>
                            </li>
    
                            <li class="lista-card">Aluguel: <strong>R${{republica.aluguel}}</strong></li>
                            <li class="lista-card">Metros quadrados: {{republica.metros_quadrados}} m²</li>
                            <li class="lista-card">Numero de pessoas: {{ republica.numero_pessoas }}</li>
                            <li v-if="republica.sexo === 'fem'" class="lista-card">Vaga: feminina</li>
                            <li v-if="republica.sexo === 'mista'" class="lista-card">Vaga:{{ republica.sexo}}</li>
                            <li v-if="republica.sexo === 'masc'" class="lista-card">Vaga: Masculina</li>
                            <li class="lista-card">Quartos: {{republica.quartos}}</li>
                        </ul>
                    </div>
                </RouterLink>
            </div>
        </div>
    </div>
    </template>
    <script>
     import router from '../routes'
     import axios from '../axios';
     import { carouselImages } from '../assets/js/global.js';


    export default {
      data() {
        return {
          republicas: [],
          not_repus: false,
    
        };
      },
      watch:{
        
        '$route.params.repu_pesquisa': function () {

            if(!this.$route.params.repu_pesquisa)
              router.push('/')
            else{
                axios.get(`/g/republica/fetch/search/repu/${this.$route.params.repu_pesquisa}`)
                .then(response => {
                    this.republicas = response.data;
                    console.log('hdgdhdhd', this.republicas)
                    if(this.republicas == '')
                        this.not_repus = !this.not_repus
                    
                    if(this.republicas != '' && this.not_repus)
                        this.not_repus = !this.not_repus
        
                    this.$nextTick(() => {
                    this.carouselImages();
                    });
        
                })
                .catch(error => {
                    console.error('Erro ao enviar o arquivo:', error);
                });
            }
        },
      },
      mounted(){
          
           if(!this.$route.params.repu_pesquisa)
               router.push('/')
            else
            {
                axios.get(`/g/republica/fetch/search/repu/${this.$route.params.repu_pesquisa}`)
                .then(response => {
                    this.republicas = response.data;
                    this.not_repus = this.republicas == '' ? true : false

        
                    this.$nextTick(() => {
                    this.carouselImages();
                    });
        
                })
                .catch(error => {
                    console.error('Erro ao enviar o arquivo:', error);
                });
            }
        
        
        
      },
      methods: {
          carouselImages
      }
    
    }
    </script>
    <style scoped>
    </style>
