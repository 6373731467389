<template>
   <div id="demo-modal" class="custom-modal" v-if="showModalAlert">
    <div class="modal_content">
       <h1>Aviso!!</h1>
       <div id="div_paragrafo" v-html="div_paragrafo"></div>
       <button href="#" class="close_modal" id="closeCustomModalBtn" @click="fecharModalAlerta">&times;</button>
    </div>
 </div>
 <div class="aba-content" id="aba1">
    <div class="div_create">
       <aside class="coluna_1">
          <div class="div_columns_1">
             <button class="button_colums">1</button>
             <p class="p_columns">Precisamos de alguns dados da sua republica</p>
          </div>
       </aside>
     
       <main class="coluna_2">
          <header class="menu_superior">
             <button id="#tab1" onclick="openTab(0)">1</button>
             <p class="p_imovel_dado">Dados da republica</p>
             <button  type="button" id="#tab2" class="button_foto tab2-disabled">2</button>
             <p class="p_fotos_repu">Fotos da republica</p>
          </header>
          <div id="my_form">
             <section class="div_informa">
                <h3 class="titulo_infos"><strong>Informações do Anfitrião</strong></h3>
                <label for="identidade">CPF ou CNPJ: <span style="color: red">*</span></label>
                <input class="inputs_info" type="text" id="identidade" name="identidade"  @input="formatarCpfCnpj" v-model="formFields.identidade">
                <label for="matricula">Matrícula: <span class="texto_explicao">somente para estudantes</span></label>
                <input class="inputs_info" type="text" id="matricula" name="matricula" v-model="formFields.matricula">
 
             </section>
             <section class="div_informa">
                <h3 class="titulo_infos"><strong>Endereço</strong></h3>
                <label for="cep">CEP: <span style="color: red">*</span></label>
                <input class="inputs_info" type="text" id="cep" v-model="formFields.cep" @input="formatCEP" @blur="pesquisacep" placeholder="_____-__"  name="cep"  maxlength="9">
                <label for="bairro">Bairro: <span style="color: red">*</span></label>
                <input class="inputs_info" type="text" name="bairro"  v-model="formFields.bairro" id="bairro" maxlength="50">
                <label for="rua">Rua: <span style="color: red">*</span></label>
                <input class="inputs_info" type="text" name="rua" v-model="formFields.rua" id="rua" maxlength="50">
                <label for="numero">Número: <span style="color: red">*</span></label>
                <input class="inputs_info" type="text" name="numero" v-model="formFields.numero" id="numero" maxlength="20">
                <label for="comple">Complemento: </label>
                <input class="inputs_info" type="text" name="comple" v-model="formFields.complemento" id="comple" maxlength="100">
                <label for="andar">Andar: <span class="texto_explicao"> Somente para apartamentos</span></label>
                <input class="inputs_info" type="text" name="andar" v-model="formFields.andar" id="andar" maxlength="70">
             </section>
             <section class="div_informa">
                <h3 class="titulo_infos"><strong>Tipo de anuncio</strong></h3>
                <label for="">Qual é a sua relação com a republica: <span style="color: red">*</span></label>
                <div class="flex">
                   <input type="radio" v-model="formFields.relacao" id="radio_proprietario" value="proprietario" class="radio_info">
                   <label for="radio_proprietario" class="label_carac">
                      <div class="btn_tipo">Proprietário</div>
                   </label>
                   <input type="radio" v-model="formFields.relacao" id="radio_inquilino" value="inquilino" class="radio_info">
                   <label for="radio_inquilino" class="label_carac">
                      <div class="btn_tipo">Inquilino</div>
                   </label>
                </div>
             </section>
             <section class="div_informa">
                <label for="">A republica é: <span style="color: red">*</span></label>
                <div class="sexo_rep">
                   <input type="radio" required v-model="formFields.sexo" id="radio_masc" value="masc" class="radio_info">
                   <label for="radio_masc" class="label_carac">
                      <div class="btn_tipo">Masculina</div>
                   </label>
                   <input type="radio" required v-model="formFields.sexo" id="radio_fem" value="fem" class="radio_info">
                   <label for="radio_fem" class="label_carac">
                      <div class="btn_tipo">Feminina</div>
                   </label>
                   <input type="radio" required v-model="formFields.sexo" id="radio_mista" value="mista" class="radio_info">
                   <label for="radio_mista" class="label_carac">
                      <div class="btn_tipo">Mista</div>
                   </label>
                </div>
             </section>
             <section class="div_informa">
                <h3 class="titulo_infos"><strong>Características da republica</strong></h3>
                <label for="">Qual é o tipo de ímovel: <span style="color: red">*</span></label>
                <ul>
                   <li>
                      <input v-model="formFields.tipo_imovel" class="radio_info" type="radio" id="apto-info" name="tipo_imovel" value="apto">
                      <label for="apto-info" class="label_carac" @click="selecionaTipoImovel('apto-info')">
                         <div class="radio-btn"></div>
                         <span>Apartamento</span>
                      </label>
                   </li>
                   <li>
                      <input v-model="formFields.tipo_imovel" type="radio" class="radio_info" id="kit-info" name="tipo_imovel" value="kit" required>
                      <label for="kit-info" class="label_carac"  @click="selecionaTipoImovel('kit-info')">
                         <div class="radio-btn"></div>
                         <span>Kitnet</span>
                      </label>
                   </li>
                   <li>
                      <input  class="radio_info" v-model="formFields.tipo_imovel" type="radio" id="casa-info" name="tipo_imovel" value="casa" required>
                      <label for="casa-info" class="label_carac" @click="selecionaTipoImovel('casa-info')">
                         <div class="radio-btn"></div>
                         <span>Casa</span>
                      </label>
                   </li>
                   <li>
                      <input v-model="formFields.tipo_imovel" type="radio" class="radio_info" id="casa-condo-info" name="tipo_imovel" value="casa_condo" required>
                      <label for="casa-condo-info" class="label_carac" @click="selecionaTipoImovel('casa-condo-info')">
                         <div class="radio-btn"></div>
                         <span>Casa em condomínio</span>
                      </label>
                   </li>
                </ul>
                <label for="area">Area do imóvel: <span style="color: red">*</span></label>
                <input type="text" class="inputs_info input_area_info" placeholder="Digite a area: m²" name="area" id="area" required v-model="formFields.metros_quadrados">
                <label for="">Qual é o número de vagas na garagem:</label>
                <div class="flex div_num_info">
                   <button type="button" class="btn-info_more_minus" @click="modifyValue('1', '-')">
                      <fa :icon="['fas', 'minus']" />
                   </button>
                   <input type="number" class="inputs_info_plus_minus" v-model="formFields.vagas" id="vagas" 
                      value="0" min="0" data-id="1" required> 
                   <button type="button" class="btn-info_more_minus"
                      @click="modifyValue('1', '+')">
                      <fa :icon="['fas', 'plus']" />
                   </button>
                </div>
                <label for="">Número de quartos:</label>
                <div class="flex div_num_info">
                   <button type="button" class="btn-info_more_minus" @click="modifyValue('2', '-')">
                      <fa :icon="['fas', 'minus']" />
                   </button>
                   <input type="number" class="inputs_info_plus_minus" v-model="formFields.quartos" id="quartos" 
                      data-id="2" value="0" min="0" required> 
                   <button type="button" class="btn-info_more_minus"
                      @click="modifyValue('2', '+')">
                      <fa :icon="['fas', 'plus']" />
                   </button>
                </div>
                <label for="">Número de banheiros:</label>
                <div class="flex div_num_info">
                   <button type="button" class="btn-info_more_minus" @click="modifyValue('3', '-')">
                      <fa :icon="['fas', 'minus']" />
                   </button>
                   <input type="number" class="inputs_info_plus_minus" v-model="formFields.banheiros" id="banheiros" 
                      data-id="3" value="0" min="0" required> 
                   <button type="button" class="btn-info_more_minus"
                      @click="modifyValue('3','+')">
                      <fa :icon="['fas', 'plus']" />
                   </button>
                </div>
                <label for="">Número de animais:</label>
                <div class="flex div_num_info">
                   <button type="button" class="btn-info_more_minus" @click="modifyValue('4', '-')">
                      <fa :icon="['fas', 'minus']" />
                   </button>
                   <input type="number" class="inputs_info_plus_minus" v-model="formFields.animais" id="animais" 
                      data-id="4" value="0" min="0" required> 
                   <button type="button" class="btn-info_more_minus"
                      @click="modifyValue('4', '+')">
                      <fa :icon="['fas', 'plus']" />
                   </button>
                </div>
                <label for="">Número de pessoas:</label>
                <div class="flex div_num_info">
                   <button type="button" class="btn-info_more_minus" @click="modifyValue('5', '-')">
                      <fa :icon="['fas', 'minus']" />
                   </button>
                   <input type="number" class="inputs_info_plus_minus" id="numero_pessoas" v-model="formFields.numero_pessoas" 
                      data-id="5" value="0" min="0" required> 
                   <button type="button" class="btn-info_more_minus"
                      @click="modifyValue('5', '+')">
                      <fa :icon="['fas', 'plus']" />
                   </button>
                </div>
             </section>
             <section class="div-condo">
                <h3 class="titulo_infos"><strong>Condomínio</strong></h3>
                <label for="valor_condominio">Valor do condomínio:</label>
                <div class="input-wrapper">
                   <input v-model="formFields.valor_condo" @input="formatMoney('valor_condo')" type="text" class="inputs_info input_reais" name="valor_condominio" id="valor_condominio" placeholder="0,00" required>
                   <span class="currency-symbol">R$</span>
                </div>
             </section>
             <section class="div_informa">
                <label for="aluguel">Aluguel: <span style="color: red">*</span></label>
                <input v-model="formFields.aluguel" @input="formatMoney('aluguel')"   class="inputs_info"  type="text" name="aluguel" id="aluguel" placeholder="0,00" value="0,00" maxlength="20">
                <label for="luz">Luz: <span style="color: red">*</span></label>
                <input v-model="formFields.luz" @input="formatMoney('luz')"  class="inputs_info" type="text" name="luz" required id="luz" maxlength="20" value="0,00" placeholder="0,00">
                <label for="agua">Agua:</label>
                <input v-model="formFields.agua" @input="formatMoney('agua')"  class="inputs_info" type="text" name="agua" id="agua" maxlength="20" value="0,00" placeholder="0,00" required>
                <label for="iptu">IPTU:</label>
                <input v-model="formFields.iptu" @input="formatMoney('iptu')" class="inputs_info" type="text" name="iptu" id="iptu" maxlength="10" value="0,00" placeholder="0,00" required>
             </section>
             <section class="div_informa">
                <h3 class="titulo_infos"><strong>Descrição da republica:</strong></h3>
                <textarea id="desc" v-model="formFields.desc" cols="30" rows="5"></textarea>
             </section>
             <footer class="div-btn-continue">
                <button type="button" class="btn-continue" @click="openTab(1)">
                   Continuar 
                   <fa :icon="['fas', 'arrow-right']" />
                </button>
             </footer>
          </div>
       </main>
   
    </div>
 </div>
 <div  class="aba-content" id="aba2">
    <div class="flex">
       <aside class="coluna_1">
          <div class="div_columns_1">
             <button class="button_colums">2</button>
             <p class="p_columns">Escolha algumas fotos para sua republica</p>
          </div>
       </aside>
       <main class="coluna_2">
          <header class="menu_superior">
             <button  type="button" @click="openTab(0)">1</button>
             <p>Dados da republica</p>
             <button type="button">2</button>
             <p class="p_imovel_dado">Fotos da republica</p>
          </header>
          <article id="form_envia_image">
             <h3 class="titulo_image_repu"><strong>Fotos da republica</strong></h3>
             <div id="fotos_anuncia">
                <div class="foto_input">
                   <label for="upload_foto">
                      <div class="overlay-repu">
                         <div class="icone_anun_foto">
                            <fa :icon="['fas', 'camera']" />
                         </div>
                      </div>
                      <input 
                         type="file" 
                         name="image" 
                         id="upload_foto" 
                         style="display:none"
                         @change="handleImageSelect" />
                   </label>
                </div>
                <div id="preview">
                   <img
                      v-for="(image, index) in selectedImages"
                      :key="index"
                      :src="image.url"
                      :width="80"
                      :height="80"
                      class="image-preview"
                      />
                </div>
             </div>
             <footer class="div-btn-continue div-btn-foto">
                <button type="button" class="btn-continue btn_enviar_repu" id="uploadButton" @click="uploadImages">
                   Criar republica 
                   <fa :icon="['fas', 'arrow-right']" />
                </button>
                <ul id="notification-list"></ul>
             </footer>
         </article>
      </main>
    </div>
 </div>
  
 </template>
  
  <script>
  import axios from '../../axios';
  import router from '../../routes'
  
  export default{
      data(){
          return{
              showModalAlert: false,
              div_paragrafo: '',
              currentTab: 0,
              selectedImages: [],
              formFields: {
                  identidade: '',
                  matricula: '',
                  bairro: '',
                  rua: '',
                  numero: '',
                  comple: '',
                  andar: '',
                  metros_quadrados: '',
                  tipo_imovel: '',
                  sexo: '',
                  relacao: '',
                  vagas: 0,
                  quartos: 0,
                  banheiros: 0,
                  animais: 0,
                  numero_pessoas: 0,
                  desc: '',
                  cep: '',
                  aluguel: '0,00',
                  iptu: '0,00',
                  luz: '0,00',
                  agua: '0,00',
                  valor_condo: '0,00',
              },
  
          }
      },
      mounted() {
         this.openTab(0);
      },
      methods: {
  
          openModal(){
             this.modalVisibility = 'visible';
             this.modalOpacity = 1;
          },
          closeModal(){
             this.modalVisibility = 'hidden';
             this.modalOpacity = 0;
          },
          openTab(tabIndex) 
          {
              if(document.getElementById(`aba${this.currentTab + 1}`))
              {
                  document.getElementById(`aba${this.currentTab + 1}`).style.display = "none";
                  document.getElementById(`aba${tabIndex + 1}`).style.display = "block";
                  this.currentTab = tabIndex;
              }
          },
          modifyValue(inputId, i) 
          {
              const quantityInput = document.querySelector(`.inputs_info_plus_minus[data-id="${inputId}"]`);
              if(i === '+')
              this.formFields[quantityInput.id] = parseInt(quantityInput.value) + 1;
  
              else if(i === '-' && quantityInput.value > 0)
              this.formFields[quantityInput.id] = parseInt(quantityInput.value) - 1;
      
          },
          formatarCpfCnpj() {
 
             let value = this.formFields.identidade.replace(/\D/g, ''); 
             if (value.length <= 11) 
             { 
                value = value.replace(/(\d{3})(\d)/, '$1.$2');
                value = value.replace(/(\d{3})(\d)/, '$1.$2');
                value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
             } 
             else 
             { 
                value = value.replace(/^(\d{2})(\d)/, '$1.$2');
                value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
                value = value.replace(/\.(\d{3})(\d)/, '.$1/$2');
                value = value.replace(/(\d{4})(\d)/, '$1-$2');
             }
             this.formFields.identidade = value;
          },
          formatCEP() 
          {
              let numericCep = this.formFields.cep.replace(/\D/g, '');
  
              if (numericCep.length > 5) 
                this.formFields.cep = numericCep.slice(0, 5) + '-' + numericCep.slice(5);
          
          },
          formatMoney(value_format) 
          {
              this.formFields[value_format] = this.formFields[value_format].replace(/\D/g, '');
  
              if (this.formFields[value_format].length > 0) 
              {
                  this.formFields[value_format] = parseFloat(this.formFields[value_format]) / 100; 
                  this.formFields[value_format] = this.formFields[value_format].toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  
              }
  
          },
          selecionaTipoImovel(value_input) 
          {
              let tipo_imovel = document.querySelector(`#${value_input}`);
              const condo = document.querySelector('.div-condo');
              const campoObrigatorioInput = document.querySelector('#andar');
              campoObrigatorioInput.required = tipo_imovel.value === 'apto';
              condo.classList.toggle('div-condo-active', tipo_imovel.value === 'casa_condo');
  
          },
          validateForm() {
              const requiredFields = [
                  { field: this.formFields.identidade, message: "Informe o CPF ou CNPJ." },
                  { field: this.formFields.tipo_imovel, message: "Selecione o tipo de imóvel." },
                  { field: this.formFields.cep, message: "Informe o CEP." },
                  { field: this.formFields.bairro, message: "Informe o bairro." },
                  { field: this.formFields.rua, message: "Informe a rua." },
                  { field: this.formFields.numero, message: "Informe o número." },
                  { field: this.formFields.relacao, message: "Selecione sua relação com a república." },
                  { field: this.formFields.sexo, message: "Selecione o sexo da república." },
                  { field: this.formFields.metros_quadrados, message: "Informe a área do imóvel." }
              ];
  
              for (const { field, message } of requiredFields) {
                  if (!field || field.trim() === '') {
                  this.mostraModalAlerta(message);
                  return false;
                  }
              }
  
              const andarInput = this.formFields.andar;
              const tipoImovel = this.formFields.tipo_imovel && this.formFields.tipo_imovel.trim();
              
              if (tipoImovel === 'apto' && (!andarInput || andarInput.trim() === '')) {
                  this.mostraModalAlerta("O campo 'Andar' é obrigatório para o tipo de imóvel 'Apartamento'.");
                  return false;
              }
              if (tipoImovel === 'casa_condo' && (!this.formFields.valor_condo || this.formFields.valor_condo.trim() === '')) {
                  this.mostraModalAlerta("Informe o valor do condominio'.");
                  return false;
              }
  
              if (this.formFields.matricula && (this.formFields.matricula.trim().length < 9 || this.formFields.matricula.trim().length > 10)) {
                  this.mostraModalAlerta("O campo matrícula foi preenchido incorretamente");
                  return false;
              }
  
              if (this.formFields.cep && this.formFields.cep.trim().length !== 9) {
                  this.mostraModalAlerta("O CEP foi preenchido incorretamente");
                  return false;
              }
              if (this.formFields.identidade && this.formFields.identidade.trim().length <= 10 || this.formFields.identidade.trim().length > 17) {
                  this.mostraModalAlerta("O CPF ou CNPJ foi preenchido incorretamente");
                  return false;
              }
  
              return true;
          },
          mostraModalAlerta(message) {
              this.showModalAlert = true;
              this.div_paragrafo =`<p>${message}</p>`
          },
          fecharModalAlerta() {
             this.showModalAlert = false;
             this.div_paragrafo = '';
          },    
          handleImageSelect(event) {
            
              const files = event.target.files;
              for (const file of files) 
              {
                  const extension = file.name.split('.').pop().toLowerCase();
                  if (extension === 'jpg' || extension === 'png' || extension === 'webp') 
                  {
                     const url = URL.createObjectURL(file);
                     this.selectedImages.push({ file, url });
                  } 
                  else 
                     this.mostraModalAlerta('Só são permitidos formatos de imagens em JPG, PNG ou WebP');
                  
              }
          },
          uploadImages() {
  
              if (!this.validateForm()) 
                  return;
  
              const formData = new FormData();
              for (const key in this.formFields) 
                  formData.append(key, this.formFields[key]);
              
          
              this.selectedImages.forEach(image => {
              formData.append('images[]', image.file);
                  
              });
 
              this.mostraModalAlerta('carregando...')
  
              axios.post(`/a/republica/create/republica/${this.$store.state.user_logado._id}`, formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }    
              }
              )
              .then(() => {
                   router.push('/');         
  
              }).catch((error) =>{
                  console.error('Erro na solicitação HTTP:', error);
  
              });
          },
          limpa_formulário_cep() {
             this.formFields.rua = '';
             this.formFields.bairro = '';
          },
          pesquisacep() {
             var cep = this.formFields.cep.replace(/\D/g, '');
 
             if (cep !== "") {
                 var validacep = /^[0-9]{8}$/;
                 if (validacep.test(cep)) 
                 {
                     this.formFields.rua = "...";
                     this.formFields.bairro = "...";
                     axios.get(`https://viacep.com.br/ws/${cep}/json/`)
                     .then((res) => {
                         this.formFields.rua = res.data.logradouro;
                         this.formFields.bairro = res.data.bairro;
                      })
                      .catch( error => console.log(error) )  
                 } 
                 else 
                 {
                    this.limpa_formulário_cep();
                   alert("Formato de CEP inválido.");
                 }
             } 
             else {
                 this.limpa_formulário_cep();
             }
         },       
          
  
  
      }
  
  }
  </script>
  <style scoped>
    .texto_explicao{
       font-size: 0.8em;
    }
  </style>