<template>

    <div class="custom-modal" v-if="showAceitaProposta">
        <div class="content_modal">
            <h3>Proposta Aceita!!!</h3>
            <RouterLink :to="`/chat/${user._id}`"><button type="button" class="button_confirma_proposta">Enviar uma mensagem ao novo morador!</button></RouterLink>
            <RouterLink to="/timeline"><button type="button" class="button_confirma_proposta">Ir para o meu Perfil</button></RouterLink>
        </div>
    </div>
 
   <article class="user_container" v-if="showProposta">
    <div class="carousel-timeline">
        <section class="imoveis_card">
                 <RouterLink :to="`/republica/${republica._id}`" class="link_carousel">

                    <section class="img_timeline image_imoveis" data-carousel-id="{{ $chave }}">
                        <div class="div_arrow_carousel">
                            <div class="carousel-button carousel-button-left"><fa :icon="['fas', 'arrow-left']" /></div>
                            <div class="carousel-button carousel-button-right"><fa :icon="['fas', 'arrow-right']" /></div>
                        </div>
                            <div class="time-image-carousel-content" v-for="imagesArray in republica.pictures" :key="imagesArray">
                                <img :src="'https://alueasy.s3.sa-east-1.amazonaws.com/' + imagesArray" alt="">
                            </div>
                                <div class="carousel-indicators"></div>

                    </section>
                    <section style="margin-top: 30px">
                        <h5>{{ republica.rua}} </h5>
                        <p> Número: {{republica.numero}} - {{ republica.bairro}}</p>
                    </section>
                                        
                </RouterLink>
                <h6 style="margin-top: 30px">Perfil do usuário que aceitou a proposta</h6>
                <section style="margin-top: 30px">
                    <RouterLink :to="`/timeline/visita/${user._id}`" class="perfil_lista_link">
                        <div class="mostra_usuario_perfil" :style="{ 'background-image': `url(${ user.picture })` }"></div>
                        <span class="perfil_lista_nome">{{ user.nome }}</span>
                    </RouterLink>
                </section>
        </section>
            <form @submit.prevent="acceptProposal">
                <footer class="flex">
                    <button type="submit" class="btn_aceitar_proposta">Aceitar proposta</button>
                </footer>
            </form>
    </div>
</article>

</template>
<script>
    import axios from '../../axios';
    import router from '../../routes'


 export default {

    data(){
        return {
            republica: [],
            user: [],
            showProposta: true,
            showAceitaProposta:false,
        }
    },
    mounted(){
        axios.get(`/g/republica/find/republica/${this.$route.params.repu_id}/${this.$route.params.user_id}`)
            .then((response) => {
                this.republica = response.data.republica
                this.user = response.data.user
                this.$nextTick(() => {
                   this.carouselImages();
                });
            })
            .catch((error) => {
            console.error('Erro ao enviar dados:', error);

         })
    },
    methods:{
        carouselImages(){
            const carousels = document.querySelectorAll('.image_imoveis');
            carousels.forEach(carousel => {

            const images = carousel.querySelectorAll('img');
            let index = 0;
            function showImage() {
                images.forEach(function(image, idx) {
                    if (idx === index) {
                        image.style.display = 'inline-block';
                    } else {
                        image.style.display = 'none';
                    }
                });

              let indicators = carousel.querySelector('.carousel-indicators');
              let bullets = indicators.querySelectorAll('.carousel-indicator');
              bullets.forEach(function(bullet, idx) {
                  bullet.classList.toggle('active', idx === index);
              });


              }


            function nextImage(event) {
                event.preventDefault(); 
                index = (index + 1) % images.length;
                showImage()

            }
            function prevImage(event) {

                event.preventDefault(); 
                index = (index - 1 + images.length) % images.length;
                showImage()

            }

            let buttonLeft = carousel.parentElement.querySelector('.carousel-button-left');
            buttonLeft.onclick = prevImage;
            let buttonRight = carousel.parentElement.querySelector('.carousel-button-right');
            buttonRight.onclick = nextImage;


            let indicators = carousel.querySelector('.carousel-indicators');
            images.forEach(function(image, idx) {
                let indicator = document.createElement('div');
                indicator.className = 'carousel-indicator';
                indicator.onclick = function(event) {
                    event.preventDefault(); 
                    index = idx;
                    showImage()

                };
                indicators.appendChild(indicator);
            });

            showImage()
            })
          
        },
        acceptProposal(){
            axios.post(`/a/republica/accept/proposal/${this.$route.params.propo_id}`)
            .then(() => {
                this.showProposta= !this.showProposta
                this.showAceitaProposta= !this.showAceitaProposta

            })
            .catch((error) => {
            console.error('Erro ao enviar dados:', error);

         })   
        }
    }
 }
</script>

<style scoped>

.imoveis_card {
    width:100%;
    border-radius:5px;
    box-sizing:border-box;
    margin:5px;
    padding:5px
}

.img_timeline {
    overflow:hidden;
    white-space:nowrap;
    position:relative
}
.img_timeline img {
    width:100%;
    height:400px;
    display:inline-block;
    vertical-align:top;
    border-radius:10px
}
.button_confirma_proposta{
    width: 400px;
    height: 50px;
    margin: 10px;
    background: var(--color);
    color: white;
    border-radius: 10px;
}
.button_confirma_proposta:hover{
    background-color: var(--color3);
    color: #000;
}
</style>