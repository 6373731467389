<template>


  <div class="modal fade" id="modal-mensagem" ref="modal_perfil">
      <div class="modal-dialog">
          <form @submit.prevent="storeFiles">
                  <div class="modal-content modal-tamanho">
                          <div class="modal-header">
                              <button type="button" class="close" ref="Close" id="close" data-dismiss="modal"><span>×</span></button>
                          </div>
                          <div class="modal-body">
                            <div v-if="show_loading">
                              <article>
                                <h3>Carregando...</h3>
                              </article>
                            </div>
                            <div class="image_perfil">
                              <label for="upload_image">
                                     <img :src="image_name ? image_name : user_logado.picture" id="uploaded_image"
                                      class="img-responsive img-circle" ref="uploaded_image"/>
                                      <div class="text"><fa :icon="['fas', 'camera']" /></div>
                                      <input type="file" name="image" class="image" id="upload_image" style="display:none" ref="imageInput" @change="onFileSelect"/>
                                      <input type="hidden" name="file" v-model="base64image">
                              </label>

                            </div>
                                  <div class="bio_perfil">  
                                      <textarea id="bio-box" v-model="new_bio" class="form_text_area bio_usuario" placeholder="Bio..."
                                          name="bio"></textarea>

                                  </div>

                          </div>
                          <div class="modal_footer">
                              <button type="submit" class="btn btn_envia">Aplicar</button>
                          </div>
                      </div>
            </form>

          </div>
    </div>

    <transition @enter="startTransitionModal" @after-enter="endTransitionModal" @before-leave="endTransitionModal" @after-leave="startTransitionModal">
      <div class="modal fade" id="modal-image" tabindex="-1" role="dialog" ref="modal_image" aria-labelledby="modalLabel" v-if="showModal">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content modal-perfil">
                        <div class="modal-header">
                            <h5 class="modal-title">Edit media</h5>
                            <button type="button" class="close" @click="closeModal" id="dropModalImage" data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="img-container">
                                <div class="row">
                                    <div class="col-md-8">
                                      <VueCropper v-show="selectedFile" ref="cropper" :src="selectedFile" :aspectRatio="aspectRatio" :cropperOptions="{ 'max-width': 100, 'max-height': 100 }"
                                       alt="Source Image"></VueCropper>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" id="crop" @click="handleImageCropped" class="btn btn_envia">enviar</button>
                        </div>
                    </div>
                </div>
            </div>  
    
    </transition>
    <div class="modal-backdrop fade d-none" ref="backdrop"></div>


  
    </template>


<script>
import axios from '../../axios';
import VueCropper from 'vue-cropperjs'
import router from '../../routes'

export default {
  props: {
        user_logado: Object
    },
  components: { VueCropper },
  data() {
    return {
      mime_type: '',
      showModal: false,
      cropedImage: '',
      autoCrop: false,
      selectedFile: '',
      image_name: '',
      dialog: false,
      files: '',
      base64image: '',
      aspectRatio: 1,
      modal_image: '',
      new_bio: '',
      show_loading: false,
      largura_original: null,
      cropperOptions: {
        minCropBoxWidth: 256,
        minCropBoxHeight: 256,
        viewMode: 3,
        cropBoxMovable: false,
        cropBoxResizable: false,


      },
    }
  },
  
  mounted() {

  },
  methods: {

     startTransitionModal() {

          if (this.$refs.backdrop) {
              this.$refs.backdrop.classList.toggle("d-block");
          }
          if (this.$refs.modal_image) {
              this.$refs.modal_image.classList.toggle("d-block");
          }
    },

    endTransitionModal() {

        if (this.$refs.backdrop) {
            this.$refs.backdrop.classList.toggle("show");
        }
        if (this.$refs.modal_image) {
            this.$refs.modal_image.classList.toggle("show");
        }
    },

    closeModal(){
      this.aspectRatio = 1
      this.showModal = !this.showModal
    },

    async storeFiles(){

      this.show_loading = !this.show_loading
      
      axios.post(`/a/user/salva/form/${this.user_logado._id}`, { file: this.base64image, bio: this.new_bio })
      .then(() => {
            document.getElementById('close').click();
            router.go('/timeline');         

      })
      .catch(error => {
          console.error('Erro ao enviar o arquivo:', error);
       });
        
                           
    },


    handleImageCropped() {
      this.cropedImage = this.$refs.cropper.getCroppedCanvas({
        width: 400,
        height: 400
      })
      
      this.cropedImage.toBlob((blob) => {
            
        const reader = new FileReader();
        reader.onloadend = () => {
        const base64data = reader.result;
        this.base64image = base64data;
        this.image_name = base64data; 
        };
        reader.readAsDataURL(blob);
        this.showModal = !this.showModal
        this.aspectRatio = 1


      })
    },
    onFileSelect(e) {
      const file = e.target.files[0]
      this.mime_type = file.type
      if (typeof FileReader === 'function') {
        this.dialog = true
        const reader = new FileReader()
        reader.onload = (event) => {
          
          this.showModal = !this.showModal
          const img = new Image();
          img.onload = () => {

            this.largura_original = img.width;
            this.aspectRatio = this.largura_original / img.height + 1;
            this.selectedFile = event.target.result;
            this.$refs.cropper.replace(this.selectedFile);

          };
          img.src = event.target.result;
        }
        reader.readAsDataURL(file)

      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
   
  },
  
}
</script>