<template >
  <div class="background"></div>
  <article class="profile-settings">
        <h1>Configurações do Perfil</h1>

        <section class="setting-option">
          <h5 class="subtitles">Alterar Senha</h5>
          <button type="button" data-toggle="modal" data-target="#modal-reset-pass" class="btn_alterar_senha">Alterar Senha</button>
        </section>

        <section class="setting-option">
          <h5 class="subtitles">Excluir Conta</h5>
          <button type="button" class="btn_alterar_senha" data-toggle="modal" data-target="#modal-excluir">Excluir Conta</button>
        </section>

        <div class="modal fade" id="modal-excluir" ref="modal_excluir">
            <div class="modal-dialog">
                <div class="modal-content modal_config">
                    <div class="modal-header">
                        <h3>Excluir Conta</h3>
                        <button type="button" class="close" ref="Close" id="close_config" data-dismiss="modal"><span>×</span></button>
                    </div>
                    <div class="modal-body">
                        <p class="pagra_modal">Tem certeza de que deseja excluir sua conta?</p>
                        <p> Esta ação é irreversível.</p>
                          <div class="form_div">
                            <p class="paragrafo_password">Digite sua senha para confirmar:</p>
                            <input type="password" class="input_verify_password" placeholder="password" v-model="password" required>
                          </div>
                    </div>
                    <div class="modal-footer">
                       <button type="button" class="btn btn_envia">Cancelar</button>
                       <button type="button" @click="deleteAccount" class="btn btn_envia">Excluir Conta</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="modal-reset-pass" ref="modal_reset_pass">

            <div class="modal-dialog">
              <form @submit.prevent="updatePassword" style="margin: 0 auto;">
                <div class="modal-content modal_config">
                    <div class="modal-header">
                        <h3>Redefinir senha</h3>
                        <button type="button" class="close" ref="Close" id="close_reset_pass" data-dismiss="modal"><span>×</span></button>
                    </div>
                    <div class="modal-body">
                        <div style="margin-bottom: 15px;">
                           <input class="input_reset_pass" type="password" name="" id="" placeholder="Senha atual">
                           <br/>
                          <Router-Link to="/reset"><button type="button" class="btn btn-link" style="font-size: 12px;" data-dismiss="modal">Forgot Your Password</button></Router-Link>
                         </div>
                         <div style="margin-bottom: 20px;">
                          <input 
                          class="input_reset_pass" 
                          type="password"
                          ref="new_password" 
                          v-model="new_password" 
                          autocomplete="new-password" 
                          placeholder="Nova senha"
                          :class="{ valid : isStrongPassword == true , inValid : isStrongPassword == false}">
                         </div>
                          <div style="margin-bottom: 20px;">
                           <input 
                           class="input_reset_pass" 
                           type="password"
                           v-model="confirm_password" 
                           ref="confirm_password" 
                           placeholder="Confirma senha"
                           autocomplete="confirm-new-password"
                           :class="{ valid : isPasswordConfirmed == true , inValid : isPasswordConfirmed == false}">
                          </div>
                    </div>
                    <div class="modal-footer">
                       <button type="submit" class="btn btn_envia">Aplicar</button>
                    </div>
                </div>
              </form>
            </div>
        </div>
  </article>


</template>
<script>
import axios from '../../axios';
import router from '../../routes'

export default {
  
  data() {
    return {
      showDeleteAccount: false,
      password: '',
      new_password: '',
      confirm_password: '',
      startValidationSenha: false,

    };
  },
  methods: {
   
    showDeleteAccountModal() {
      this.showDeleteAccount = true;
    },
    closeModal() {
      this.showDeleteAccount = false;
    },
    async deleteAccount() {

      const user = {
        id: this.$store.state.user_logado._id,
        password: this.password
      }
     await axios.delete('/a/user/delete/account', { data: { user }})
      .then(() => {
            localStorage.removeItem('accessToken')
            localStorage.removeItem('refreshToken')
            document.getElementById('close_config').click();

      })
      .catch(error => {
          console.error('Erro ao enviar o arquivo:', error);
      });
      this.$store.commit('logado', '')
      window.location.href = "/";


    },
    updatePassword(){

        const user = {
          email: this.$store.state.user_logado.email,
          new_password: this.new_password,
        }
        axios.put('/a/user/reset/password/logado', user)
        .then(() => {
          document.getElementById('close_reset_pass').click();

            this.$router.push('/timeline');         

        })
        .catch(error => {
            console.error('Erro ao enviar o arquivo:', error);
        });
    }
  },
  computed: {
          isStrongPassword() {
              return this.startValidationSenha ? /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(this.new_password) : null;
          },
          isPasswordConfirmed() {
          return this.startValidationSenha ? this.new_password == this.confirm_password : null;
          }
      },
      watch: {
          new_password() {
              this.startValidationSenha = true
              if(this.startValidationSenha)
              this.$refs.new_password.style.borderColor = this.isStrongPassword ? "#ccc" : "red";
          },
          confirm_password() {
              if(this.startValidationSenha)
                this.$refs.confirm_password.style.borderColor = this.isPasswordConfirmed ? "#ccc" : "red";
          }
      },
};
</script>
<style scoped>

 .profile-settings {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 65vh;
    border-radius: 8px;
  }
  .profile-settings h1 {
   text-align: center;
   font-size: 1.8em;
  }


  .setting-option {
    margin: 25px 0 20px 0;
  }
 .form_div{
  margin: 30px 0 30px 0;
 }
 .paragrafo_password{
  margin-left: 16%;
 }
  .input_verify_password{
    height: 40px;
    width: 90%;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
  }
  .btn_alterar_senha{
    background-color: var(--color);
    min-width: 30vw;
    margin-top: 8px;
    padding: 10px;
    color: #fff;
    border: none;
    border-radius: 8px;

  }
  .input_reset_pass{
    width: 50%;
    height: 35px;
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 5px;

  }
  
  .pagra_modal{
    display: inline;
    white-space: nowrap;
    margin-bottom: 3px;
  }
  .subtitles{
    margin: 0 auto;
    text-align: center;
  }



</style>