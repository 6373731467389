<template>
    <header class="bar_mobile" >
     <Router-Link to="/config"><fa :icon="['fa', 'gear']" class="icone_bar_mobile" /></Router-Link>
  
    </header>
   <div class="flex">
       <main class="timeline_principal">
           
           <section class="foto-capa">
               <section class="div_infos_perfil" v-if="showPerfil">
                   <div>
                       <div class="wrapper"
                               :style="{ 'background-image': `url(${ $store.state.user_logado.picture })` }">
  
                       </div>
  
                   </div>
                   <section class="div_perfil_info">
                       <div class="div_infos_perfil">
                           <p class="info_perfil_timeline nome_perfil">{{$store.state.user_logado.nome}}</p>
                           <p class="info_perfil_timeline"><button type="button" class="botao-edit-perfil-timeline" data-toggle="modal" data-target="#modal-mensagem">Editar Perfil</button></p>
                           <p  class="info_perfil_timeline"><RouterLink to="/config"><fa :icon="['fa', 'gear']" class="icone_perfil" /></RouterLink></p>
  
                       </div>
                       <div class="flex">
                           <RouterLink :to="`/connections/${$store.state.user_logado._id}`"><p class=info_perfil_timeline>Conexões: {{ $store.state.user_logado.number_connections }}</p></RouterLink>
                       </div>
                       <div class="bio_perfil_timeline">
                           <p class=info_perfil_timeline>{{$store.state.user_logado.bio}}</p>
                       </div>
                 </section>
               </section>
               <section class="div_infos_perfil_mobile" v-if="showPerfilMobile">
              
                   <section class="">
                       <div class="wrapper"
                              :style="{ 'background-image': `url(${ $store.state.user_logado.picture })` }">
                       </div>
                   </section>
                   <p class="paragrafo_nome">{{ $store.state.user_logado.nome }}</p>
                   <button type="button" class="botao-edit-perfil-time_mobile"  data-toggle="modal" data-target="#modal-mensagem">Editar Perfil</button>
                   <p>{{$store.state.user_logado.bio}}</p>
  
                   <div class="table_seguir">
                       <RouterLink :to="`/mostra/seguindo/${$store.state.user_logado._id}`"><p>Conexões {{ $store.state.user_logado.count_seguindo }}</p></RouterLink>
                   </div>
  
                   
                 
               </section>
           </section>
  
           <CropImage :user_logado="$store.state.user_logado"></CropImage>
  
          
           <section class="conteudo_timeline">
               <div class="tabs">
                   <span
                       v-for="(tab, index) in tabs"
                       :key="index"
                       :data-tab-value="tab.value"
                       @click="changeTab(tab.value)"
                       :class="{ active: tab.value === activeTab }"
                   >
                       {{ tab.label }}
                   </span>
               </div>
               <div class="tab-content">
                   <div
                       v-for="(tabInfo, index) in tabInfos"
                       :key="index"
                       :class="{ 'tabs__tab': true, active: tabInfo.value === activeTab }"
                       :id="tabInfo.value.substring(1)"
                       data-tab-info
                   >
                     <component v-if="$store.state.user_logado" :user_ativo="$store.state.user_logado" :is="getComponentForTab(tabInfo.value)"></component>
  
                   </div>
               </div>
           </section>
       </main>
       <Aside :user_logado="$store.state.user_logado"></Aside>
     </div>
   <Footer :user_logado="$store.state.user_logado"></Footer>
  </template>
  
  <script>
  import Aside from './Aside.vue'
  import Footer from './Footer.vue'
  import Republica from './Republica.vue'
  import Hobbie from './Hobbie.vue'
  import CropImage from './CropImage.vue'
  
  
  
  export default{
   components: {
      Aside,
      Footer,
      Republica,
      Hobbie,
      CropImage,
      
   }, 
   data(){
       return{ 
           activeTab: "#tab_1",
           tabs: [
               { label: "Republicas", value: "#tab_1" },
               { label: "Hobbies e gostos", value: "#tab_2" },
           ],
           tabInfos: [
               { value: "#tab_1" },
               { value: "#tab_2" },
           ],
           user: {},
           showPerfil: true,
           showPerfilMobile: false
          };
   },
   async mounted(){
      
      this.checkMobile()
       window.addEventListener('resize', this.checkMobile)
  
   },
   methods: {
  
       changeTab(tabValue) {
           this.activeTab = tabValue;
       },
  
  
       getComponentForTab(tabValue) {
           switch (tabValue) {
               case "#tab_1":
               return "Republica";
               case "#tab_2":
               return "Hobbie";
               default:
               return null;
           }
       },
       checkMobile()
       {
           if(window.innerWidth <= 570)
           { 
                this.showPerfilMobile = true
                this.showPerfil = false
  
           }
           else
           {
               this.showPerfilMobile = false
                this.showPerfil = true
           }
  
  
  
       },
      
      
   }
  }
  </script>
  
  <style global>
  
  .wrapper {
   position:relative
  }
  
  .timeline_principal {
   margin-top: 50px;
   width:50%;
   margin-left:150px;
   transition:margin .4s
  }
  .wrapper {
   height:150px;
   width:150px;
   border:5px solid #dcdcdc;
   border-radius:50%;
   background-size:100% 100%;
   overflow:hidden;
   bottom:20px
  }
  .modal-tamanho {
   height:650px;
   margin:auto
  }
  
  .foto-capa{
  margin-bottom: 30px;
  }
  
  .div_infos_perfil_mobile{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
  }
  .div_image_perfil_mobile{
   width: 100%;
  border-bottom: 1px solid #ccc; 
  }
  .div_perfil_info{
   margin-left: 2vw;
  }
  .table_seguir{
   width: 100%;
   display:flex;
   justify-content:space-around
  
  }
  .bar_mobile{display: none;}
  
  .icone_bar_mobile{
   font-size: 1.1rem;
   cursor: pointer;
  }
  .nome_perfil, .icone_perfil{
   font-size: 1.3em;
   margin-top: 5px;
   white-space: nowrap;
  
  }
  .paragrafo_nome{
   margin: 10px 0 15px 0;
   font-size: 1.5rem;
  }
  .div_infos_perfil{
   display: flex;
  
  }
  .icone_perfil{
   margin-top: 7px;
  }
  
  .info_perfil_timeline {
   padding: 0 0 0 30px;
  
  }
  .bio_perfil_timeline{
   margin-top: 32px;
  }
  
  
  
  
  .botao-edit-perfil-timeline, .botao-chat-perfil-timeline, .botao-edit-perfil-time_mobile, .botao_mobile {
   border:1px solid #ccc;
   background: white;
   border-radius:20px;
   width: 112px;
   height: 35px;
   font-weight: bold;
   font-size: 0.9rem;
  }
  .botao-chat-perfil-timeline{
   width: 38px;
   height: 35px;
   border-radius: 50px;
  }
  .botao-edit-perfil-time_mobile{
   width: 50%;
   margin-bottom: 15px;
  }
  .botao_mobile{
   min-width: 30vw;
   margin-bottom: 15px;
  }
  .botao_seguir{
   width: 112px;
   background-color: rgba(226,8,92,.8); 
   font-weight: normal;
   border:1px solid #ccc;
   height: 35px;
   border-radius:20px;
  
  }
  .botao-edit-perfil-timeline:hover, .botao-chat-perfil-timeline:hover {
   color:#f50049
  }
  
  .conteudo {
   margin-left:90px;
   position:relative
  }
  
  [data-tab-info] {
   display:none
  }
  .active[data-tab-info] {
   display:block
  }
  #tab_1,#tab_2,.tab-content {
   width:100%
  }
  .tabs {
   font-size:20px;
   color:#fff;
   display:flex;
   margin:0
  }
  .tabs span {
   padding:10px 10px 10px 3.90625vw;
   border:1px solid #fff;
   color: #000;
   width:100%;
   white-space:nowrap
  }
  .tabs span:hover {
   cursor:pointer;
   color:#000;
   border-bottom:3px solid #e2085c
  }
  .bar_down {
   display:none;
   position:fixed;
   bottom:0;
   background-color:#fff;
   width:100%;
   z-index:1;
   border:1px solid #ccc;
   overflow:hidden
  }
  
  
  
  #tab_2 {
   border:1px solid #ccc
  }
  #tab_3 h5 {
   margin:20px 0 2px 10px;
   letter-spacing:3px
  }
  #div_btn_envia {
   display:flex;
   justify-content:flex-end
  }
  #botao_timeline_enviar {
   margin-right:8px;
   margin-bottom:3px
  }
  
  .container_semrepu {
   max-width:100%;
   margin:20px auto;
   padding:20px;
   background-color:#fff;
   text-align:center
  }
  .container_semrepu h1 {
   margin-bottom:10px;
   font-size: 1.7em;
   color:#e2085c
  }
  
  @media only screen and (max-width:993px) {
   .timeline_principal {
       width:100%;
       margin-left:0
   }
   .secundario {
       width:300px
   }
  }
  
  @media only screen and (max-width:900px) {
   .secundario {
       display:none
   }
   #tab_1,#tab_2 {
       margin-bottom:30px
   }
   .bar_down {
       display:block
   }
   .foto-capa{
       margin-left: 10px;
   }
  }
  
  @media only screen and (max-width: 570px) {
  
    .bar_mobile{
        display: flex;
        justify-content: center;
        border-bottom: 1px solid #ccc;
        padding: 8px;
    }
    .tabs span {

       font-size: 0.9em;
  
   }
  
  }
  
  @media only screen and (max-width:480px) {
   .timeline_principal {
       width:100%;
       margin-left:0
   }
   .secundario {
       width:300px
   }
  
  .botao-chat-perfil-timeline{
        width: 35px;
        height: 33px;
   }
  }
  
  
  
  @media only screen and (max-width:320px) {
   
   .timeline_principal {
     margin-top: 40px;
   }
       
   .tabs span {
       font-size: 0.7em;
       padding: 10px 1px 10px 3.90625vw;
  
   }
   .botao-chat-perfil-timeline{
        width: 30px;
        height: 31px;
   }
  }
  
  
  
  </style>